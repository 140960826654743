import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StateService } from "../services/state.service";

@Component({
  selector: "app-unauthorized",
  templateUrl: "./unauthorized.component.html",
  styleUrls: ["./unauthorized.component.scss"],
})
export class UnauthorizedPageComponent implements OnInit {
  unauthorizedType: string = "";
  supportTeamMail: string = "";
  appDetails: any = [];

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService
  ) {
    this.appDetails = this.stateService.getAppDetails();
  }

  ngOnInit(): void {
    this.supportTeamMail = this.appDetails.supportMailID;

    this.route.params.subscribe(
      (params) => {
        this.unauthorizedType = params.unauthorizedType;
      },
      (err) => console.error("Observer got an error: " + err)
    );
  }
}
