<div class="guideLayer" *ngIf="stateService.guideLayerShow"></div>
<router-outlet> </router-outlet>
<!-- <app-loader
  *ngIf="stateService.calledApiList && stateService.calledApiList.length"
>
</app-loader> -->
<p-toast
  position="bottom-right"
  [preventOpenDuplicates]="true"
  key="downloadToast"
  styleClass="download-toast"
>
  <ng-template let-message pTemplate="message">
    <app-toast-download
      (closeIconClicked)="closeDownloadToast()"
      style="width: 100%"
    >
    </app-toast-download>
  </ng-template>
</p-toast>
<p-toast
  position="bottom-right"
  [preventOpenDuplicates]="true"
  key="softWarningToast"
  [styleClass]="'planogram-toast ' + toastMarginClass"
>
  <ng-template let-message pTemplate="message">
    <app-soft-warning-toast
      (closeIconClicked)="closeSoftWarningToast()"
      [message]="message.detail"
      style="width: 100%"
    ></app-soft-warning-toast>
  </ng-template>
</p-toast>
