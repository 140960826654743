import {
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, Event } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-common-model',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss'],
})
export class CommonModalComponent implements OnInit {
  downloadLoader = false;
  constructor(
    private http: HttpClient,
    public stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _elementRef: ElementRef,
    private router: Router,
  ) {}

  ngOnInit() {
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.classList.add('cdk-global-scrollblock');
  }

  close() {
    this.data.callback({ ...this.data, close: true });
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.classList.remove('cdk-global-scrollblock');
  }
  exportCsv(): void {
    this.downloadLoader = true;
    let csv = '';
    const originalData = JSON.parse(
      this.data.data.simulationData.originalTableData,
    );

    originalData.forEach((item: any, index: any) => {
      const obj = this.data.data.simulationData.filteredTableData.find(
        (originalItem: any) => originalItem.id === item.id,
      );
      if (obj && obj?.simulate_mix !== item.simulate_mix) {
        originalData[index].simulate_mix = obj.simulate_mix;
      }

      if (index === 0) {
        const keys = Object.keys(item);
        for (let i = 0; i < keys.length; i++) {
          // if (keys[i] !== 'id') {
          //   csv += keys[i]?.toString()?.replace(',', ' ') + ',';
          // }
          csv += keys[i]?.toString()?.replace(',', ' ') + ',';
        }
        csv = csv.substring(0, csv.length - 1) + '\n';
      }

      const values: any = Object.values(item);
      for (let value in item) {
        // if (value !== 'id') {
        //   csv += item[value]?.toString()?.replace(',', ' ') + ',';
        // }
        csv += item[value]?.toString()?.replace(',', ' ') + ',';
      }
      csv = csv.substring(0, csv.length - 1) + '\n';
    });
    csv = csv.substring(0, csv.length - 1) + '\n';

    const blob: Blob = new Blob([csv], { type: 'text/csv;charset=UTF-8' });
    let hiddenElement = document.createElement('a');
    hiddenElement.href = URL.createObjectURL(blob);
    hiddenElement.target = '_blank';
    hiddenElement.download = this.stateService.getFileName(
      'simulation_output',
      'csv',
    );
    hiddenElement.click();
    this.downloadLoader = false;
  }

  downloadFile(event: any, fileURL: string) {
    this.downloadLoader = true;
    // Prevent default behavior when clicking a link
    event.preventDefault();

    this.http.get(fileURL, { responseType: 'arraybuffer' }).subscribe(
      (val) => {
        if (val) {
          const blob: Blob = new Blob([val], {
            type: 'text/csv;charset=UTF-8',
          });
          let hiddenElement = document.createElement('a');
          hiddenElement.href = URL.createObjectURL(blob);
          hiddenElement.target = '_blank';
          let items = fileURL.split('/').slice(-1)[0].split('.');
          let filename = items[0];
          let extension = items[1];
          hiddenElement.download = this.stateService.getFileName(
            filename,
            extension,
          );
          hiddenElement.click();
          this.downloadLoader = false;
        }
      },
      (err) => {},
    );
  }
}
