<p-dialog
  [modal]="true"
  header="Version history"
  [showHeader]="true"
  [(visible)]="visible"
  [style]="{
    width: '450px',
    border: '1px solid #E0E2EB',
    borderRadius: '8px',
    height: '500px'
  }"
  [closable]="true"
  [blockScroll]="true"
  [baseZIndex]="10000"
  [resizable]="false"
  [maximizable]="false"
  [draggable]="false"
  (onHide)="closeVersionHistoryPopup()"
  appendTo="body"
  styleClass="version-history-dialog"
>
  <ng-template pTemplate="content">
    <div *ngIf="isLoading" class="loader-container">
      <div class="circular-loader"></div>
    </div>
    <div *ngIf="!isLoading" class="version-history-list">
      <div *ngIf="versions.length === 0" class="no-versions">
        <span>No version history available</span>
      </div>
      <div *ngIf="versions.length > 0">
        <div
          *ngFor="let group of groupedVersions; let i = index"
          class="version-group"
        >
          <div class="version-date-group" (click)="toggleGroup(group.date)">
            <span class="material-icons">
              {{ expandedGroups[group.date] ? "expand_more" : "chevron_right" }}
            </span>
            <span class="group-title">{{
              group.date | ordinalDate: false
            }}</span>
            <div class="flex"></div>
            <span class="group-count">{{ group.count }} versions</span>
          </div>
          <div *ngIf="expandedGroups[group.date]" class="version-details">
            <div
              *ngFor="let version of group.versions; let j = index"
              class="version-item"
            >
              <span class="material-icons">restore</span>
              <div class="content">
                <span class="version-date">{{
                  version.created_at | ordinalDate
                }}</span>
                <span class="version-state">
                  <span
                    class="material-icons"
                    [ngStyle]="{ color: getStateColor(version.state_status) }"
                  >
                    {{ getStateIcon(version.state_status) }}
                  </span>
                  {{ getStateName(version.state) }}
                </span>
                <span class="version-by">
                  By {{ userMap.get(version.created_by) }}
                </span>
              </div>
              <div class="flex"></div>
              <div class="button-wrapper">
                <button *ngIf="version.current" class="tick-button">
                  <span class="material-icons">done</span>
                </button>
                <button
                  *ngIf="!version.current"
                  class="preview-button"
                  (click)="setPreviewVersion(version)"
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
