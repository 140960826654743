<div
  class="footer-left-menu"
  [ngStyle]="
    dockRight ? {'right': footerPanel} : {'left': footerPanel}"
>
  <ul *ngIf="planoLoadingMode">
    <li><p-skeleton class="placed-sku-skeleton"></p-skeleton></li>
    <li><p-skeleton class="leftout-sku-skeleton"></p-skeleton></li>
    <li>
      <div style="display: flex; align-items: center; margin-top: 2px">
        <p-skeleton class="traffic-flow-skeleton"></p-skeleton>
      </div>
      <div><p-skeleton class="traffic-flow-icon-skeleton"></p-skeleton></div>
    </li>
  </ul>

  <ul *ngIf="!planoLoadingMode">
    <li style="padding-left: 8px" (click)="openPlacedSkuTab()">
      Placed SKU: {{ placedProductsCount }}
    </li>
    <li (click)="openLeftoutSkuTab()" style="display: flex">
      <span>Leftout SKU: </span>
      <span
        *ngIf="leftoutProductsCount === ''"
        class="footer-spinner"
        style="margin-top: -3px; margin-left: 8px"
      >
        <mat-spinner
          class="example-margin"
          mode="indeterminate"
          value="100"
          strokeWidth="2"
          diameter="15"
        >
        </mat-spinner>
      </span>
      <span style="margin-left: 2px" *ngIf="leftoutProductsCount !== ''">
        {{ leftoutProductsCount }}</span
      >
    </li>
    <li style="padding: 4px 4px 6px 7px">
      <div
        style="display: flex; align-items: center"
        *ngIf="trafficFlowAllocation != undefined"
      >
        <span>Traffic Flow </span>
        <div *ngIf="trafficFlowAllocation === 'left_to_right'">
          <span>
            <img src="/assets/images/trafficflow.svg" class="traffic-image" />
          </span>
        </div>
        <div *ngIf="trafficFlowAllocation === 'right_to_left'">
          <span>
            <img
              src="/assets/images/trafficflow.svg"
              class="traffic-image-reversed"
            />
          </span>
        </div>
      </div>
    </li>
  </ul>
</div>

<p-toast></p-toast>
