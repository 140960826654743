import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrl: './share-button.component.scss'
})
export class ShareButtonComponent implements OnInit {
  @Output() actionBtnCallback = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onButtonClick(event: any) {
    this.actionBtnCallback.emit(event);
  }
}
