<div class="btn-block">
  <span *ngIf="isRecommended" class="recomm-label">Recommended</span>
  <p *ngIf="isKeepRecommended" class="h-5"></p>
  <button
    [disabled]="btnDisable"
    class="btn flex justify-center items-center gap-2 {{ btnSize }}"
    (click)="onBtnClick()"
    [ngClass]="{ 'active-btn': isActive }"
  >
    <app-svg-icon
      class="red-cross"
      [ngClass]="{ 'white-cross': isActive }"
      color="#D14447"
      icon="delist-cross"
      padding="5px"
    ></app-svg-icon>
    <span class="label mb-0 delist-label font-bold">Delist</span>
  </button>
</div>
