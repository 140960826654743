import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { debounceTime } from "rxjs/operators";
import { HttpService } from "../../services/http.service";
import { StateService } from "../../services/state.service";
import { requestStatus } from "../admin.constants";

@Component({
  selector: "app-admin-modal",
  templateUrl: "./admin-modal.component.html",
  styleUrls: ["./admin-modal.component.scss"],
})
export class AdminModalComponent implements OnInit {
  @ViewChild("otherRegionInput")
  otherRegionInput!: ElementRef<HTMLInputElement>;
  @ViewChild("otherCountryInput")
  otherCountryInput!: ElementRef<HTMLInputElement>;

  addUserFromGroup!: FormGroup;
  addEditZoneFormGroup!: FormGroup;
  addKeyAccFormGroup!: FormGroup;
  addCountryFormGroup!: FormGroup;
  addNewKeyAccount: boolean = false;
  isShareSearchInput: boolean = false;
  selectedCountry!: string;
  selectedRegion!: string;

  data: any;
  roles: any[] = [
    { label: "Admin", value: "admin" },
    { label: "Basic", value: "basic" },
    { label: "Beta user", value: "beta user" },
  ];

  status = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
  keyAccounts: any[] = [
    {
      id: "0edc4b5b-8efc-48c8-a926-7ba87c5a52e5",
      country: "USA",
      created_by: "bf0570be-6bc9-49f8-9717-9a9e69eb11b6",
      created_at: "2023-04-18T07:00:08.377000",
      region: "Brazil",
      name: "711",
      zone_id: "d497d1dd-f132-4349-9122-982c54da6c03",
      updated_by: "bf0570be-6bc9-49f8-9717-9a9e69eb11b6",
      updated_at: "2023-04-18T07:18:49.793000",
    },
    {
      id: "61452125-97e8-44f5-82e3-2ffc2c4cab0d",
      country: "Canada",
      created_by: "bf0570be-6bc9-49f8-9717-9a9e69eb11b6",
      created_at: "2023-04-18T07:00:08.377000",
      region: "Canada",
      name: "ClubElite",
      zone_id: "d497d1dd-f132-4349-9122-982c54da6c03",
      updated_by: "bf0570be-6bc9-49f8-9717-9a9e69eb11b6",
      updated_at: "2023-04-18T07:15:05.390000",
    },
    {
      id: "cdd3164f-86e9-4ff4-8e9b-e1efcf740d56",
      country: "Canada",
      created_by: "bf0570be-6bc9-49f8-9717-9a9e69eb11b6",
      created_at: "2023-04-18T07:00:08.377000",
      region: "East",
      name: "ClubElite",
      zone_id: "d497d1dd-f132-4349-9122-982c54da6c03",
      updated_by: "bf0570be-6bc9-49f8-9717-9a9e69eb11b6",
      updated_at: "2023-04-18T07:15:28.403000",
    },
    {
      id: "202a321d-9fe3-4da7-aac3-9dc1f932beba",
      country: "USA",
      created_by: "bf0570be-6bc9-49f8-9717-9a9e69eb11b6",
      created_at: "2023-04-18T07:00:08.377000",
      region: "Brazil",
      name: "Condor",
      zone_id: "d497d1dd-f132-4349-9122-982c54da6c03",
      updated_by: "bf0570be-6bc9-49f8-9717-9a9e69eb11b6",
      updated_at: "2023-04-18T07:17:48.620000",
    },
  ];
  canViewOptions: any[] = [
    { label: "View", value: "View", inactive: true },
    { label: "Edit", value: "Edit" },
    { label: "Remove", value: "Remove" },
  ];
  selectedZoneId!: string;
  rejectReason!: string;
  isApiCalling: boolean = false;
  isFormSubmitted: boolean = false;
  errMessage!: string;
  selectedEditKeyAccounts: any[] = [];
  existingKeyAccounts: any[] = [];
  filteredKeyAccounts: any[] = [];
  searchKeyAccount: FormControl = new FormControl();
  shareAutocomplete: FormControl = new FormControl();
  selectedSharedUser: any;
  emailIdsList: any[] = [];
  sharedUsers: any[] = [];
  allowExperimentEditing: boolean = true;
  showSpinner: boolean = false;
  revokeKeyAccounts: boolean = false;
  dialogConfig: any;
  selectAllKeyAccounts: boolean = false;

  constructor(
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private stateService: StateService,
    private httpService: HttpService
  ) {
    this.dialogConfig = config;
    this.data = config.data;

    //getting user details for shared users in share experiment
    if (this.data?.type === "share_exp") {
      if (this.data?.rowData.shared_users?.length > 0) {
        this.getUserDetails(
          this.data?.rowData.shared_users.map((user: any) => user.id)
        );
      }
    }
    if (
      this.data?.type === "approve_request" &&
      this.data?.rowData.type === "add_keyaccount"
    ) {
      this.selectedEditKeyAccounts = this.data.keyAccounts;
    }
  }

  ngOnInit(): void {
    // adding mask class dynamically
    const elm = document.getElementsByClassName("p-dialog-mask");
    elm[0].classList.add("user-management-dialog-mask");

    this.addUserFromGroup = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      fname: new FormControl(""),
      lname: new FormControl(""),
      role: new FormControl(""),
      zone: new FormControl("", [Validators.required]),
      key_account: new FormControl(""),
      beta_user: new FormControl(""),
      status: new FormControl(""),
    });

    this.addEditZoneFormGroup = new FormGroup({
      zone: new FormControl("", [Validators.required]),
      zone_admin: new FormControl("", [Validators.required]),
    });

    this.addKeyAccFormGroup = new FormGroup({
      zone_id: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      region: new FormControl("", [Validators.required]),
      otherCountry: new FormControl(""),
      otherRegion: new FormControl(""),
    });

    if (this.data?.type === "app_edit_zone") {
      this.addEditZoneFormGroup.patchValue({
        zone: this.data?.rowData.name,
        zone_admin: this.data?.rowData.created_by,
      });
    } else if (this.data?.type === "edit_key_account") {
      this.addKeyAccFormGroup.patchValue({
        zone_id: this.data?.rowData.zone_id,
        country: this.data?.rowData.country,
        name: this.data?.rowData.name,
        region: this.data?.rowData.region,
      });
    } else if (this.data?.type === "edit_user") {
      this.addUserFromGroup.patchValue({
        email: this.data?.rowData.email,
        fname: this.data?.rowData.first_name,
        lname: this.data?.rowData.last_name,
        role: this.data?.rowData.role,
        zone: this.data?.rowData.zone,
        status: this.data?.rowData.is_active ? "active" : "inactive",
        beta_user: this.data?.rowData.is_beta_user,
      });
    }

    this.existingKeyAccounts = this.data?.rowData?.key_accounts;
    let existingKeyAccountIdMap: { [key: string]: string } = {};
    if (this.existingKeyAccounts !== undefined) {
      this.existingKeyAccounts.forEach((keyAccount: any) => {
        existingKeyAccountIdMap[keyAccount.id] = keyAccount.id;
      });
    }

    if (this.data?.keyAccounts !== undefined) {
      this.data.keyAccounts = this.data.keyAccounts.filter(
        (keyAccount: any) => existingKeyAccountIdMap[keyAccount.id] == undefined
      );
      this.filteredKeyAccounts = this.data.keyAccounts;
    }

    //key accounts search filter
    this.searchKeyAccount.valueChanges
      .pipe(debounceTime(500))
      .subscribe((inputValue: string) => {
        if (inputValue) {
          inputValue = inputValue.toLocaleLowerCase();
          this.filteredKeyAccounts = this.data?.keyAccounts.filter(
            (keyAccount: any) =>
              keyAccount.name.toLowerCase().includes(inputValue) ||
              keyAccount.region.toLowerCase().includes(inputValue) || // Filter by region
              keyAccount.country.toLowerCase().includes(inputValue) // Filter by country
          );
        } else {
          this.filteredKeyAccounts = this.data?.keyAccounts;
        }
      });
  }

  getColor(index: number) {
    let colors = ["#FFDBDB", "#E2DBFF", "#DBF5FF", "#DBE2FF"];
    return colors[index % 4];
  }

  shareExperiment() {
    this.isFormSubmitted = true;
    this.shareAutocomplete.markAsTouched();
    if (this.emailIdsList.length > 0 && this.shareAutocomplete.valid) {
      this.showSpinner = true;
      const expId = this.data?.rowData.id;
      const sharedUserId = this.shareAutocomplete.value.id;
      const url = `${this.stateService.apiList.experiments.getExperiments}/${expId}/share/${sharedUserId}`;
      this.httpService.postMethod(url, {}).subscribe({
        next: (res: any) => {
          if (res) {
            this.showSpinner = false;
            this.data?.rowData.shared_users?.push(sharedUserId);
            res["rowData"] = this.data?.rowData;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.showSpinner = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  onChangeShareOptions(event: any, user: any) {
    if (event.value === "Remove") {
      this.isApiCalling = true;
      const expId = this.data?.rowData.id;
      const url = `${this.stateService.apiList.experiments.getExperiments}/${expId}/share/${user.id}`;
      this.httpService.deleteMethod(url).subscribe({
        next: (res: any) => {
          if (res) {
            if (this.sharedUsers) {
              this.isApiCalling = false;
              // removing user from the shared users
              const userIndex = this.sharedUsers.indexOf(user);
              this.sharedUsers.splice(userIndex, 1);
              this.data.rowData.shared_users = this.sharedUsers.map(
                (each) => each.id
              );
            }
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  getUserDetails(sharedUsersIds: any[]) {
    this.isApiCalling = true;
    const url = this.stateService.apiList.user.sharedWith;
    const payload = { users_list: sharedUsersIds };
    this.httpService.postMethod(url, payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.sharedUsers = res.map((user: any) => {
            user["permission"] = "Edit";
            return user;
          });
          this.isApiCalling = false;
        }
      },
      error: (error: any) => {
        this.isApiCalling = false;
        this.errMessage = error.error.message;
      },
    });
  }

  searchMailIds(event: any) {
    const searchText = event.query;
    let experiment = this.data?.rowData;
    const url = `${this.stateService.apiList.user.searchUserV3}?search=${searchText}&key_account_id=${experiment.key_account_id}`;
    this.httpService
      .getMethod(url)
      .pipe(debounceTime(500))
      .subscribe({
        next: (res: any) => {
          this.isShareSearchInput = true;
          this.emailIdsList = res
            .filter((each: any) => {
              return (
                !experiment.shared_users.includes(each.id) &&
                experiment.created_by != each.id
              );
            })
            .map((each: any) => {
              each.name = [each.first_name, each.last_name]
                .filter((name) => name)
                .join(" ");
              each.has_key_account_access =
                experiment.key_account_id == "Other"
                  ? true
                  : each.has_key_account_access;
              return each;
            });
        },
        error: (error: any) => {
          this.isShareSearchInput = true;
          this.errMessage = error.error.message;
        },
      });
  }

  clearSearchText() {
    if (this.data?.type === "share_exp") {
      this.shareAutocomplete.reset();
      this.isShareSearchInput = false;
    } else {
      this.searchKeyAccount.reset();
      this.filteredKeyAccounts = this.data?.keyAccounts;
    }
  }

  checkError(control: string, type: string) {
    return (
      this.addUserFromGroup.get(control)?.touched &&
      this.addUserFromGroup.get(control)!.hasError(type)
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitDialog() {
    const type = this.data.type;
    switch (type) {
      case "migrate_user":
        this.migrateZone();
        break;

      case "revoke_zone":
        this.revokeZone();
        break;

      case "approve_request":
        this.approveRequest();
        break;

      case "reject_request":
        this.rejectRequest();
        break;

      case "add_user":
        this.addUser();
        break;

      case "add_edit_key_account":
        this.addEditKeyAccount();
        break;

      case "delete_exp":
        this.deleteExperiment();
        break;

      case "request_zone":
        this.requestZone();
        break;

      case "revoke_zone_access":
        this.revokeUserZoneAccess();
        break;

      case "request_key_account":
        this.raiseKeyAcAccessRequest();
        break;

      case "revoke_key_account_access":
        this.revokeKeyAccountAccess();
        break;

      case "system_request_zone":
        this.addZone();
        break;

      case "appRevokeZone":
        this.deleteZone();
        break;

      case "app_edit_zone":
        this.updateZone();
        break;

      case "app_add_key_account":
        this.addNewCountry();
        break;

      case "edit_key_account":
        this.updateKeyAccount();
        break;

      case "delete_key_account":
        this.deleteKeyAccount();
        break;

      case "edit_user":
        this.editUser();
        break;

      default:
        break;
    }
  }

  revokeKeyAccountAccess() {}

  revokeUserZoneAccess() {}

  deleteKeyAccount() {
    this.isApiCalling = true;
    const url = this.stateService.apiList.admin.updateKeyAccount(
      this.data?.rowData.id
    );
    this.httpService.deleteMethod(url).subscribe({
      next: (res: any) => {
        if (res) {
          this.isApiCalling = false;
          this.dialogRef.close({
            ka_id: this.data?.rowData.id,
          });
        }
      },
      error: (error: any) => {
        this.isApiCalling = false;
        this.errMessage = error.error.message;
      },
    });
  }

  updateKeyAccount() {
    this.isFormSubmitted = true;
    if (this.addKeyAccFormGroup.valid) {
      this.isApiCalling = true;
      const url = this.stateService.apiList.admin.updateKeyAccount(
        this.data?.rowData.id
      );
      const payload = { ...this.addKeyAccFormGroup.value };
      this.httpService.putMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.dialogRef.close({
              ka_id: this.data?.rowData.id,
              keyAccount: payload,
            });
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  addNewCountry() {
    this.isFormSubmitted = true;
    if (this.addKeyAccFormGroup.valid) {
      this.isApiCalling = true;
      const url = this.stateService.apiList.admin.countries;
      const payload = {
        name:
          this.addKeyAccFormGroup.value.country == "Other"
            ? this.addKeyAccFormGroup.value.otherCountry
            : this.addKeyAccFormGroup.value.country,
        zone_id: this.addKeyAccFormGroup.value.zone_id,
        currency_name: "United States Dollar",
        currency_symbol: "United States Dollar",
      };
      this.httpService.postMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            //this.dialogRef.close(res);
            this.appAddNewKeyAccount();
          }
        },
        error: (error) => {
          this.isApiCalling = false;
          if (
            error.status === 400 &&
            error?.error?.message === "Country name already in use."
          ) {
            this.appAddNewKeyAccount();
          } else {
            this.errMessage = error?.error?.message;
          }
        },
      });
    }
  }

  appAddNewKeyAccount() {
    this.isFormSubmitted = true;
    if (this.addKeyAccFormGroup.valid) {
      this.isApiCalling = true;
      const url = this.stateService.apiList.admin.keyAccounts;
      const payload = { ...this.addKeyAccFormGroup.value };
      console.log("check payload:", payload);

      if (payload.region === "Other") {
        payload.region = payload.otherRegion;
      }
      if (payload.country === "Other") {
        payload.country = payload.otherCountry;
      }

      this.httpService.postMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  raiseKeyAcAccessRequest() {
    this.isFormSubmitted = true;
    if (this.selectedEditKeyAccounts.length > 0) {
      const url = this.stateService.apiList.admin.requestNewKeyAccount(
        this.data?.activeZone.id
      );
      const newKeyAccountIds = this.selectedEditKeyAccounts.map(
        (keyAccount) => keyAccount.id
      );
      const payload = {
        key_account_ids: newKeyAccountIds,
      };
      this.isApiCalling = true;
      this.httpService.postMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  deleteExperiment() {
    this.isApiCalling = true;
    const expId = this.data?.rowData.id;
    const url = `${this.stateService.apiList.experiments.getExperiments}/${expId}`;
    this.httpService.deleteMethod(url).subscribe({
      next: (res: any) => {
        if (res) {
          this.isApiCalling = false;
          this.dialogRef.close(res);
        }
      },
      error: (error: any) => {
        this.isApiCalling = false;
        this.errMessage = error.error.message;
      },
    });
  }

  deleteZone() {
    const zoneId = this.data?.rowData.id;
    const url = this.stateService.apiList.admin.deleteZone(zoneId);
    this.httpService.deleteMethod(url).subscribe({
      next: (res: any) => {
        if (res) {
          this.isApiCalling = false;
          this.dialogRef.close(res);
        }
      },
      error: (error: any) => {
        this.isApiCalling = false;
        this.errMessage = error.error.message;
      },
    });
  }

  addZone() {
    this.isFormSubmitted = true;
    if (this.selectedZoneId) {
      const url = this.stateService.apiList.admin.zones;
      const payload = {
        name: this.selectedZoneId,
      };
      this.isApiCalling = true;

      this.httpService.postMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  updateZone() {
    this.isFormSubmitted = true;
    if (this.addEditZoneFormGroup.valid) {
      this.isApiCalling = true;
      const zoneId = this.data?.rowData.id;
      const url = this.stateService.apiList.admin.updateZone(zoneId);
      const payload = {
        name: this.addEditZoneFormGroup.value.zone,
      };
      this.httpService.putMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.dialogRef.close({ zone_id: zoneId, zone_name: payload.name });
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  addEditKeyAccount() {
    if (
      this.existingKeyAccounts.length !==
        this.data?.rowData?.key_accounts?.length &&
      !this.revokeKeyAccounts
    ) {
      this.revokeKeyAccounts = true;
      this.data.okBtn = "Confirm";
      this.dialogConfig.header = "Modify key account access";
    } else {
      this.addKeyAccounts();
    }
  }

  addKeyAccounts() {
    const url = this.stateService.apiList.admin.syncKeyAccounts(
      this.data?.currentZone?.id,
      this.data?.rowData?.id
    );
    let newKeyAccountIds = this.selectedEditKeyAccounts.map(
      (keyAccount) => keyAccount.id
    );
    let existedKeyAccountIds = this.existingKeyAccounts.map(
      (keyAccount) => keyAccount.id
    );
    let mergedKeyAccounts = this.selectedEditKeyAccounts.concat(
      this.existingKeyAccounts
    );
    let mergedKeyAccountIds = newKeyAccountIds.concat(existedKeyAccountIds);
    const payload = {
      key_account_ids: mergedKeyAccountIds,
    };

    this.isApiCalling = true;
    this.httpService.putMethod(url, payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.isApiCalling = false;
          this.data.rowData.key_accounts = mergedKeyAccounts;
          res["rowData"] = this.data.rowData;
          this.dialogRef.close(res);
        }
      },
      error: (error: any) => {
        this.isApiCalling = false;
        this.errMessage = error.error.message;
      },
    });
  }

  addUser() {
    this.addUserFromGroup.markAllAsTouched();
    this.isFormSubmitted = true;
    if (this.addUserFromGroup.valid) {
      const url = this.stateService.apiList.admin.onboardUser;
      const formData = this.addUserFromGroup.value;
      const payload = {
        email: formData.email,
        zone_id: formData.zone,
      };
      this.isApiCalling = true;

      this.httpService.postMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  rejectRequest() {
    this.isFormSubmitted = true;
    if (this.rejectReason) {
      const url = `${this.stateService.apiList.admin.approveRejectRequest(
        this.data.currentZone.id,
        this.data.rowData.id
      )}`;
      const payload = {
        status: requestStatus.REJECT_REQUEST,
        remarks: this.rejectReason,
        key_account_ids: [],
      };
      this.isApiCalling = true;

      this.httpService.postMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.data.rowData.remarks = this.rejectReason;
            res["rowData"] = this.data.rowData;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  approveRequest() {
    this.isFormSubmitted = true;
    if (
      (this.data.rowData.type === "add_keyaccount" &&
        this.selectedEditKeyAccounts.length > 0) ||
      this.data.rowData.type !== "add_keyaccount"
    ) {
      const url = `${this.stateService.apiList.admin.approveRejectRequest(
        this.data.currentZone.id,
        this.data.rowData.id
      )}`;
      let newKeyAccountIds = this.selectedEditKeyAccounts.map(
        (keyAccount) => keyAccount.id
      );
      const payload = {
        status: requestStatus.APPROVE_REQUEST,
        remarks: "",
        key_account_ids: newKeyAccountIds,
      };
      this.isApiCalling = true;

      this.httpService.postMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            res["rowData"] = this.data?.rowData;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  requestZone() {
    this.isFormSubmitted = true;
    if (this.selectedZoneId) {
      const url = this.stateService.apiList.admin.requestNewZone(
        this.selectedZoneId
      );
      this.isApiCalling = true;

      this.httpService.postMethod(url, {}).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  migrateZone() {
    this.isFormSubmitted = true;
    if (this.selectedZoneId) {
      const url = this.stateService.apiList.admin.migrateZone(
        this.data.currentZone.id,
        this.data.rowData.id
      );
      const payload = { zone_id: this.selectedZoneId };
      this.isApiCalling = true;

      this.httpService.postMethod(url, payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.isApiCalling = false;
            this.dialogRef.close(res);
          }
        },
        error: (error: any) => {
          this.isApiCalling = false;
          this.errMessage = error.error.message;
        },
      });
    }
  }

  revokeZone() {
    const url = this.stateService.apiList.admin.revokeZone(
      this.data.currentZone.id,
      this.data.rowData.id
    );
    this.isApiCalling = true;

    this.httpService.deleteMethod(url).subscribe({
      next: (res: any) => {
        if (res) {
          this.isApiCalling = false;
          res["rowData"] = this.data.rowData;
          this.dialogRef.close(res);
        }
      },
      error: (error: any) => {
        this.isApiCalling = false;
        this.errMessage = error.error.message;
      },
    });
  }

  editUser() {
    const url = this.stateService.apiList.admin.updateUser(
      this.data?.rowData.id
    );
    const formData = this.addUserFromGroup.value;
    const payload = {
      is_active: formData.status === "active" ? true : false,
      is_beta_user: formData.beta_user,
    };
    this.isApiCalling = true;

    this.httpService.putMethod(url, payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.isApiCalling = false;
          this.dialogRef.close({ user_id: this.data?.rowData.id, ...payload });
        }
      },
      error: (error: any) => {
        this.isApiCalling = false;
        this.errMessage = error.error.message;
      },
    });
  }

  onCountryChange(event: any) {
    this.selectedCountry = event.value;
    this.addKeyAccFormGroup.controls["otherCountry"].clearValidators();

    this.addKeyAccFormGroup.controls["otherCountry"].updateValueAndValidity();
    this.addKeyAccFormGroup.controls["otherCountry"].reset();
    if (this.selectedCountry === "Other") {
      this.addKeyAccFormGroup.controls["otherCountry"].setValidators([
        Validators.required,
      ]);
      this.addKeyAccFormGroup.controls["otherCountry"].updateValueAndValidity();
    }

    if (this.selectedRegion === "Other" && this.otherCountryInput) {
      setTimeout(() => {
        this.otherCountryInput.nativeElement.focus();
      }, 0);
    }
  }

  onRegionChange(event: any) {
    this.selectedRegion = event.value;
    this.addKeyAccFormGroup.controls["otherRegion"].clearValidators();

    this.addKeyAccFormGroup.controls["otherRegion"].updateValueAndValidity();
    this.addKeyAccFormGroup.controls["otherRegion"].reset();
    if (this.selectedCountry === "Other") {
      this.addKeyAccFormGroup.controls["otherRegion"].setValidators([
        Validators.required,
      ]);
      this.addKeyAccFormGroup.controls["otherRegion"].updateValueAndValidity();
    }

    // If selected region is 'Other' and the input field exists, focus it
    if (this.selectedRegion === "Other" && this.otherRegionInput) {
      setTimeout(() => {
        this.otherRegionInput.nativeElement.focus();
      }, 0);
    }
  }

  selectAllAccounts() {
    if (this.selectAllKeyAccounts) {
      // If "Select All" checkbox is checked, select all key accounts
      this.selectedEditKeyAccounts = [...this.filteredKeyAccounts];
    } else {
      // If "Select All" checkbox is unchecked, deselect all key accounts
      this.selectedEditKeyAccounts = [];
    }
  }
}
