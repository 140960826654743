import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-footer-navigation",
  templateUrl: "./footer-navigation.component.html",
  styleUrls: ["./footer-navigation.component.scss"],
})
export class FooterNavigationComponent implements OnInit {
  @Input() nextButtonDisabledFlag: boolean = false;
  @Input() previousButtonDisabledFlag: boolean = false;
  @Input() hostedFrom?: string = "";
  @Input() prevBtnText: string = "Previous";
  @Input() nextBtnText: string = "Next";
  @Input() nextBtnExpandFlag: boolean = false;
  @Output() prevClick = new EventEmitter<any>();
  @Output() nextClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onPrevClick() {
    this.prevClick.emit();
  }

  onNextClick() {
    this.nextClick.emit();
  }
}
