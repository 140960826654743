import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: any;

  constructor() {}

  setupSocketConnection() {
    // this.socket = io(environment.socketBaseUrl, {
    //   reconnectionDelay: 3000,
    // });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
