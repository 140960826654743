import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDrawer } from "@angular/material/sidenav";
import { toTitleCase, clone } from "../../utils";
import * as joint from "jointjs";
import dagre from "dagre";
import graphlib from "graphlib";
import html2canvas from "html2canvas";

@Component({
  selector: "app-flow-chart",
  templateUrl: "./flow-chart.component.html",
  styleUrl: "./flow-chart.component.scss",
})
export class FlowChartComponent implements OnInit, AfterViewInit {
  @Input() options: any = {};
  @Input() formDetails: any = [];
  @Input() uploadDataContent: any = [];
  @Input() formDefaults: any = {};
  @Input() loadingSpinner: boolean = false;
  @Input() downloadSpinner: boolean = false;
  @Input() expDetails: any = [];
  @Output() action = new EventEmitter<any>();
  @ViewChild("flowDrawer") flowDrawer!: MatDrawer;
  zoomValue: any;
  currentScale: any = 1;
  inputForm!: FormGroup;
  overlayFormList: any = {};
  initialList: any = [];
  hideChart: boolean = false;
  presentMode: boolean = false;
  chartData: any = {};
  interval: any = "";
  binCount: number = 3;
  binsData: any = [];
  maxData: number = 0;
  minData: number = 0;
  featureDefaults: any = {};
  metaDetails: any = {};
  updatedBins: any = [];
  refreshBins: boolean = true;
  featureChangeListLength = 0;

  //graph related
  graph = new joint.dia.Graph({});
  paper: any;
  finalCellId: any;
  cellClicked: boolean = false;
  addIcon: any;
  deleteIcon: any;
  editIcon: any;
  colorSet: any = {
    feature: { arrow: "#D69E77", rect: "#D69E7766", bg: "#F4EBE6" },
    column: { arrow: "#E88475", rect: "#E8847580", bg: "#FCEDEA" },
    final: { arrow: "#325A6D", rect: "#325A6D66", bg: "#E4EAEC" },
  };
  selectedCells: any = [];
  selectedSourceCell: any = "";
  // Create an overlay container
  overlay = new joint.shapes.basic.Rect({
    size: { width: 200, height: 100 },
    attrs: {
      rect: {
        fill: "white",
        stroke: "white",
        "stroke-width": 1,
      },
      filter: this.getBoxFilter(),
    },
  });

  treeList!: any[];
  chartResponse: any = [];
  isOldExp: boolean = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.zoomValue = this.getZoomValue();
  }

  ngAfterViewInit(): void {
    this.createFlowGraph();
  }

  nodeListFormation(nodeList: any, isOldExp: boolean = false) {
    this.isOldExp = isOldExp;
    this.graph.clear();
    let nodeDataList = clone(nodeList);
    nodeDataList.push({
      parent_id: null,
      isFinal: true,
      type: "final",
      disable: nodeList?.length <= 1 ? true : false,
      cluster_name: "Cluster Profile",
    });

    // forming tree structure datalist
    this.treeList = this.buildTree(null, nodeDataList, []);
    if (this.treeList) {
      this.updateFinaltargetConnection();
      this.updateLayout();
    }
  }

  buildTree(
    parent_id: string | null,
    nodes: any[],
    parentNode: any,
    setLink?: boolean
  ): any[] {
    const filteredNodes = nodes.filter((node) => node.parent_id === parent_id);
    filteredNodes.forEach((node) => {
      // Check if the node has children
      const hasChildren = nodes.some(
        (childNode) => childNode.parent_id === node.cluster_id
      );

      // Perform operations based on the presence of children
      if ((node?.meta?.task && hasChildren) || node.isFinal) {
        node["shape"] = node?.shape ? node.shape : 3;
        node["canEditDelete"] = node.isFinal || this.isOldExp ? false : true;
        node["type"] = node?.type ? node.type : node?.meta?.task.split("-")[0];
        node["parentCellId"] = node?.meta?.task && hasChildren ? node.id : "";
        node["text"] = node.isFinal ? node.cluster_name : node.type;
      } else {
        node["shape"] = node?.shape ? node.shape : 2;
        node["canAdd"] = this.isOldExp ? false : true;
        node["parentCellId"] = parentNode.id === "xx" ? "" : parentNode.id;
        node["text"] = node.cluster_name;
      }

      node["size"] = { width: 150, height: 50 };
      let ele = this.createCustomCell(node);
      if (ele.id) {
        this.graph.addCell(ele);
      }

      if (setLink && node?.meta?.task) {
        let link = this.createLink(parent_id, node.id); //for newly added data
        if (link.id) {
          this.graph.addCell(link);
        }
      }

      node.children = this.buildTree(node.cluster_id, nodes, node);
      if (node.children?.length) {
        node.children.forEach((f: any) => {
          let link = this.createLink(node.id, f.id);
          if (link.id) {
            this.graph.addCell(link);
          }
        });
      }
    });
    return filteredNodes;
  }

  //common graph functionalities
  getCell(cellId: any) {
    const cell = this.graph.getCell(cellId);
    return cell;
  }

  getOnlyCustomCells() {
    // Get all cells that are elements (nodes) from the graph
    const elements: joint.dia.Element[] = this.graph.getElements();
    // Filter out custom elements
    const customElements = elements.filter((element) => {
      let type = element.prop("type");
      return element.prop("type") == "custom";
    });

    return customElements;
  }

  getOutgoingLinks(cell: any) {
    let links: any = this.graph.getConnectedLinks(cell, { outbound: true });
    // Ignore final cell
    const filteredLinks = links.filter(
      (obj: any) => obj.getTargetCell().id !== this.finalCellId
    );
    return filteredLinks;
  }

  //graph related actions
  createFlowGraph() {
    this.paper = new joint.dia.Paper({
      el: document.getElementById("paper"),
      model: this.graph,
      width: "100%",
      height: "100%",
      gridSize: 1,
      interactive: {
        linkMove: false,
        vertexMove: false,
        vertexAdd: false,
        labelMove: false,
        elementMove: false,
      },
    });

    this.setAddIcon();
    this.setDeleteIcon();
    this.setEditIcon();

    setTimeout(() => {
      this.updateLayout();
    }, 100);

    // Event listener for 'cell:mouseenter' event on shapes
    this.paper.on("cell:mouseenter", (cell: any) => {
      this.isOldExp ? "" : this.handleAction(cell, true, false);
    });

    // Event listener for 'cell:mouseleave' event on shapes
    this.paper.on("cell:mouseleave", (cell: any) => {
      this.isOldExp ? "" : this.handleAction(cell, false, false);
    });

    // Event listener for overlay element
    this.paper.on("cell:pointerdown", (cell: any) => {
      this.isOldExp ? "" : this.handleAction(cell, true, true);
    });

    // Listen for cell click events
    this.paper.on("cell:pointerup", (cell: any) => {
      let cellModel = cell.model;
      let cellData =
        cellModel.prop("attrs/customData") || cellModel.prop("data");
      let cellType = cellModel.prop("type");
      if (
        this.selectedCells.length &&
        this.selectedSourceCell !== cellData?.parent_id &&
        cellType == "custom"
      ) {
        this.removeSelectedCells();
      }
      if (!this.presentMode && !this.isOldExp) {
        this.selectCell(cell, cellData, cellType);
      }
    });

    // Hide overlay on paper click
    this.paper.on("blank:pointerclick", () => {
      this.isOldExp ? "" : this.removeSelectedCells();
    });
  }

  selectCell(cell: any, cellData: any, cellType: any) {
    let cellModal = cell.model;
    this.selectedSourceCell = cellData.parent_id;
    let outgoingLinks = this.getOutgoingLinks(cellModal);
    if (cellData?.canAdd && outgoingLinks.length == 0 && cellType == "custom") {
      if (this.selectedCells.includes(cellModal)) {
        cellData["selected"] = false;
        let indexToRemove = this.selectedCells.findIndex(
          (cell: any) => cell.id === cellData.id
        );
        this.selectedCells.splice(indexToRemove, 1); // Remove 1 element at indexToRemove
        this.unHighLight(cellModal);
      } else {
        cellData["selected"] = true;
        this.selectedCells.push(cellModal);
        this.highLight(cellModal);
      }
    }
  }

  removeSelectedCells() {
    this.cellClicked = false;
    this.removeExtraElements();
    let list = this.selectedCells.map((cell: any) => {
      let cellData = cell.prop("attrs/customData");
      cellData["selected"] = false;
      this.unHighLight(cell);
    });
    if (list.length === this.selectedCells.length) {
      this.selectedCells = [];
    }
  }

  highLight(cell: any) {
    cell.attr("body/stroke", "black");
    this.updateFinalPosition();
  }

  unHighLight(cell: any) {
    cell.attr("body/stroke", "white");
    this.updateFinalPosition();
  }

  handleAction(cell: any, isShow: boolean = false, isClicked: boolean = false) {
    let cellModel = cell.model;
    let cellData = cellModel.prop("attrs/customData") || cellModel.prop("data");
    let cellType = cellModel.prop("type");

    if (cellType == "custom" && isShow && cellData?.type) {
      this.cellClicked = false;
      this.removeSelectedCells();
    }

    if (!this.presentMode) {
      if (cellType === "overlay" && isClicked) {
        this.overlaySidebarCall(cellData);
      } else if (cellType == "custom") {
        if (isClicked) {
          this.cellClicked = true;
          if (cellData?.isFinal && cellData?.disable) {
            return;
          }
          this.checkForMerged(cellData);
        }
        if (cellData?.canAdd) {
          this.addIconShowHide(cellModel, cellData, isShow);
        }
        if (cellData?.canEditDelete) {
          this.deleteIconShowHide(cellModel, cellData, isShow);
          this.editIconShowHide(cellModel, cellData, isShow);

          setTimeout(() => {
            cellData["hover"] = isShow ? true : false;
          }, 0);
        }
      } else if (cellType == "add-click" && isClicked) {
        this.overlay.remove();
        this.setupOverlayOption(cellModel, this.overlay, this.graph, cellData);
      } else if (cellType == "delete-click" && isClicked) {
        this.actionEmitCall("delete-clusters", cellData);
      } else if (cellType == "edit-click" && isClicked) {
        let data: any = this.options.overlayList.filter(
          (item: any) => item.key === cellData.type
        );
        data[0]["cellData"] = cellData;
        this.overlaySidebarCall(data[0]);
      } else if (cellType == "run-click" && isClicked) {
        if (cellData?.disable) {
          return;
        }
        this.checkForMerged(cellData);
      }
    }
  }

  checkForMerged(cellData: any) {
    let data = this.findNodeById(
      this.treeList[0],
      cellData.parentCellId || cellData.id
    );
    let list = data?.children || [];
    cellData["hasMerged"] = list.some((item: any) => {
      if (item?.dummy) {
        return item.children[0].meta?.merge_parents?.length ? true : false;
      } else {
        return item?.meta?.merge_parents?.length ? true : false;
      }
    });
    this.actionEmitCall("get-list", cellData);
  }

  findNodeById(root: any, targetId: number): any {
    if (root === null) {
      return null;
    }

    if (root.id === targetId) {
      return root;
    }

    // Recursively search in children
    for (const child of root?.children) {
      const result = this.findNodeById(child, targetId);
      if (result !== null) {
        return result;
      }
    }

    // If targetId not found in current node or its children, return null
    return null;
  }

  addIconShowHide(cellModel: any, cellData: any, show: boolean = false) {
    let outgoingLinks = this.getOutgoingLinks(cellModel);
    // Perform adding if no children
    if (outgoingLinks.length == 0) {
      this.addIcon.position(
        cellModel.getBBox().x + 150,
        cellModel.getBBox().y + 10
      ); // Position the addicon
      cellData["isEdit"] = false;
      this.addIcon.prop("data", cellData);
      if (show || cellData?.selected) {
        this.addIcon.attr("text/fill", cellData?.selected ? "white" : "black");
        this.addIcon.attr("rect/fill", cellData?.selected ? "black" : "white");
        this.graph.addCell(this.addIcon);
      } else {
        this.graph.removeCells([this.addIcon]);
      }
    }
  }

  deleteIconShowHide(cellModel: any, cellData: any, show: boolean = false) {
    this.deleteIcon.position(
      cellModel.getBBox().x + 80,
      cellModel.getBBox().y - 45
    ); // Position the deleteicon
    this.deleteIcon.prop("data", cellData);
    if (show || this.cellClicked || cellData?.hover) {
      this.graph.addCell(this.deleteIcon);
    } else {
      this.graph.removeCells([this.deleteIcon]);
    }
  }

  editIconShowHide(cellModel: any, cellData: any, show: boolean = false) {
    this.editIcon.position(
      cellModel.getBBox().x + 30,
      cellModel.getBBox().y - 45
    ); // Position the editicon
    cellData["isEdit"] = true;
    this.editIcon.prop("data", cellData);
    if (show || this.cellClicked || cellData?.hover) {
      this.graph.addCell(this.editIcon);
    } else {
      this.graph.removeCells([this.editIcon]);
    }
  }

  overlaySidebarCall(data: any) {
    switch (data.key) {
      case "feature":
        this.actionEmitCall("get-recommendation", data);
        break;
      case "column":
        this.actionEmitCall("get-recommendation", data);
        break;
      case "add":
        this.uploadDataContent.fileUploadStatus = false;
        this.flowDrawer.toggle();
        this.overlaySetup(data);
        break;
      case "merge":
        let eveData: any = {
          parentCellId: "",
          clusterList: [],
        };
        let cellData: any = {};
        this.selectedCells.forEach((cell: any, index: any) => {
          if (index == 0) {
            cellData = cell.prop("attrs/customData");
          }
          if (cell.id) {
            let data = cell.prop("attrs/customData");
            eveData.clusterList.push(data.cluster_id);
          }
        });
        eveData.parentCellId = cellData.parentCellId;
        this.actionEmitCall("merge-clusters", eveData);
        break;
    }
  }

  //graph update related functionalities
  createLink(srcId: any, tgtId: any, multiple: boolean = false) {
    var link = new joint.dia.Link();
    link.source(multiple ? srcId : { id: srcId });
    link.target(multiple ? tgtId : { id: tgtId });
    link.connector("straight");
    link.attr({
      ".connection": {
        stroke: "#C5C5C5",
        "stroke-width": 1,
      }, // Change the line color
      ".marker-vertices": { display: "none" },
      ".marker-arrowheads": { display: "none" }, // Hide the marker
      ".link-tools": { display: "none" }, // Hide link tools
    });
    return link;
  }

  createCustomCell(cell: any) {
    const CustomElement = this.setupCustomElementBase();
    let ele = new CustomElement();
    if (cell.id) {
      ele.prop("id", cell.id);
    }
    ele.size(cell.size);
    this.setUpShape(cell, ele);
    if (cell.isFinal) {
      this.finalCellId = ele.id;
    }
    return ele;
  }

  updateFinaltargetConnection() {
    let customCellList: any = this.getOnlyCustomCells();
    if (customCellList.length) {
      customCellList.forEach((element: any) => {
        let outgoingLinks = this.graph.getConnectedLinks(element, {
          outbound: true,
        });
        if (!outgoingLinks.length) {
          if (element.id !== this.finalCellId) {
            let link = this.createLink(element.id, this.finalCellId);
            if (link.id) {
              this.graph.addCell(link);
            }
          }
        }
      });
    }
  }

  updateLayout() {
    joint.layout.DirectedGraph.layout(this.graph, {
      dagre: dagre,
      graphlib: graphlib,
      setLinkVertices: false,
      rankDir: "LR",
      rankSep: 100,
      marginX: 10,
      marginY: 10,
      nodeSep: 10,
      edgeSep: 20,
    });

    this.updateFinalPosition();
    this.updateGraphAlignment();
  }

  updateGraphAlignment() {
    // To horizontally and vertically align
    let bbox = this.graph.getBBox();
    if (bbox !== null) {
      let contentWidth = bbox.width * this.paper.scale().sx; // Width of the content
      let contentHeight = bbox.height * this.paper.scale().sx; // Height of the content
      let paperWidth = this.paper.el.clientWidth; // Width of the paper/container
      let paperHeight = this.paper.el.clientHeight; // Height of the paper/container
      let offsetX = Math.max((paperWidth - contentWidth) / 2, 0); // Calculate horizontal offset
      let offsetY = Math.max((paperHeight - contentHeight) / 2, 0); // Calculate vertical offset
      this.paper.setOrigin(offsetX, offsetY); // Set the origin based on the calculated offsets
    }
  }

  updateFinalPosition() {
    this.getOnlyCustomCells().forEach((element) => {
      let outgoingLinks = this.graph.getConnectedLinks(element, {
        outbound: true,
      });
      if (outgoingLinks.length >= 1) {
        let cellData = element.prop("attrs/customData") || element.prop("data");
        outgoingLinks.forEach((link: any) => {
          // position cluster cells
          if (link.getTargetCell().id != this.finalCellId) {
            link
              .getTargetCell()
              .position(
                link.getSourceCell().getBBox().x +
                  (cellData?.meta?.task ? 200 : 230),
                link.getTargetCell().getBBox().y
              );
          }

          // position cluster links
          let sourceBBox = link.getSourceCell().getBBox();
          const sourceCenterX = sourceBBox.x + sourceBBox.width;
          const sourceCenterY = sourceBBox.y + sourceBBox.height / 2;
          let targetBBox = link.getTargetCell().getBBox();
          let targetCellData: any = link
            .getTargetCell()
            .prop("attrs/customData");
          const targetCenterX = targetBBox.x - (targetCellData?.type ? 30 : 0);
          const targetCenterY = targetBBox.y + targetBBox.height / 2;
          const linkMarkerMain = link
            .findView(this.paper)
            .$('path[class*="connection"]');
          linkMarkerMain.attr(
            "d",
            `M ${sourceCenterX} ${sourceCenterY} L ${targetCenterX - 50} ${sourceCenterY} L ${targetCenterX} ${targetCenterY}`
          );
          linkMarkerMain.attr("fill", "none");
        });
      }
    });
  }

  removeExtraElements() {
    this.graph.removeCells([
      this.overlay,
      this.addIcon,
      this.deleteIcon,
      this.editIcon,
    ]);
    this.cellClicked = false;
  }

  // Zoom-in zoom-out related functionalities
  handleZoom(zoom: number) {
    if (this.zoomValue <= 100 && this.zoomValue >= 20) {
      if (zoom == 1 && this.zoomValue < 100) {
        this.currentScale += 0.1;
      } else if (zoom == 0 && this.zoomValue > 20) {
        this.currentScale -= 0.1;
      }
      this.zoomValue = this.getZoomValue();
      this.updateZoomScale();
    }
  }

  updateZoomScale() {
    this.paper.scale(this.currentScale);
    this.updateGraphAlignment();
  }

  getZoomValue(): string {
    return `${(this.currentScale * 100).toFixed(0)}`;
  }

  //Setting graph elements view
  setupCustomElementBase() {
    return joint.dia.Element.define(
      "custom",
      {
        attrs: {
          body: {
            width: "calc(w)",
            height: "calc(h)",
            "stroke-width": 1,
            stroke: "black",
            fill: "white",
            cursor: this.isOldExp ? "" : "pointer",
          },
          label: {
            textVerticalAnchor: "middle",
            textAnchor: "middle",
            x: "calc(0.5*w)",
            y: "calc(0.5*h)",
            fontSize: 14,
            fill: "black",
            cursor: this.isOldExp ? "" : "pointer",
          },
        },
        id: "customID",
      },
      {
        markup: [
          {
            tagName: "rect",
            selector: "body",
          },
          {
            tagName: "text",
            selector: "label",
          },
        ],
      }
    );
  }

  setUpShape(cell: any, ele: any) {
    switch (cell.shape) {
      case 1:
        ele.attr(this.setRectangleAttr(cell));
        break;
      case 2:
        ele.attr(this.setRoundedRectAttr(cell));
        break;
      case 3:
        ele.attr(this.setColoredRectangleAttr(cell));
        setTimeout(() => {
          let runEle = this.setRunElement(ele.id, cell);
          ele.embed(runEle);
          runEle.position(ele.getBBox().x - 30, ele.getBBox().y);
          this.graph.addCell(runEle);
        }, 1000);
        break;
    }
  }

  setRoundedRectAttr(item: any) {
    return {
      body: {
        fill: "white",
        stroke: "#F4EBE6",
        "stroke-width": 1,
        rx: 20, // Border radius
        filter: this.getBoxFilter(),
      },
      label: {
        text: item.label || item.text,
        fill: "#000000",
        "font-size": 14,
        "font-weight": 600,
        "font-family": "Open Sans",
      },
      customData: item,
    };
  }

  setRectangleAttr(item: any) {
    return {
      body: {
        fill: "white",
        stroke: "#F4EBE6",
        "stroke-width": 0,
        rx: 4,
        filter: this.getBoxFilter(),
      },
      label: {
        text: item.label || item.text,
        fill: "#000000",
        "font-size": 14,
        "font-weight": 600,
        "font-family": "Open Sans",
      },
      customData: item,
    };
  }

  setColoredRectangleAttr(item: any) {
    return {
      body: {
        fill:
          item?.isFinal && item?.disable
            ? "#ffffff"
            : this.colorSet[item.type].bg,
        "stroke-width": 1,
        stroke: this.colorSet[item.type].rect,
        rx: 4,
        filter: this.getBoxFilter(),
      },
      label: {
        text: item?.isFinal
          ? item.text
          : "Cluster based \n on " +
            (item.type === "column"
              ? item?.meta?.column_used || item.label || item.text
              : item.label || item.text),
        fill: "#232323",
        "font-size": item?.isFinal ? 16 : 14,
        "font-weight": 600,
        "font-family": "Open Sans",
      },
      customData: item,
    };
  }

  getBoxFilter() {
    return {
      name: "dropShadow",
      args: {
        dx: 0, // Horizontal offset of the shadow
        dy: 0, // Vertical offset of the shadow
        blur: 40, // Amount of blur
        color: "#000000", // Color of the shadow
        opacity: 0.06, // Opacity of the shadow (hexadecimal alpha value)
      },
    };
  }

  setAddIcon() {
    this.addIcon = new joint.shapes.basic.Rect({
      size: { width: 30, height: 30 },
      type: "add-click",
      attrs: {
        rect: {
          fill: "black",
          "stroke-width": 0,
          rx: 5,
          ry: 5,
          cursor: "Pointer",
        },
        text: {
          text: "+",
          fill: "white", // Setting the text color
          "font-size": 35, // Setting the font size
          "font-weight": "normal", // Setting the font weight
          "text-anchor": "middle", // Aligning the text to the middle horizontally
          "alignment-baseline": "central", // Aligning the text to the middle vertically
          cursor: "Pointer",
        },
      },
    });
  }

  setDeleteIcon() {
    this.deleteIcon = new joint.shapes.basic.Image({
      size: { width: 40, height: 40 },
      type: "delete-click",
      attrs: {
        image: {
          "xlink:href": "/assets/images/red-delete-icon.svg",
          cursor: "Pointer",
          width: 40,
          height: 40,
        },
      },
    });
  }

  setEditIcon() {
    this.editIcon = new joint.shapes.basic.Image({
      size: { width: 40, height: 40 },
      type: "edit-click",
      attrs: {
        image: {
          "xlink:href": "/assets/images/red-edit-icon.svg",
          cursor: "Pointer",
          width: 40,
          height: 40,
        },
      },
    });
  }

  setRunElement(cellId: any, cell: any) {
    return new joint.shapes.basic.Rect({
      size: { width: 30, height: 50 },
      type: "run-click",
      data: cell,
      disable: cell?.disable || false,
      attrs: {
        rect: {
          fill: this.colorSet[cell.type].rect,
          "stroke-width": 1,
          stroke: this.colorSet[cell.type].rect,
          rx: 4,
          ry: 4,
          cursor: this.isOldExp ? "" : "pointer",
        },
        text: {
          text: "▶",
          fill: this.colorSet[cell.type].arrow, // Setting the text color
          "font-size": 20, // Setting the font size
          "font-weight": "normal", // Setting the font weight
          "text-anchor": "middle", // Aligning the text to the middle horizontally
          "alignment-baseline": "central", // Aligning the text to the middle vertically
          cursor: this.isOldExp ? "" : "pointer",
        },
      },
    });
  }

  setupOverlayOption(model: any, overlay: any, graph: any, cellData: any) {
    this.graph.addCell([this.overlay]);
    let optionList = [];
    if (this.selectedCells.length > 1) {
      optionList = this.options.overlayList.filter(
        (option: any) => option.key === "merge"
      );
    } else {
      optionList = this.options.overlayList.filter(
        (option: any) => option.key !== "merge"
      );
    }
    this.overlay.position(
      model.getBBox().x + 35,
      model.getBBox().y +
        model.getBBox().height -
        (optionList.length > 1 ? optionList.length - 1 : optionList.length) * 35
    ); // Position the overlay above the rectangle
    this.overlay.size(
      optionList.length == 1 ? 140 : 200,
      optionList.length * (optionList.length == 1 ? 40 : 34)
    );
    optionList.forEach((f: any, i: any) => {
      if (f.viewOption) {
        f["cellData"] = cellData;
        let option = new joint.shapes.basic.Rect({
          size: { width: optionList.length == 1 ? 120 : 180, height: 22 },
          attrs: {
            rect: {
              event: "overlay:pointerdown",
              fill: "#F5F5F5",
              cursor: "pointer",
              "stroke-width": 0,
              stroke: "#F5F5F5",
            },
            text: {
              text: f.label,
              cursor: "pointer",
              "font-size": 12,
              fill: "black",
            },
            customData: f,
          },
          position: {
            x: overlay.attributes.position.x + 10,
            y: overlay.attributes.position.y + 10 + i * 1.5 * 20,
          },
          type: "overlay",
        });
        overlay.embed(option);
        graph.addCell(option);
      }
    });
  }

  //Emit action related functionalities
  onFileAction(event: any, action: any) {
    this.action.emit({
      action: "file-upload",
      data: { event: event, cellData: this.overlayFormList.cellData },
      fileAction: action,
    });
  }

  togglePresentationView() {
    this.presentMode = !this.presentMode;
    this.actionEmitCall("present-mode", this.presentMode);
  }

  getRecommendation() {
    // Subscribe to value changes of the form control
    let value = this.inputForm.get("features")?.value.length;
    if (value && value !== this.featureChangeListLength) {
      this.action.emit({
        action: "update-recommendation",
        data: this.overlayFormList,
        selectedList: this.inputForm.get("features")?.value,
      });
      this.featureChangeListLength = value;
      this.inputForm.controls["n_clusters"].setValue("");
      this.featureDefaults["cluster_recommendation"] = [];
    }
  }

  actionEmitCall(action: any, data: any) {
    this.action.emit({
      action: action,
      data: data,
    });
  }

  // To min.max accordian
  toggleAccordian() {
    this.hideChart = !this.hideChart;
  }

  // Overlay sidebar related functionalities
  overlaySetup(option: any) {
    this.overlayFormList = option;
    if (this.overlayFormList?.fieldList) {
      this.setForm();
    }
  }

  updateMetaDetails(metaData: any) {
    this.metaDetails = metaData?.args || [];
  }

  setForm() {
    this.inputForm = this.formBuilder.group({});
    this.overlayFormList?.fieldList.forEach((field: any) => {
      if (field.model) {
        let validators = [];
        let disabledFlag = false;
        if (field.isRequired) {
          validators.push(Validators.required);
        }
        if (field.pattern) {
          validators.push(Validators.pattern(field.pattern));
        }

        this.inputForm.addControl(
          field.model,
          new FormControl(
            {
              value: field.selectedKey
                ? this.featureDefaults[field.selectedKey]
                : "",
              disabled: disabledFlag,
            },
            validators
          )
        );
      }
    });

    setTimeout(() => {
      this.featureChangeListLength =
        this.inputForm.get("features")?.value.length;
    });
  }

  overlayCallResponse(response: any, data: any, toggle: number = 0) {
    if (data.key == "column") {
      let responseData =
        response[parseInt(data.cellData.cluster_id)] || response[0];
      let category = ["categorical", "numerical"];
      data.fieldList[0].options = [];
      category.forEach((f: any, i: any) => {
        responseData[f].forEach((col: any) => {
          data.fieldList[0].options.push({
            label: toTitleCase(col),
            value: col,
            type: f,
          });
          if (this.metaDetails[2] === col && data.cellData?.isEdit) {
            let item = { label: toTitleCase(col), value: col, type: f };
            this.featureDefaults["selected_column"] = this.metaDetails[2] || "";
            this.radioItemChange(item, data.cellData);
          }
        });
      });
    } else if (data.key == "feature") {
      this.featureDefaults = response.result.data;
      if (this.metaDetails[2]?.length && data.cellData?.isEdit) {
        this.featureDefaults["feature_selection_recommendation"] =
          this.metaDetails[2];
        this.featureDefaults["selected_cluster"] =
          typeof this.metaDetails[3] === "number"
            ? this.metaDetails[3]
            : this.featureDefaults["cluster_recommendation"][0] || "";
      } else {
        this.featureDefaults["selected_cluster"] =
          this.featureDefaults["cluster_recommendation"][0] || "";
      }
      data.fieldList.forEach((item: any) => {
        item.selectOptions = this.featureDefaults[item.optionKey] || [];
      });
    }
    if (!toggle) {
      this.flowDrawer.toggle();
    }
    this.overlaySetup(data);
  }

  checkValid() {
    return this.inputForm.valid ? false : true;
  }

  submitForm() {
    if (this.overlayFormList.key === "bin-chart") {
      this.chartResponse.storeInfo.forEach((store: any) => {
        let value = store[this.inputForm.get("column")?.value];
        this.updatedBins.forEach((bin: any) => {
          if (value >= bin.bins[0] && value <= bin.bins[1]) {
            bin.store_count = bin.store_count + 1;
          }
        });
      });
    }

    this.actionEmitCall("create-clusters", {
      form: this.inputForm,
      cellData: this.overlayFormList.cellData,
      option: this.overlayFormList,
      bins: this.overlayFormList.key === "bin-chart" ? this.updatedBins : [],
    });
  }

  submitResponse(response: any) {
    if (this.inputForm) {
      this.inputForm.reset();
    }
    this.flowDrawer.toggle();
    this.removeExtraElements();
  }

  // Feature based overlay funxtionalities
  clusteringGraphView() {
    this.actionEmitCall("view-graph", this.featureDefaults);
  }

  getClusteringItem(item: any) {
    this.inputForm.patchValue({
      n_clusters: item,
    });
  }

  // Column based overlay functionalities
  radioItemChange(item: any, cellData: any) {
    if (item.type == "numerical") {
      let data = {
        cellData: cellData,
        selectedItem: item,
      };
      this.actionEmitCall("bin-chart", data);
    }
  }

  binChartResponse(response: any, data: any) {
    this.chartResponse = {
      binInfo: response.bin_info,
      selectedData: data.selectedItem,
      storeInfo: JSON.parse(response.poc_selected_column_data),
    };
    this.overlayFormList = {
      label: "Cluster Based on " + data.selectedItem.label,
      key: "bin-chart",
      cellData: data.cellData,
      dropList: [
        {
          label: "Number of Bins",
          type: "dropdown",
          model: "binCount",
          optionList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        {
          label: "Graph Interval",
          type: "input",
          model: "interval",
        },
      ],
      toggleOverlay: true,
      viewOption: false,
      width: "90%",
    };
    this.binsData = [];
    this.options.binChartOption.scales.x.title.text = data.selectedItem.label;
    this.updateInitialBins(response);
  }

  updateInitialBins(response: any) {
    this.binsData = response.bin_info.map((f: any) => f.bins[1]);
    this.binsData.sort((a: any, b: any) => a - b);
    this.interval = 50;
    this.setBinChart();
  }

  updateBinsInfo(event: any) {
    this.updatedBins = event.map((list: any, index: any) => {
      if (index === 0) {
        return { bins: [this.minData - 1, list], store_count: 0 };
      }
      return { bins: [event[index - 1], list], store_count: 0 };
    });
  }

  updateChart(type: any) {
    if (type == "interval") {
      this.interval = parseInt(this.interval);
      this.setBinChart();
    } else if (type == "binCount") {
      this.binsData = [];
      this.refreshBins = false;
      let equalPart = Math.floor(this.maxData / this.binCount);
      let remainder = this.maxData % this.binCount;
      for (let i = 0; i < this.binCount; i++) {
        this.binsData.push(
          equalPart +
            (remainder > i ? 1 : 0) +
            (i > 0 ? this.binsData[i - 1] : 0)
        );
      }
      setTimeout(() => {
        this.refreshBins = true;
      }, 100);
    }
  }

  groupStoresByInterval(response: any, interval: number): any {
    const groupedData: any = {
      labels: [],
      datas: [],
    };

    // Calculate the maximum and minimum values to determine the range
    this.maxData = Math.max(
      ...response.storeInfo.map(
        (item: any) => item[response.selectedData.value]
      )
    );
    this.minData = Math.min(
      ...response.storeInfo.map(
        (item: any) => item[response.selectedData.value]
      )
    );

    // Calculate the number of intervals based on the range
    const range = this.maxData - this.minData;
    const numIntervals = Math.ceil(range / interval);

    // Initialize interval arrays
    for (let i = 0; i <= numIntervals; i++) {
      groupedData.labels.push(`${this.minData + i * interval}`);
      groupedData.datas.push(0);
    }

    // Group data by interval
    response.storeInfo.forEach((item: any) => {
      const intervalIndex = Math.floor(
        (item[response.selectedData.value] - this.minData) / interval
      );
      if (intervalIndex < numIntervals) {
        // Ensure intervalIndex is within bounds
        groupedData.datas[intervalIndex]++;
      } else {
        // Handle the case where the data exceeds the last interval
        groupedData.datas[numIntervals]++;
      }
    });

    return groupedData;
  }

  setBinChart() {
    let groupedData: any = this.groupStoresByInterval(
      this.chartResponse,
      this.interval
    );
    this.chartData = {
      labels: groupedData.labels,
      datasets: [
        {
          label: "Number of Stores",
          data: groupedData.datas,
          backgroundColor: "#325A6DE8",
        },
      ],
    };
  }

  downloadSvg() {
    let expName = this.expDetails?.expName || "";
    let element: any = document.getElementById("paper")!;
    this.fitToScreen();
    html2canvas(element, { scale: 5 }).then(function (canvas: any) {
      // Convert the canvas to blob
      canvas.toBlob(function (blob: any) {
        // To download directly on browser default 'downloads' location
        let link = document.createElement("a");
        link.download = expName + "_chart" + ".png";
        link.href = URL.createObjectURL(blob);
        link.click();
      }, "image/png");
    });
  }

  fitToScreen() {
    let bbox = this.graph.getBBox();
    if (bbox !== null && (bbox.height > 350 || bbox.width > 1000)) {
      let contentWidth = bbox.width;
      let contentHeight = bbox.height;
      let paperWidth = this.paper.el.clientWidth;
      let paperHeight = this.paper.el.clientHeight;

      // Define padding values (adjust as needed)
      let paddingX = 25; // Horizontal padding
      let paddingY = 25; // Vertical padding

      // Calculate the available space after padding
      let availableWidth = paperWidth - 2 * paddingX;
      let availableHeight = paperHeight - 2 * paddingY;

      // Calculate the scaling factors for width and height
      let scaleX = availableWidth / contentWidth;
      let scaleY = availableHeight / contentHeight;

      // Choose the minimum scaling factor to ensure the content fits inside the available space
      let scale = Math.min(scaleX, scaleY);

      // Calculate the new width and height of the content after scaling
      let newWidth = contentWidth * scale;
      let newHeight = contentHeight * scale;

      // Calculate the offset to center the scaled content, including padding
      let offsetX = paddingX + (availableWidth - newWidth) / 2;
      let offsetY = paddingY + (availableHeight - newHeight) / 2;

      // Apply the scaling and offset to the paper
      this.paper.scale(scale, scale).translate(offsetX, offsetY);
      this.updateGraphAlignment();
    }
  }

  migrateToNewCluster() {
    this.actionEmitCall("migrate", "");
  }

  clearForm() {
    this.removeExtraElements();
    this.inputForm.reset();
    this.inputForm.markAsPristine();
    this.inputForm.markAsUntouched();
  }
}
