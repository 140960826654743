import { Pipe, PipeTransform } from '@angular/core';
import { StateService } from '../services/state.service';

@Pipe({
  name: 'fiTitleCase',
})
export class FiTitleCasePipe implements PipeTransform {
  constructor(private stateService: StateService) {}

  transform(text: any) {
    if (text.toLowerCase() === 'parent_sku') {
      return 'Top SKUs';
    }
    if (text.toLowerCase() === 'ptc_segment') {
      return 'PTC Segment';
    }
    if (text) {
      return this.stateService.titleCase(text);
    }
    return text;
  }
}
