import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-screen-guide',
  templateUrl: './screen-guide.component.html',
  styleUrls: ['./screen-guide.component.scss'],
})
export class ScreenGuideComponent implements OnInit {
  Arr = Array; //Array type captured in a variable
  num: number = 0;
  @Input() guideContent: any;
  @Output() slideOutput: EventEmitter<string> = new EventEmitter();
  @Output() slidePreOutput: EventEmitter<string> = new EventEmitter();
  @Output() closeGuideTip: EventEmitter<string> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.num = this.guideContent.count;
  }
  toSlide(dir: any) {
    if (this.guideContent.next !== this.guideContent.current) {
      this.slideOutput.emit(dir);
    }
  }
  preSlide(dir: any) {
    if (this.guideContent.pre !== this.guideContent.current) {
      this.slidePreOutput.emit(dir);
    }
  }
  closeGuide() {
    this.closeGuideTip.emit(this.guideContent.current);
  }
}
