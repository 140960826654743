const landingPageContent = {
  catexpertBannerContent: [
    {
      id: 1,
      image: "/assets/images/experience.svg",
      label: "Ground Level Experience",
      color: "#233B61",
      description:
        "Evolved from experience with more than 37 retailers globally. We have benchmarked against the best industry solutions to create a product with superior accuracy and insights.",
    },
    {
      id: 2,
      image: "/assets/images/industry.svg",
      label: "Simplicity & Value Impact",
      color: "#0091FF",
      description:
        "Artificial Intelligence powered engine allows Category Managers to run an end to end assortment optimization exercise in just a few hours. Proven results with an average of 3-5% incremental sales uplift.",
    },
    {
      id: 3,
      image: "/assets/images/partnerships.svg",
      label: "Partnerships with Think Tanks",
      color: "#B64437",
      description:
        "Powerful collaboration with MIT, the Category Management Association, leading consumer insights and consulting firms to be the best-in-class category management capability for the retailers.",
    },
  ],
  catexpertFeatures: [
    {
      id: 1,
      bg_image: "/assets/images/data.png",
      label: "Know Your Data",
      short_description:
        "Simple reports give you meaningful and actionable insights into your data. Also help you assess quality and completeness of your data.",
    },
    {
      id: 2,
      bg_image: "/assets/images/cluster.png",
      label: "Cluster Insights",
      short_description:
        "Intuitive ML algorithms to help you discover your consumers’ behavior – based on their demographics and consumption patterns. Equips you with the arsenal to target them strategically.",
    },
    {
      id: 3,
      bg_image: "/assets/images/assortment.jpg",
      label: "Configure Business Rules for Assortment Optimization",
      short_description:
        "Led by the end-user. By adjusting different parameters, you can see how the recommendations change.",
    },
    {
      id: 4,
      bg_image: "/assets/images/delist.png",
      label: "What to KEEP, DELIST and CULTIVATE?",
      short_description:
        "Advanced Genetic Algorithm allows you to optimize the suggested SKU combination that maximizes the retailer’s revenue and volume.",
    },
    {
      id: 5,
      bg_image: "/assets/images/simulate.png",
      label: "Simulate Your Own SKU Mix",
      short_description:
        "Unlimited scenario modelling to compare the differences across your simulations. Discover what SKU mix drives the highest incremental profit for your retailer.",
    },
    {
      id: 6,
      bg_image: "/assets/images/business.png",
      label: "Build Planograms Seamlessly",
      short_description:
        "Cutting-edge planogramming capability that flexibly incorporates key business principles, and allows users to experiment with product placements, and increases efficiency.",
    },
  ],
  catexpertFaq: [
    {
      question:
        "What data is required for the tool to generate recommendations?",
      answer:
        "The tool requires sell-out data at a weekly/monthly level and can consume syndicated or retailer sales data, contextual data (weather, macroeconomic, demographic), consumer profiles and first-party consumer data",
    },
    {
      question:
        "What data governance, privacy and security policies are in place?",
      answer:
        "CatExpert.ai does not host any Personal Identifying Information or only retains a minimum amount of raw data when all impacted stakeholders provide consent. We follow all regional privacy laws and our security is managed by Global IT team based out of Brazil and India offices. They run regular security patches to ensure our servers are secured, and follow all ABI IT security norms.",
    },
    {
      question:
        "Do I need a background in data science to understand and run the tool?",
      answer: `We've kept our business partners at the center of design considerations and have built user-friendly modules that can be run by all users, regardless of experience.`,
    },
    {
      question: `Can I add my own clusters or is it mandatory to use the tool's clustering capability?`,
      answer: `The tool is built to absorb your pre-existing clusters, if available. If you possess a multi-dimensional data set, we encourage you to leverage our best-in-class clustering capability to generate the optimal outcomes.`,
    },
    {
      question: `Is the product open to customizations?`,
      answer: `Our team is always open to suggestions and customizations to the tool to ensure that we are offering the best user experience and generating optimal outputs.`,
    },
  ],
  phoBannerContent: [
    {
      id: 1,
      image: "/assets/images/experience.svg",
      label: "Lorem Ipsum",
      color: "#233B61",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 2,
      image: "/assets/images/industry.svg",
      label: "Lorem Ipsum",
      color: "#0091FF",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 3,
      image: "/assets/images/partnerships.svg",
      label: "Lorem Ipsum",
      color: "#B64437",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ],
  phoFeatures: [
    {
      id: 1,
      bg_image: "/assets/images/data.png",
      label: "Lorem Ipsum",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 2,
      bg_image: "/assets/images/cluster.png",
      label: "Lorem Ipsum",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 3,
      bg_image: "/assets/images/assortment.jpg",
      label: "Lorem Ipsum",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 4,
      bg_image: "/assets/images/delist.png",
      label: "Lorem Ipsum",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 5,
      bg_image: "/assets/images/simulate.png",
      label: "Lorem Ipsum",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 6,
      bg_image: "/assets/images/business.png",
      label: "Lorem Ipsum",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ],
  phoFaq: [
    {
      question:
        "What data is required for the tool to generate recommendations?",
      answer:
        "The tool requires sell-out data at a weekly/monthly level and can consume syndicated or retailer sales data, contextual data (weather, macroeconomic, demographic), consumer profiles and first-party consumer data",
    },
    {
      question:
        "What data governance, privacy and security policies are in place?",
      answer:
        "PHO.ai does not host any Personal Identifying Information or only retains a minimum amount of raw data when all impacted stakeholders provide consent. We follow all regional privacy laws and our security is managed by Global IT team based out of Brazil and India offices. They run regular security patches to ensure our servers are secured, and follow all ABI IT security norms.",
    },
  ],
};

export { landingPageContent };
