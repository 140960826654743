<p-calendar
  [iconDisplay]="'input'"
  [showIcon]="true"
  inputId="icondisplay"
  [(ngModel)]="date"
  [timeOnly]="enableTimePicker"
  [hourFormat]="hourFormat"
  [minDate]="minDate"
  (onSelect)="onDateChange($event)"
  styleClass="calendar-input"
  placeholder="Select"
  appendTo="body"
  [disabled]="isDisabled"
></p-calendar>
