<div class="tip">
  <div class="tip-head">
    <div style="padding: 10px">
      <strong>{{ guideContent?.title }}</strong>
      <span style="float: right; margin-top: -1px; cursor: pointer"
        ><i class="fa fa-times" aria-hidden="true" (click)="closeGuide()"></i
      ></span>
    </div>
    <div style="padding: 5px 10px 5px 10px">
      <p style="font-size: smaller">{{ guideContent?.content }}</p>
    </div>
  </div>
  <div class="tip-foot">
    <diV
      [ngClass]="{ disableClr: guideContent.pre === guideContent.current }"
      (click)="preSlide(guideContent.pre)"
      style="cursor: pointer"
    >
      <i class="fa fa-arrow-circle-left"></i> Prev
    </diV>
    <div style="text-align: center">
      <ng-container *ngFor="let i of Arr(num).fill(1); let x = index">
        <div
          class="dot"
          [ngClass]="{ 'active-dot': guideContent.indx === x }"
        ></div>
      </ng-container>
    </div>
    <div
      [ngClass]="{ disableClr: guideContent.next === guideContent.current }"
      (click)="toSlide(guideContent.next)"
      style="cursor: pointer"
    >
      Next <i class="fa fa-arrow-circle-right"></i>
    </div>
  </div>
</div>
