import { ConstantPool } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { StateService } from '../../services/state.service';
@Component({
  selector: 'app-soft-warning-toast',
  templateUrl: './soft-warning-toast.component.html',
  styleUrls: ['./soft-warning-toast.component.scss'],
})
export class SoftWarningToastComponent implements OnInit {
  @Input() message: any;
  @Output() closeIconClicked = new EventEmitter<string>();

  constructor(
    public messageService: MessageService,
    public stateservice: StateService,
  ) {}

  ngOnInit(): void {}
  closeToast() {
    this.closeIconClicked.emit();
  }
}
