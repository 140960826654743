<div class="flex mx-4 mt-3 h-[442px] charts-wrapper">
  <div class="w-[33%] mx-2">
    <div class="flex items-center justify-between">
      <div class="text-xl font-semibold chart-header">MACO Uplift</div>
      <div class="flex items-center gap-2 absolute-right-top">
        <div class="buttons-set">
          <button
            [ngClass]="{ 'is-active': macoTableView }"
            class="btn"
            (click)="showTableView('maco')"
          >
            <app-svg-icon
              icon="table-list"
              padding="10px"
              color="#000"
            ></app-svg-icon>
          </button>
          <button
            class="btn"
            [ngClass]="{ 'is-active': !macoTableView }"
            (click)="showTableView('maco')"
          >
            <app-svg-icon
              icon="stats"
              padding="10px"
              color="#000"
            ></app-svg-icon>
          </button>
        </div>

        <app-download-btn
          btnClassName="btn-border"
          class="p-2"
          [triggerMenu]="true"
          (downloadChart)="
          chartDownload([chartIds[0], 'macoUpliftTable'])
        "
          (downloadExcel)="
          excelDownload(excelHeader, chartData?.macoData, 'macoUpliftTable')
        "
        ></app-download-btn>
      </div>
    </div>
    <div [id]="chartIds[0]" class="h-[360px] mt-[26px]" [hidden]="macoTableView"></div>
    <div class="flex justify-between py-9 px-3" *ngIf="macoTableView">
      <table class="table-trend" id="macoUpliftTable">
        <thead>
          <tr>
            <th>Category</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of chartData?.macoData">
            <td>{{ item?.name }}</td>
            <td>
              {{ formatNumber(nParseFloat(item?.y?.toFixed(0))) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="revenue-chart w-[33%] mx-2">
    <div class="flex items-center justify-between mb-4 pl-2">
      <div class="text-xl font-semibold chart-header">Revenue Uplift</div>
      <div class="flex items-center gap-2 absolute-right-top pr-2">
        <div class="buttons-set">
          <button
            [ngClass]="{ 'is-active': revenueTableView }"
            class="btn"
            (click)="showTableView('revenue')"
          >
            <app-svg-icon
              icon="table-list"
              padding="10px"
              color="#000"
            ></app-svg-icon>
          </button>
          <button
            class="btn"
            [ngClass]="{ 'is-active': !revenueTableView }"
            (click)="showTableView('revenue')"
          >
            <app-svg-icon
              icon="stats"
              padding="10px"
              color="#000"
            ></app-svg-icon>
          </button>
        </div>
        <app-download-btn
          btnClassName="btn-border"
          class="p-2"
          [triggerMenu]="true"
          (downloadChart)="
          chartDownload([chartIds[1], 'revenueUpliftTable'])
        "
          (downloadExcel)="
          excelDownload(excelHeader, chartData?.revenueData, 'revenueUpliftTable')"
        ></app-download-btn>
      </div>
    </div>
    <div [id]="chartIds[1]" class="h-[360px]" [hidden]="revenueTableView"></div>
    <div class="flex justify-between py-9 px-3" *ngIf="revenueTableView">
      <table class="table-trend" id="revenueUpliftTable">
        <thead>
          <tr>
            <th>Category</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of chartData?.revenueData">
            <td>{{ item?.name }}</td>
            <td>
              {{ formatNumber(nParseFloat(item?.y?.toFixed(0))) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="w-[33%]">
    <div class="flex items-center justify-between mb-4 pl-2">
      <div class="text-xl font-semibold chart-header">Volume Uplift</div>
      <div class="flex items-center gap-2 absolute-right-top">
        <div class="buttons-set">
          <button
            [ngClass]="{ 'is-active': volumeTableView }"
            class="btn"
            (click)="showTableView('volume')"
          >
            <app-svg-icon
              icon="table-list"
              padding="10px"
              color="#000"
            ></app-svg-icon>
          </button>
          <button
            class="btn"
            [ngClass]="{ 'is-active': !volumeTableView }"
            (click)="showTableView('volume')"
          >
            <app-svg-icon
              icon="stats"
              padding="10px"
              color="#000"
            ></app-svg-icon>
          </button>
        </div>
        <app-download-btn
          btnClassName="btn-border"
          class="p-2"
          [triggerMenu]="true"
          (downloadChart)="
          chartDownload([chartIds[2], 'volumeUpliftTable'])
        "
          (downloadExcel)="
          excelDownload(excelHeader, chartData?.volumeData, 'volumeUpliftTable')"
        ></app-download-btn>
      </div>
    </div>
    <div [id]="chartIds[2]" class="h-[360px]" [hidden]="volumeTableView"></div>
    <div class="flex justify-between py-9 px-3" *ngIf="volumeTableView">
      <table class="table-trend" id="volumeUpliftTable">
        <thead>
          <tr>
            <th>Category</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of chartData?.volumeData">
            <td>{{ item?.name }}</td>
            <td>
              {{ formatNumber(nParseFloat(item?.y?.toFixed(0))) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>