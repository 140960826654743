import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "./../services/http.service";
import { StateService } from "./../services/state.service";
import { landingPageContent } from "./landing";
@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit, AfterViewInit {
  @Input() control!: AbstractControl;

  @ViewChild("typewriterContainerDiv") typewriterContainerDivRef!: ElementRef;
  // @ViewChild('mainFirstLineDiv') mainFirstLineDivRef!: ElementRef;

  custom!: FormGroup;
  errors: any = {
    email: [
      {
        key: "required",
        message: `Please enter valid email id`,
      },
    ],
    zone: [
      {
        key: "required",
        message: `Please choose zone`,
      },
    ],
  };

  isScheduleMeetSubmitted = false;
  isDomainchk = false;
  isBottom!: boolean;
  zonelist = [
    {
      name: "",
      id: "",
    },
  ];
  email = "";
  emailId = "";
  selectedZone = "";

  isNewsletter = false;
  newsletterMessage = "";
  errorMessage = "";
  isErrorMsg = false;
  isSuccessMessage = false;
  successMessage = "";

  // set up text to print, each item in array is new line
  aText = new Array(
    "leveraging AI analytics and robust data",
    "for clustering, assortment and planograms",
    "adaptable to your needs",
    // 'adaptable to your needs and something more...',
    "to maximize retailer's incremental revenue and improve the shopper experience"
  );
  iSpeed = 10; // less time delay able to read better
  delayInMs!: number;
  firstTime = true;

  animationMs = 4000;
  animationDurationMs = 4000;

  iIndex = 0; // start printing array at this posision
  iArrLength = this.aText[0].length; // the length of the text array
  iTextPos = 0; // initialise text position

  // mainAnimationMs = 0;
  // typingAnimationMs = 0;
  // offsetMs = 0;
  // typewriterFirstTime = true;

  isActive = true;
  delay = 6000;
  groups = new Array(".hg-1", ".hg-2", ".hg-3", ".hg-4");
  animationIndex = 0;
  domainType: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private stateService: StateService,
    private formBuilder: FormBuilder
  ) {
    this.domainType = this.stateService.checkAppConfig();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
      this.isBottom = true;
    } else {
      this.isBottom = false;
    }
  }

  @HostListener("document:click", ["$event"])
  clickout(event: any) {
    let selection = window.getSelection();
    if (event.target.nodeName === "IMG")
      if (selection) {
        selection.removeAllRanges();
      }
  }

  selectedFaq: number = -1;
  allTeam = [
    {
      id: 1,
      proflie_image: "/assets/images/felipe_aragao.png",
      name: "Felipe Aragao",
      role: "Global VP, Analytics",
    },

    {
      id: 2,
      proflie_image: "/assets/images/anthony_berry_1.png",
      name: "Anthony Berry",
      role: "Global VP Key Acc. & Category Mgmt.",
    },
    {
      id: 3,
      proflie_image: "/assets/images/sajith_kurup.png",
      name: "Sajith Kurup",
      role: "Global Director, Analytics",
    },
    {
      id: 4,
      proflie_image: "/assets/images/santiago_1.png",
      name: "Santiago Di Ció",
      role: "Global Dir. Key Acc. & Category Mgmt",
    },
    {
      id: 5,
      proflie_image: "/assets/images/wall.png",
      name: "Product Team",
      role: "",
    },
  ];

  bannerContent = landingPageContent.catexpertBannerContent;
  allFeature = landingPageContent.catexpertFeatures;
  allFaq = landingPageContent.catexpertFaq;

  continueToLogin() {
    window.open(`/login`, "_blank");
  }

  scrollToElement($ele: any): void {
    $ele.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  setFaq(index: number) {
    this.selectedFaq = this.selectedFaq == index ? -1 : index;
  }

  ngOnInit(): void {
    if (this.domainType === "pho") {
      this.setLandingpageContent();
    }
    this.startBannerTextAnimation();
    this.Zone();
    this.custom = this.formBuilder.group({
      email: new FormControl(null, [Validators.required]),
      zone: new FormControl(null, [Validators.required]),
    });
  }

  // Function for change the content for when domain type is pho
  setLandingpageContent() {
    this.bannerContent = landingPageContent.phoBannerContent;
    this.allFeature = landingPageContent.phoFeatures;
    this.allFaq = landingPageContent.phoFaq;
  }

  ngAfterViewInit() {
    if (this.firstTime) {
      this.typewriterContainerDivRef.nativeElement.addEventListener(
        "animationstart",
        () => {
          this.firstTime = false;
        },
        false
      );
    }
  }

  //zone list
  Zone() {
    const url =
      this.domainType !== "pho"
        ? `${this.stateService.apiList.admin.zones}`
        : `${this.stateService.apiList.projects.zone}`;
    this.httpService.getZone(url).subscribe(
      (val) => {
        this.zonelist = val.body;
      },
      (err) => {}
    );
  }

  //newsletter
  Subscribe() {
    if (this.email == "") {
      this.isErrorMsg = true;
      this.errorMessage = "Please enter email";
      return;
    }
    const url = `${this.stateService.apiList.user.newsletter}`;
    const data = {
      email: this.email,
    };
    this.stateService.addCalledApi(url);
    this.httpService.postNewsletter(url, data).subscribe(
      (val) => {
        if (val.status == "200") {
          this.newsletterMessage =
            "You have successfully subscribed to our newsletter.";
        }
        this.stateService.removeCalledApi(url);
        this.isNewsletter = true;
        this.isErrorMsg = false;
      },
      (err) => {
        this.isErrorMsg = true;
        this.errorMessage = err.error.message;
        this.stateService.removeCalledApi(url);
      }
    );
  }

  //newsletter
  Getstarted() {
    if (!this.custom.valid) {
      this.custom.markAllAsTouched();
      return;
    }

    this.isScheduleMeetSubmitted = true;

    const url = `${this.stateService.apiList.user.approvalV3}`;

    this.stateService.addCalledApi(url);
    let payload = this.custom.value;
    payload["zone_id"] = payload["zone"];
    delete payload["zone"];
    this.httpService.postNewsletter(url, payload).subscribe(
      (val) => {
        if (val.status == "200" || val.status == "201") {
          this.successMessage =
            "You've successfully created your Onboarding Request. We will send you the instructions shortly.";
          this.isSuccessMessage = true;
        }
        this.stateService.removeCalledApi(url);
      },
      (err) => {
        this.isSuccessMessage = true;
        if (err.error.message == "You are already onboarded") {
          this.successMessage =
            "Oh, its great! You already have access to the tool. Click Login to Studio to access the tool";
        } else if (err.error.message == "Please enter a valid email") {
          this.successMessage =
            "Oops! Looks like you have entered an invalid email address.";
        } else {
          this.successMessage = err.error.message;
        }

        this.stateService.removeCalledApi(url);
      }
    );
  }

  startBannerTextAnimation() {
    setInterval(() => {
      this.animationIndex++;
      if (this.animationIndex == this.groups.length) this.animationIndex = 0;
    }, this.delay);
  }
}
