<button
  class="btn"
  [ngClass]="buttonClassName"
  [type]="buttonType"
  [disabled]="buttonDisabled"
  (click)="clickEvent(buttonType)"
  [style.min-width.px]="btnMinWidth"
  [style.height]="btnHeight"
  [disabled]="isBtnDisabled"
>
  <img src="/assets/images/{{ buttonIcon }}" *ngIf="buttonIcon" class="mr-2" />
  {{ buttonCaption }}
</button>
