<div
  *ngIf="!uploadDataContent?.fileUploadStatus"
  (dragover)="onFileDragOver($event)"
  (drop)="onFileDrop($event, uploadDataContent?.title)"
  class="{{ styleClassName }}"
>
  <div
    class="row upload-document mx-0 {{
      uploadDataContent?.fileList?.length ? 'upload-document-completed' : ''
    }}"
  >
    <div class="col text-center my-auto">
      <div class="upload-document-icon flex justify-center mb-2">
        <img
          src="/assets/images/{{
            uploadDataContent?.fileList?.length
              ? 'icon-file-upload-completed.svg'
              : 'icon_document_upload.svg'
          }}"
        />
      </div>

      <span class="upload-document-text"
        >{{
          uploadDataContent?.fileList?.length
            ? "Files Uploaded (" + uploadDataContent?.fileList?.length + ")"
            : uploadDataContent?.placeholderText ||
              "Drag & drop your xls file to upload"
        }}
        <br />
        <span *ngIf="!uploadDataContent?.fileList?.length">or</span>
      </span>

      <div
        class="flex justify-center"
        *ngIf="isMultiple ? uploadDataContent?.fileList?.length < 10 : true"
      >
        <input
          style="display: none"
          type="file"
          id="{{ uploadDataContent?.title }}-data"
          class="collection-file"
          [accept]="uploadDataContent.allowedFileType"
          (change)="actionFileBrowse($event)"
          [disabled]="uploadDataContent.disableFlag"
          [multiple]="isMultiple"
        />
        <label
          for="{{ uploadDataContent?.title }}-data"
          class="upload-document-button flex items-center justify-center"
          [ngClass]="uploadDataContent?.disableFlag ? 'disabled' : ''"
          [ngStyle]="{
            cursor: !uploadDataContent?.disableFlag ? 'pointer' : 'default'
          }"
        >
          <span>{{
            uploadDataContent?.fileList?.length ? "Add More" : "Browse"
          }}</span>
        </label>
      </div>
    </div>

    <div
      class="col-8 p-2"
      *ngIf="isMultiple && uploadDataContent?.fileList?.length"
    >
      <div class="file-list-area">
        <ng-container
          *ngFor="let file of uploadDataContent.fileList; let i = index"
        >
          <div class="file-uploaded mb-2 d-flex justify-content-between">
            <div class="d-flex my-auto">
              <img
                src="/assets/images/{{
                  uploadDataContent?.psaIcon
                    ? uploadDataContent?.psaIcon
                    : 'psa-icon.svg'
                }}"
                class="psa-file-icon p-2"
              />
              <div class="my-auto flex items-center">
                <span
                  class="uploaded-file-name uploaded-file-ellipsis"
                  pTooltip="{{ file.fileName }}"
                  tooltipPosition="top"
                  tooltipStyleClass="custom-tooltip"
                  >{{ file.fileName }}</span
                >
                <div class="my-auto ps-2 uploaded-file-percent">(100%)</div>
              </div>
            </div>
            <div class="my-auto">
              <span
                class="file-upload-cancel flex items-center justify-center"
                (click)="
                  actionFileClear(isMultiple ? i : uploadDataContent?.title)
                "
              >
                <img src="/assets/images/cross-icon.svg" alt="close icon"
              /></span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="uploadDataContent?.fileUploadStatus" class="{{ styleClassName }}">
  <div
    class="upload-document upload-document-completed"
    *ngIf="!uploadDataContent?.editModifyCriteria"
  >
    <div class="upload-document-in w-100">
      <div
        class="upload-document-icon flex justify-center"
        [ngClass]="
          uploadDataContent?.loaderFlag
            ? 'xl:mb-0 xl:mt-0 2xl:mb-2 2xl:mt-1'
            : 'xl:mb-4 2xl:mb-4'
        "
      >
        <img
          *ngIf="
            !uploadDataContent?.loaderFlag &&
            uploadDataContent?.fileUploadPercent === 100
          "
          src="/assets/images/icon-file-upload-completed.svg"
        />
        <img
          src="/assets/images/file-uploading.gif"
          *ngIf="uploadDataContent?.loaderFlag"
          class="uploading-gif"
        />
      </div>

      <div
        class="file-uploaded flex items-center"
        [ngClass]="
          uploadDataContent?.loaderFlag
            ? 'xl:mt-2 2xl:mt-3.5'
            : 'xl:mt-6 2xl:mt-8'
        "
      >
        <img
          src="/assets/images/{{
            uploadDataContent?.psaIcon || 'csv-file-icon.svg'
          }}"
          class="csv-file-icon"
        />
        <div class="file-uploaded-in">
          <div class="flex items-center mb-1.5">
            <span class="uploaded-file-name">{{
              uploadDataContent?.fileName
            }}</span>
            <span class="upload-percentage"
              >({{ uploadDataContent?.fileUploadPercent }}%)</span
            >
          </div>

          <mat-progress-bar
            *ngIf="uploadDataContent?.loaderFlag"
            [mode]="
              uploadDataContent?.fileUploadPercent === 0 ||
              uploadDataContent?.fileUploadPercent === 100
                ? 'indeterminate'
                : 'determinate'
            "
            [value]="uploadDataContent?.fileUploadPercent"
          ></mat-progress-bar>
          <div
            class="completed-upload"
            *ngIf="
              !uploadDataContent?.loaderFlag &&
              uploadDataContent?.fileUploadPercent === 100
            "
          >
            Completed
          </div>
        </div>
        <div
          class="flex items-center justify-center"
          *ngIf="
            !uploadDataContent?.loaderFlag &&
            uploadDataContent?.fileUploadPercent === 100
          "
        >
          <span
            class="file-upload-cancel flex items-center justify-center"
            (click)="actionFileClear(uploadDataContent?.title)"
            [ngClass]="{
              'close-disabled': uploadDataContent?.fileUploadStatus
            }"
          >
            <img src="/assets/images/cross-icon.svg" alt="close icon"
          /></span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="processing-document"
    *ngIf="uploadDataContent?.editModifyCriteria"
  >
    <div
      class="file-uploaded flex items-center"
      [ngClass]="
        uploadDataContent?.loaderFlag
          ? 'xl:mt-2 2xl:mt-3.5'
          : 'xl:mt-6 2xl:mt-8'
      "
    >
      <img src="/assets/images/csv-file-icon.svg" class="csv-file-icon" />
      <div class="file-uploaded-in">
        <div class="flex items-center mb-1.5">
          <span class="uploaded-file-name">{{
            uploadDataContent?.fileName
          }}</span>
          <span class="upload-percentage"
            >({{ uploadDataContent?.fileUploadPercent }}%)</span
          >
        </div>
        <div
          class="completed-upload"
          *ngIf="
            !uploadDataContent?.loaderFlag &&
            uploadDataContent?.fileUploadPercent === 100
          "
        >
          Completed
        </div>
      </div>
      <div
        class="flex items-center justify-center"
        *ngIf="
          !uploadDataContent?.loaderFlag &&
          uploadDataContent?.fileUploadPercent === 100
        "
      >
        <span
          class="file-upload-cancel flex items-center justify-center"
          (click)="actionFileClear(uploadDataContent?.title)"
          [ngClass]="{
            'close-disabled': uploadDataContent?.fileUploadStatus
          }"
        >
          <img src="/assets/images/cross-icon.svg" alt="close icon"
        /></span>
      </div>
    </div>
  </div>
</div>
