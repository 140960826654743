import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  sideModal: any = { display: false, isBack: false };

  @Input() sideBarContent: any;
  staticContent: any;
  title = '';
  @Output() onDisplayChanged = new EventEmitter<any>();
  @Output() onScreenGuide = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.title = this.sideBarContent.storeClusteringTitle;
    this.staticContent = this.sideBarContent.clusteringContent;
  }

  goBack() {
    this.onDisplayChanged.emit();
  }
  openCard() {
    this.sideModal.display = true;
    this.sideModal.isBack = true;
  }
  openScreenGuide() {
    this.onScreenGuide.emit();
  }
}
