import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss'
})
export class DatePickerComponent implements OnInit, OnChanges {
  date!: Date;
  hourFormat: string = '12';
  minDate!: any;
  @Input() enableTimePicker: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() ctrlName: any;
  @Input() hostingFrom: string = '';
  @Output() onDateSelect = new EventEmitter<any>();

  ngOnInit(): void {
    this.minDate = !this.enableTimePicker ? new Date() : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes && this.hostingFrom == 'voting-summary') {
      this.date = changes.ctrlName.currentValue;
    // }
  }

  onDateChange(event: any) {
    this.onDateSelect.emit(event);
  }

}
