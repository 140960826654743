<mat-dialog-content class="commom-modal">
  <span (click)="close()" tabindex="-1" class="commom-modal-close">
    <img alt="Close" src="/assets/images/close-dialog.svg" />
  </span>
  <div *ngIf="data.type === 'logout'" class="logout">
    <p class="logout-head my-3 pl-3">Log out</p>
    <p class="logout-body my-3 py-2 pl-3">Are you sure you want to log out</p>
    <div class="text-right">
      <button class="btn logout-cancel" (click)="data.callback(data, 'cancel')">
        Cancel
      </button>
      <button class="btn logout-ok" (click)="data.callback(data, 'ok')">
        OK
      </button>
    </div>
  </div>
  <div *ngIf="data.type === 'delete'">
    <div class="delete-experiment">
      <div class="delete-experiment-header">Delete {{data?.content || 'Experiment'}}</div>
      <div class="delete-experiment-text">
        Are you sure to delete the {{data?.content || 'Experiment'}} ?
      </div>

      <div style="float: right">
        <button
          type="button"
          class="btn cancel-exp-btn"
          data-dismiss="modal"
          (click)="close()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn delete-exp-btn"
          (click)="data.callback(data, 'ok')"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="data.type === 'deletes'" class="logout">
    <p class="logout-body my-3 py-2 pl-3">Are you sure you want to Delete</p>
    <div class="text-right">
      <button
        class="btn btn-app-primary"
        (click)="data.callback(data, 'cancel')"
      >
        Cancel
      </button>
      <button class="btn btn-app-primary" (click)="data.callback(data, 'ok')">
        OK
      </button>
    </div>
  </div>
  <app-message-popup *ngIf="data.type === 'message'" [data]="data">
  </app-message-popup>

  <app-message-popup *ngIf="data.type === 'failed'" [data]="data">
  </app-message-popup>

  <div *ngIf="data.type === 'downloadAndUpload'" class="upload">
    <p class="upload-title">Modify</p>
    <div
      style="
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div
        class="download-section"
        (click)="downloadFile($event, data.data.editableUrl)"
        style="cursor: pointer"
      >
        <div style="display: flex; align-items: center">
          <div>
            <span style="margin-right: 20px">
              <img src="/assets/images/file.svg" />
            </span>
            <strong>Please download the editable sheet</strong>
          </div>
          <div style="margin-left: 100px">
            <span *ngIf="!downloadLoader">
              <img src="/assets/images/downloadimg.svg"
            /></span>
            <span *ngIf="downloadLoader">
              <mat-spinner
                class="example-margin"
                color="primary"
                mode="indeterminate"
                value="100"
                strokeWidth="5"
                diameter="30"
              >
              </mat-spinner
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div class="upload-body">
      <strong
        class="upload-download"
        style="margin-top: 20px; margin-bottom: 20px"
        >Upload the sheet below once edited.</strong
      >

      <label class="upload-section" for="upload-file" style="cursor: pointer">
        <div style="display: flex; flex-direction: column; align-items: center">
          <div>
            <span *ngIf="!data?.data?.progress">
              <img src="/assets/images/file.svg"
            /></span>
            <span *ngIf="data?.data?.progress">
              <mat-spinner
                class="example-margin"
                color="primary"
                mode="indeterminate"
                value="100"
                strokeWidth="5"
                diameter="30"
              >
              </mat-spinner
            ></span>
          </div>
          <div style="font-size: 12px; margin-top: 10px">
            <label style="color: #d1a33c; cursor: pointer"
              >Select file to upload</label
            >
          </div>
        </div>
        <input
          type="file"
          id="upload-file"
          class="upload-file"
          (change)="data.callback($event)"
        />
      </label>
    </div>
  </div>
  <!-- simulation csv  -->
  <div *ngIf="data.type === 'simulationCsv'" class="upload">
    <p class="upload-title">Modify</p>
    <div
      style="
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div
        class="download-section"
        style="cursor: pointer"
        (click)="exportCsv()"
      >
        <div style="display: flex; align-items: center">
          <div>
            <span style="margin-right: 20px">
              <img src="/assets/images/file.svg" />
            </span>
            <strong>Please download the editable sheet</strong>
          </div>
          <div style="margin-left: 100px">
            <span *ngIf="!downloadLoader">
              <img src="/assets/images/downloadimg.svg"
            /></span>
            <span *ngIf="downloadLoader">
              <mat-spinner
                class="example-margin"
                color="primary"
                mode="indeterminate"
                value="100"
                strokeWidth="5"
                diameter="30"
              >
              </mat-spinner
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div class="upload-body">
      <strong
        class="upload-download"
        style="margin-top: 20px; margin-bottom: 20px"
        >Upload the sheet below once edited.</strong
      >
      <label class="upload-section" for="upload-file" style="cursor: pointer">
        <div style="display: flex; flex-direction: column; align-items: center">
          <div>
            <span *ngIf="!data?.upload?.progress">
              <img src="/assets/images/file.svg"
            /></span>
            <span *ngIf="data?.upload?.progress">
              <mat-spinner
                class="example-margin"
                color="primary"
                mode="indeterminate"
                value="100"
                strokeWidth="5"
                diameter="30"
              >
              </mat-spinner
            ></span>
          </div>
          <div style="font-size: 12px; margin-top: 10px">
            <label style="color: #d1a33c; cursor: pointer"
              >Select file to upload</label
            >
            <input
              type="file"
              id="upload-file"
              class="upload-file"
              (change)="data.callback($event)"
            />
          </div>
        </div>
      </label>
    </div>
  </div>
  <div *ngIf="data.type === 'upload'" class="upload">
    <p class="upload-title">Upload</p>
    <div class="upload-body">
      <p class="upload-download">Upload the sheet below once edited.</p>
      <label for="upload-file" class="btn upload-button">
        <i class="fa fa-cloud-download" aria-hidden="true"></i>
        <span class="upload-button-text">Upload</span></label
      >

      <input type="file" id="upload-file" class="upload-file" />
    </div>
  </div>
  <div
    class="mt-4 mb-3 pt-2 px-3"
    *ngIf="data.type === 'error'"
    id="error-modal"
  >
    <ng-container *ngFor="let message of data?.message">
      <p class="mb-2 message">{{ message }}</p>
    </ng-container>
    <ng-container *ngIf="data?.moreDetail">
      <cdk-accordion>
        <cdk-accordion-item
          #accordionItem="cdkAccordionItem"
          [attr.id]="'accordion-header'"
          [attr.aria-expanded]="accordionItem.expanded"
          [attr.aria-controls]="'accordion-body'"
        >
          <span
            class="mb-2 error-message-more"
            (click)="accordionItem.toggle()"
          >
            More Details
            <span>
              <i
                [attr.class]="
                  accordionItem.expanded ? 'fa fa-angle-up' : 'fa fa-angle-down'
                "
                aria-hidden="true"
              ></i>
            </span>
          </span>
          <div
            class="mb-3 error-message-more-text"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body'"
            [attr.aria-labelledby]="'accordion-header'"
          >
            <div
              style="white-space: pre-wrap"
              [innerHTML]="data?.moreDetail"
            ></div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>
    </ng-container>
    <div class="text-center mt-2" *ngIf="data.btnText">
      <button class="btn btn-app-primary" (click)="close()">
        {{ data.btnText }}
      </button>
    </div>
  </div>
</mat-dialog-content>
