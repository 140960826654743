import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-edit-btn",
  templateUrl: "./edit-btn.component.html",
  styleUrls: ["./edit-btn.component.scss"],
})
export class EditBtnComponent implements OnInit {
  @Input() disabledFlag: boolean = false;
  @Output() actionBtnCallback = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  btnAction() {
    this.actionBtnCallback.emit();
  }
}
