import { Component, Input, OnInit } from '@angular/core';
import { SkuAge } from '../../core/enums';

@Component({
  selector: 'app-portfolio-accordion',
  templateUrl: './portfolio-accordion.component.html',
  styleUrls: ['./portfolio-accordion.component.scss']
})
export class PortfolioAccordionComponent implements OnInit {

  @Input() skuAge: string = '';
  @Input() subHeading: string = '';
  showAccordionContent: boolean = false;
  _skuAge = SkuAge;

  constructor() { }

  ngOnInit(): void {
  }

  activeIndexChange(index: any) {
    if (index == 0) {
      this.showAccordionContent = true;
    } else {
      this.showAccordionContent = false;
    }
  }

}
