<p-toast
  styleClass="admin-panel-toast"
  class="mt-3"
  position="top-center"
></p-toast>
<p-table
  [value]="skeletonLoader ? skeletonData : tableData"
  responsiveLayout="scroll"
  [(selection)]="selectedItems"
  dataKey="id"
  styleClass="user-management-table"
  [paginator]="true"
  [rows]="10"
  [columns]="tableColumns"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{first} - {last} of {totalRecords} entries"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="pagination.options"
  (onRowSelect)="checkRow($event)"
  (onRowUnselect)="checkRow($event)"
  (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
  appendTo="body"
  paginatorDropdownAppendTo="body"
  [(first)]="paginationFirst"
>
  <ng-template pTemplate="header">
    <tr>
      <!-- dynamic column names -->
      <ng-container *ngFor="let column of tableColumns">
        <th
          class="{{ column.value }}-th"
          [pSortableColumn]="column.sortable ? column.value : ''"
        >
          {{ column.label }}
          <p-sortIcon
            *ngIf="column.sortable"
            [field]="column.value"
          ></p-sortIcon>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-data>
    <ng-template [ngIf]="skeletonLoader">
      <tr>
        <td *ngFor="let column of tableColumns">
          <p-skeleton height="1rem" styleClass="mb-2"></p-skeleton>
        </td>
      </tr>
    </ng-template>
    <ng-template [ngIf]="!skeletonLoader">
      <tr>
        <!-- dynamic table data -->
        <ng-container *ngFor="let column of tableColumns">
          <td
            class="key-accounts"
            style="color: #325a6d; width: 20rem"
            *ngIf="column.valueType === 'userKeyAccounts'"
            (click)="viewKeyAccounts(data, 'addEditKeyAccounts')"
          >
            {{ data[column.value]?.length }} Key accounts
            <i class="fa fa-eye"></i>
          </td>
          <td
            *ngIf="
              column.value === 'requested_at' ||
              column.value === 'created_at' ||
              column.value === 'approved_at'
            "
          >
            {{ data[column.value] | date: "d MMM YYYY" }}
          </td>
          <td
            *ngIf="
              column.valueType === 'reqUserName' ||
              column.valueType === 'appUsersName'
            "
          >
            {{
              data["first_name"] || data["last_name"]
                ? data["first_name"] + " " + data["last_name"]
                : "-"
            }}
          </td>
          <td
            *ngIf="
              column.valueType === 'reqApprovedBy' ||
              column.valueType === 'reqRejectedBy'
            "
          >
            <span
              *ngIf="
                data['approved_by_first_name'] || data['approved_by_last_name']
              "
            >
              {{ data["approved_by_first_name"] || "" }}
              {{ data["approved_by_last_name"] || "" }}
            </span>
            <span
              *ngIf="
                !data['approved_by_first_name'] &&
                !data['approved_by_last_name']
              "
              >-</span
            >
          </td>
          <td *ngIf="column.valueType === 'expKeyAccount'">
            <span
              style="
                font: normal normal 600 14px/19px Open Sans;
                color: #000000;
              "
              >{{ data["key_account"] }}</span
            >
            <p
              style="
                font: normal normal normal 12px/17px Open Sans;
                color: #7b7b7b;
                margin: 0px;
              "
            >
              {{ data["country"] }} | {{ data["region"] }}
            </p>
          </td>
          <td style="width: 15rem" *ngIf="column.valueType === 'expName'">
            {{ data[column.value] }}
          </td>
          <td
            *ngIf="
              column.valueType === 'expZone' ||
              column.valueType === 'appKeyAccZone'
            "
          >
            {{ zoneDict[data[column.value]] }}
          </td>
          <td
            *ngIf="
              column.valueType === 'expCreatedBy' ||
              column.valueType === 'appZoneAdmin'
            "
          >
            {{ userDict[data[column.value]] }}
          </td>
          <td
            *ngIf="
              column.valueType === 'reqType' ||
              column.valueType === 'userRequestsType'
            "
          >
            {{ requestTypes[data[column.value]] }}
          </td>
          <td
            *ngIf="
              column.valueType === 'expType' ||
              column.valueType === 'userRequestsStatus'
            "
          >
            {{ data[column.value] | titlecase }}
          </td>
          <td *ngIf="column.value === 'role'">
            {{ data[column.value] | titlecase }}
          </td>

          <td *ngIf="column.value === 'is_beta_user'">
            <span *ngIf="data[column.value]">Yes</span>
            <span *ngIf="!data[column.value]">No</span>
          </td>

          <td *ngIf="column.valueType === 'reqCreatedBy'">
            {{
              data["requested_by_first_name"] || data["requested_by_last_name"]
                ? data["requested_by_first_name"] +
                  " " +
                  data["requested_by_last_name"]
                : "-"
            }}
          </td>
          <td *ngIf="column.value === 'is_active'">
            <mat-slide-toggle
              [checked]="data[column.value]"
              class="status-toggle"
              (change)="toggleUserStatus($event, data)"
            >
              <span>{{ data[column.value] ? "Active" : "Inactive" }}</span>
            </mat-slide-toggle>
          </td>
          <td *ngIf="column.valueType === 'appUserZones'">
            {{
              data[column.value]
                ? data[column.value].length > 0
                  ? data[column.value].toString()
                  : "-"
                : "-"
            }}
          </td>
          <td
            *ngIf="
              column.value !== 'actions' &&
              column.value !== 'requested_at' &&
              column.value !== 'created_at' &&
              column.value !== 'approved_at' &&
              column.value !== 'key_accounts' &&
              column.value !== 'key_account' &&
              column.value !== 'zone' &&
              column.valueType !== 'expName' &&
              column.valueType !== 'reqType' &&
              column.valueType !== 'expType' &&
              column.valueType !== 'reqCreatedBy' &&
              column.valueType !== 'expCreatedBy' &&
              column.valueType !== 'userRequestsStatus' &&
              column.valueType !== 'userRequestsType' &&
              column.valueType !== 'appZoneAdmin' &&
              column.valueType !== 'reqApprovedBy' &&
              column.valueType !== 'reqRejectedBy' &&
              column.valueType !== 'appKeyAccZone' &&
              column.value !== 'is_active' &&
              column.value !== 'is_beta_user' &&
              column.valueType !== 'appUserZones' &&
              column.value !== 'role' &&
              column.valueType !== 'appUsersName'
            "
          >
            {{ data[column.value] ? data[column.value] : "-" }}
          </td>
        </ng-container>
        <td
          class="actions-td"
          *ngIf="
            dataFrom !== 'rejectedRequests' &&
            dataFrom !== 'approvedRequests' &&
            dataFrom !== 'userProfile' &&
            dataFrom !== 'userRequests' &&
            dataFrom !== 'experiments'
          "
        >
          <!-- dynamic table actions -->
          <ng-container *ngFor="let action of tableActions">
            <button
              *ngIf="!action.from"
              class="btn {{ action.action }}_btn"
              (click)="onTableAction(data, action)"
            >
              {{ action.label }}
              <img src="assets/images/{{ action.btnIcon }}.svg" alt="" />
            </button>
            <img
              class="reject-approve-images"
              style="margin-right: 8px; cursor: pointer"
              *ngIf="action.from"
              src="assets/images/{{ action.btnIcon }}.svg"
              alt=""
              [title]="action.btnIcon"
              (click)="onTableAction(data, action)"
            />
          </ng-container>
        </td>

        <td class="actions-td" *ngIf="dataFrom === 'experiments'">
          <ng-container>
            <button mat-button [matMenuTriggerFor]="menu">
              <img src="assets/images/popup-icon.svg" alt="" />
            </button>

            <mat-menu #menu="matMenu" class="custom-menu">
              <ng-container *ngFor="let action of tableActions">
                <button
                  mat-menu-item
                  *ngIf="!action.from"
                  (click)="onTableAction(data, action)"
                >
                <div class="content-wrapper">
                  <span class="menu-label">{{ action.label }}</span>
                  <span><img src="assets/images/{{ action.btnIcon }}.svg" alt="" /> </span>
                </div>
                </button>
                <button
                  mat-menu-item
                  *ngIf="action.from"
                  (click)="onTableAction(data, action)"
                >
                <div class="content-wrapper">
                  <span class="menu-label">{{ action.label }}</span>
                  <span>
                  <img
                    src="assets/images/{{ action.btnIcon }}.svg"
                    alt=""
                    [title]="action.btnIcon"
                  />
                  </span>
                </div>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td
        style="text-align: center; padding: 20px 10px"
        [attr.colspan]="columns?.length"
      >
        No records found
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft" let-state> Showing </ng-template>
</p-table>
