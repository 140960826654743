<button
  class="btn {{ btnClass }}"
  [ngClass]="{
    'is-active': toggleOnOff,
  }"
  pTooltip="{{ tooltipText }}"
  tooltipPosition="top"
  (click)="clickEvent()"
>
  <app-svg-icon
    icon="{{ icons }}"
    padding="10px"
    color="#000"
    *ngIf="!text"
  ></app-svg-icon>

  <span *ngIf="text" class="text-sm">{{ text }}</span>
</button>
