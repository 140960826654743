<div class="app-loader-bg" [style.z-index]="zindex">
  <div class="app-loader-center" [style.left]="left">
    <mat-spinner
      class="example-margin"
      [color]="color"
      [mode]="mode"
      [value]="value"
      [strokeWidth]="strokeWidth"
      [diameter]="diameter"
    >
    </mat-spinner>
  </div>
</div>
