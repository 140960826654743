import { Pipe, PipeTransform } from '@angular/core';
import { StateService } from '../services/state.service';

@Pipe({
  name: 'removeText',
})
export class RemoveTextPipe implements PipeTransform {
  constructor(private stateService: StateService) {}

  transform(text: string, removeText: any) {
    if (text && typeof text === 'string') {
      return text.replace(removeText, '');
    }
    return text;
  }
}
