import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import moment from "moment";
import { StateService } from "../../services/state.service";
import { CatExpertRouterLink } from "../../app-routing-enum";
import { HttpService } from "../../services/http.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-pho-sidebar-form",
  templateUrl: "./pho-sidebar-form.component.html",
  styleUrls: ["./pho-sidebar-form.component.scss"],
})
export class PhoSidebarFormComponent implements OnInit, OnChanges {
  @Input() nestingSideBarForm: any = [];
  @Input() nestingFormDefault: any = {};
  @Input() formAccessType: boolean = true;
  @Input() isVotingClosed: boolean = false;
  @Output() action = new EventEmitter<any>();
  @Output() actionChange = new EventEmitter<any>();
  @Output() actionViewGraph = new EventEmitter<any>();
  @Output() actionHelp = new EventEmitter<any>();
  @Output() actionEditUpload = new EventEmitter<any>();
  inputForm!: FormGroup;
  advancedParamsShow: boolean = false;
  activeAccord: any = 0;
  ownersList: any[] = [];
  activeDelistBtn: boolean = false;
  activeKeepBtn: boolean = false;
  lockAssortment: boolean = false;
  expId!: any;
  users: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public stateService: StateService,
    private httpService: HttpService,
    private messageService: MessageService
  ) {
    // syncing assortment steps lock value
    this.stateService.getAssortmentLock.subscribe((newValue: any) => {
      this.setAssortmentStepsLock();
    });
  }

  ngOnInit(): void {
    this.expId = sessionStorage.getItem("expId");
    // locking assortment steps after initiate voting
    this.setAssortmentStepsLock();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.setForm();
    }
  }

  formatLabel(value: number | null): string {
    // Always display the thumb label
    return value !== null ? value.toString() : "";
  }

  setForm() {
    this.inputForm = this.formBuilder.group({});
    if (this.nestingSideBarForm?.arrayFormFlag) {
      this.nestingSideBarForm?.accordionList?.map((fieldArray: any) => {
        fieldArray?.formField?.map((field: any) => {
          this.addFormField(field);
        });
      });
    } else {
      this.nestingSideBarForm?.formField?.map((field: any) => {
        this.addFormField(field);
      });
    }
    if (!this.nestingSideBarForm?.formAccessType) {
      this.inputForm.disable();
    }
    this.activeKeepBtn =
      this.nestingFormDefault["final_verdict"] == "Keep" ? true : false;
    this.activeDelistBtn =
      this.nestingFormDefault["final_verdict"] == "Delist" ? true : false;
  }

  addFormField(field: any) {
    if (field.model) {
      let validators = [];
      let disabledFlag = this.isVotingClosed;
      if (field.isRequired) {
        validators.push(Validators.required);
      }
      if (field.pattern) {
        validators.push(Validators.pattern(field.pattern));
      }
      if (field?.min) {
        validators.push(Validators.min(field.min));
      }
      if (field?.max) {
        validators.push(Validators.max(field.max));
      }

      this.inputForm.addControl(
        field.model,
        new FormControl(
          {
            value: this.nestingSideBarForm?.arrayFormFlag
              ? this.nestingFormDefault[field.model] || 0
              : this.nestingFormDefault[field.model] || "",
            disabled: disabledFlag,
          },
          validators
        )
      );
    }
  }

  // Function for set the clustering form data
  setClusterFormValue() {
    this.inputForm.patchValue({
      feature_list: this.nestingFormDefault["feature_list"],
    });
    this.inputForm.patchValue({
      number_of_cluster: this.nestingFormDefault["number_of_cluster"],
    });
  }

  getClusteringItem(item: any) {
    this.inputForm.patchValue({
      number_of_cluster: item,
    });

    // setting keep/delist buttons active
    this.activeKeepBtn =
      this.nestingFormDefault["final_verdict"] == "Keep" ? true : false;
    this.activeDelistBtn =
      this.nestingFormDefault["final_verdict"] == "Delist" ? true : false;
  }

  onBlurEvent() {
    let data = {};
    let checkStatesStatus = this.stateService.checkStatesFlag(
      CatExpertRouterLink.SkuFunnel
    );
    if (checkStatesStatus) {
      data = {
        key_change: "feature_list",
        check_status: checkStatesStatus,
        input_form: this.inputForm.getRawValue(),
      };
      this.actionChange.emit(data);
    } else {
      if (!this.inputForm.controls["feature_list"].valid) {
        this.inputForm.markAllAsTouched();
        return;
      }
      data = {
        input_form: this.inputForm.getRawValue(),
      };
      this.actionChange.emit(data);
      this.resetClusterFormValue();
    }
  }

  resetClusterFormValue() {
    this.inputForm.controls["number_of_cluster"].setValue("");
    this.nestingFormDefault["number_of_cluster"] = [];
    this.nestingSideBarForm["clusterRecommendation"] = [];
  }

  formAction(action: any) {
    if (action === "submit") {
      if (this.inputForm.valid) {
        this.action.emit(this.inputForm.getRawValue());
      } else {
        this.inputForm.markAllAsTouched();
      }
    }
  }

  clickViewGraph() {
    this.actionViewGraph.emit();
  }

  actionHelpModal() {
    this.actionHelp.emit();
  }

  toggleAdvancedParams() {
    this.advancedParamsShow = !this.advancedParamsShow;
  }

  setActiveAccod(index: any) {
    if (this.activeAccord !== index) {
      this.activeAccord = index;
    } else {
      this.activeAccord = -1;
    }
  }
  onExpiryDateTimeSelect(event: any, type: string) {
    let dateTime = moment(event).format("L");
    this.inputForm.patchValue({ [type]: dateTime });
  }

  getCtrlValue(ctrlName: any) {
    return this.inputForm.controls[ctrlName].value;
  }

  onSearchOwners(event: any) {
    this.ownersList = [
      "bhaskararao.gummidi-ext@ab-inbev.com",
      "ashok.kumar-ext3@ab-inbev.com",
    ];
    if (event?.length) {
      this.inputForm.patchValue({ owner: event });
    }
  }

  onKeepDelist(event: any, value: string) {
    this.activeKeepBtn = value == "Keep" ? true : false;
    this.activeDelistBtn = value == "Delist" ? true : false;
    this.inputForm.patchValue({ keep_delist: value });
  }

  setAssortmentStepsLock() {
    const lockAssortmentSteps = JSON.parse(
      sessionStorage.getItem("lockAssortment")!
    );
    if (lockAssortmentSteps) {
      this.lockAssortment = lockAssortmentSteps[this.expId!];
    }
  }

  getUsersOnType(event: any) {
    const apiUrl = `${this.stateService.apiList.experiments.phoSearchUser}?name=${event}`;
    this.httpService.getMethod(apiUrl).subscribe({
      next: (response: any) => {
        this.users = response;
      },
      error: (err: any) => {
        if (err.status === 400) {
          this.messageService.add({
            key: "w",
            severity: "warn",
            summary: "Something went wrong!",
            detail: err?.error?.message,
          });
        } else if (err.status !== 401) {
          this.messageService.clear();
          this.messageService.add({
            key: "c",
            severity: "warn",
            summary: err?.error?.message,
            detail: err?.error?.traceback,
          });
        }
      },
    });
  }

  onOwnerSelect(event: any, model: any) {
    this.inputForm.patchValue({ [model]: event });
  }
}
