import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { StateService } from "../../services/state.service";
import { VotingStatic } from "../../main-container/pho/voting/voting";
import { UIChart } from "primeng/chart";
import { toTitleCase } from "./../../utils";

@Component({
  selector: "app-sku-details",
  templateUrl: "./sku-details.component.html",
  styleUrl: "./sku-details.component.scss",
})
export class SkuDetailsComponent {
  @Input() showSkuDetailsDialog: boolean = false;
  @Input() skuDetails!: any;
  @Output() onCloseDialog = new EventEmitter<any>();
  @ViewChild("macoSegmentChart") macoSegmentChart!: UIChart;
  @ViewChild("revenueVolumeChart") revenueVolumeChart!: UIChart;
  @Input() dataFrom!: string;
  @Input() withFinancialImpact!: any;
  @Input() skuFeatures:any;

  [key: string]: any; // Index signature
  macoLineChartOptions!: any;
  revenueLineChartOptions!: any;
  macoLineChartData!: any;
  revenueLineChartData!: any;
  votingStatic = VotingStatic;
  legendMacoSegmentChart: any[] = [];
  legendRevenueVolumeChart: any[] = [];
  dataFromComponent: any;

  constructor(
    private stateService: StateService,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataFromComponent = this.dataFrom;
    this.revenueLineChartOptions = this.votingStatic.revenueLineChartOptions;
    this.macoLineChartOptions = this.votingStatic.macoLineChartOptions;
    this.macoLineChartData = this.votingStatic.macoLineChartData;
    this.revenueLineChartData = this.votingStatic.revenueLineChartData;
    this.setLineChartData();
     //hide maco uplift and volume retained for without Financial Impact
     if (!this.withFinancialImpact) {
      let growthFeature = this.skuFeatures.splice(14, 1)[0];
      this.skuFeatures.splice(4, 0, growthFeature);
      this.skuFeatures.splice(10, 0, { label: ""});
      this.skuFeatures = this.skuFeatures.filter(
        (item: any) =>
          item.label != "MACO Uplift" && item.label != "Volume Retained"
      );
    }
  }
  setLineChartData() {
    // updating maco line chart data
    this.macoLineChartData.labels = this.skuDetails.sku_trend?.labels;
    this.skuDetails.sku_trend?.data?.forEach((each: any) => {
      this.macoLineChartData.datasets.forEach((each1: any, index: any) => {
        if (each.name === each1.keyValue) {
          this.macoLineChartData.datasets[index].data = each.values;
        }
      });
    });
    this.macoLineChartData = { ...this.macoLineChartData };

    // updating revenue, volume line chart data
    this.revenueLineChartData.labels = this.skuDetails?.sku_trend?.labels;
    this.skuDetails?.sku_trend?.data?.forEach((each: any) => {
      this.revenueLineChartData.datasets.forEach((each1: any, index: any) => {
        if (each.name === each1.keyValue) {
          this.revenueLineChartData.datasets[index].data = each.values;
        }
      });
    });
    this.revenueLineChartData = { ...this.revenueLineChartData };

    this.updateLegendMacoSegmentChart(this.macoLineChartData);
    this.updateLegendRevenueVolumeChart(this.revenueLineChartData);
    this.cdr.detectChanges();
  }

  nParseFloat(val: any) {
    if (typeof val == "string") {
      return val != "" ? val : "-";
    } else if (this.isInt(val)) {
      return this.formatNumber(val);
    } else if (val && typeof val != "string" && !this.isInt(val)) {
      const floatVal: any = val.toFixed(1);
      return this.formatNumber(floatVal);
    } else {
      return val || "-";
    }
  }

  isInt(num: any) {
    return Number(num) === num && num % 1 === 0;
  }

  formatNumber(num: number) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  getString(input: any) {
    return String(input);
  }

  // Preparing legend for segment line chart
  updateLegendMacoSegmentChart(chartLabels: any) {
    this.legendMacoSegmentChart = chartLabels.datasets.map(
      (dataset: any, index: any) => ({
        label: dataset.label,
        color: dataset.borderColor,
        textHide: true,
      })
    );
  }

  // Preparing legend for segment line chart
  updateLegendRevenueVolumeChart(chartLabels: any) {
    this.legendRevenueVolumeChart = chartLabels.datasets.map(
      (dataset: any, index: any) => ({
        label: dataset.label,
        color: dataset.borderColor,
        textHide: true,
      })
    );
  }

  // Function for update the line chart when click the legend items
  onLegendItemClick(index: any, chartIdName: string, legendName: string) {
    const meta = this[chartIdName].chart.getDatasetMeta(index);
    meta.hidden = !meta.hidden;
    this[legendName][index]["textHide"] = !meta.hidden;
    this[chartIdName].chart.update();
  }

  closeDialog() {
    this.showSkuDetailsDialog = false;
    this.onCloseDialog.emit(this.showSkuDetailsDialog);
  }
  getKeyValue(item: any) {
    if (this.skuDetails[item.key]) {
      return item.formatNumber
        ? this.formatNumber(
            this.nParseFloat(
              item.fixed
                ? this.skuDetails[item.key].toFixed(0)
                : this.skuDetails[item.key]
            )
          )
        : this.nParseFloat(
            item.fixed
              ? this.skuDetails[item.key].toFixed(0)
              : this.skuDetails[item.key]
          );
    } else {
      return 0;
    }
  }
}
