<div>
  <div class="font-semibold text-lg">Filter By</div>
  <div class="py-2" *ngIf="segmentsOptions.length > 0">
    <app-select-dropdown
      class="ml-auto"
      [dropdownOptions]="segmentsOptions"
      [selectedValue]="segmentSelectedValue"
      (actionBtnCallback)="featureDataChange($event, 'ptc_segments')"
    ></app-select-dropdown>
  </div>
  <div class="text-center">or</div>
  <div class="py-2" *ngIf="skusOptions.length > 0">
    <app-select-dropdown
      class="ml-auto"
      [dropdownOptions]="skusOptions"
      [selectedValue]="skuSelectedValue"
      (actionBtnCallback)="featureDataChange($event, 'skus')"
    ></app-select-dropdown>
  </div>
  <div class="flex justify-center items-center mt-3">
    <app-button-primary
      (actionButtonCall)="applyFilters()"
      buttonCaption="Apply"
    ></app-button-primary>
  </div>
</div>
