import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSlider } from "@angular/material/slider";

@Component({
  selector: "app-slider-bar",
  templateUrl: "./slider-bar.component.html",
  styleUrls: ["./slider-bar.component.scss"],
})
export class SliderBarComponent implements OnInit {
  @ViewChild("slider") slider!: MatSlider;

  @Input() sliderValue!: any;
  @Output() sliderChangeCall = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  formatLabel(value: number | null): string {
    // Always display the thumb label
    return value !== null ? value.toString() : "";
  }

  onChangeSlider(event: any) {
    const endValueIndicator =
      this.slider._elementRef.nativeElement.querySelector(
        ".mdc-slider__value-indicator-text"
      );

    if (endValueIndicator) {
      this.sliderChangeCall.emit(endValueIndicator.textContent);
      this.sliderValue = endValueIndicator.textContent;
    }
  }
}
