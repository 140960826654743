import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {

  @Input() icon!: string; // icon name
  @Input() color?: string; // color of the icon
  @Input() padding?: string; // adjust icon size with padding
  @Input() tooltip: string = ''; // tooltip for the icon
  
  constructor() { }

  ngOnInit(): void {
  }

}
