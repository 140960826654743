<p-dialog
  header="Alert"
  [(visible)]="popupData.visibleFlag"
  [style]="{ width: '32vw' }"
  [modal]="true"
  styleClass="edit-alert-popup"
  [draggable]="false"
>
  <div>
    <p>{{ popupData.message }}</p>
    <p class="note-text">
      Tip : If you want to keep the current version, make a clone of the
      experiment.
    </p>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-end gap-3">
      <app-button-secondary
        (click)="clickEvent('No')"
        buttonClassName="min-small"
        buttonCaption="No"
      ></app-button-secondary>
      <app-button-primary
        (click)="clickEvent('Yes')"
        buttonClassName="small"
        buttonCaption="Yes"
      ></app-button-primary>
    </div>
  </ng-template>
</p-dialog>
