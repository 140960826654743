import { Inject, Injectable } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { config } from "../../environments/config";
import { HttpService } from "./http.service";

import {
  UpdateShelfProductsPayload,
  BulkUpdateShelfProductsPayload,
  ProductSyncData,
} from "../main-container/planogram/v2/plano.types";
import { CommonModalComponent } from "../shared/common-modal/common-modal.component";
import { SEGMENT_STYLE_COLORS_ARRAY } from "../main-container/planogram/v2/plano.constants";
import * as XLSX from "xlsx";

const BASE_URL = environment.baseUrl;
const BASE_PLANO_URL = environment.basePlanoUrl;
const EXP_BASE_URL = environment.baseUrlV2;
const EXP_BASE_URL_V3 = environment.baseUrlV3;
const URL = environment.url;
const EDAURL = environment.edaurl;
const EDAURLV2 = environment.edaurlV2;
const EXPURL = environment.expurl;
const SIMULATIONURL = environment.simulationurl;
const USERURL = environment.userUrl;
const SOCKET_URL = environment.socketBaseUrl + environment.socketCommonPath;
const ACCESS_TOKEN_EXPIRY_DURATION = 5;
const USERS_V3_URL = environment.userUrlV3;
const API_URL = environment.apiUrl;
const GO_PROCESS_URL = environment.goProcess;
const PHOBASEURL = environment.phoBaseUrl;
const GOPROCESSSTATES = environment.goProcessStates;
const ASSORTMENT_URL = environment.assortmentUrl;
const AD_CLUSTERING_URL = environment.apiUrl + "api/v1/";

const apiData = {
  user: {
    me: `${USERURL}me`,
    meV3: `${USERS_V3_URL}users/me`,
    login: `${USERURL}login`,
    users: `${USERURL}users/`,
    acceptTerms: `${USERURL}/user/termscondition`,
    usersByID: `${EXP_BASE_URL}/users/`,
    getUser: `${USERURL}user/search`,
    searchUserV3: `${USERS_V3_URL}users/search`,
    logout: `${USERURL}logout`,
    refreshToken: `${USERURL}refresh_access_token`,
    newsletter: `${USERURL}newsletter`,
    approval: `${USERURL}user/approval`,
    approvalV3: `${USERS_V3_URL}users/onboard`,
    sharedWith: `${USERURL}bulk/users/detail`,
    countriesList: `${USERS_V3_URL}countries`,
    countriesListWithZone: `${USERS_V3_URL}countries/zone/list`,
  },
  roles: {
    permission_mappings: `${USERS_V3_URL}roles/permissions/mapping`,
  },
  projects: {
    projects: `${BASE_URL}projects`,
    eda: `${EDAURL}eda`,
    edaV2: `${EDAURLV2}`,
    keyaccounts: `${URL}key_account_by_country`,
    regions: `${URL}regions_by_key_account_and_country`,
    createkeyaccounts: `${URL}key_accounts`,
    createregions: `${URL}regions`,
    zone: `${URL}zones`,
    users: `${USERURL}users`,
    requests: `${USERURL}users/approvals`,
    approval: `${USERURL}user/approvals`,
    bulkApproval: `${USERURL}users/approvals/bulk`,
    status: `${USERURL}user/status`,
  },
  admin: {
    zones: `${USERS_V3_URL}zones`,
    keyAccounts: `${USERS_V3_URL}key-accounts`,
    countries: `${USERS_V3_URL}countries`,
    userRequests: `${USERS_V3_URL}users/requests`,
    users: `${USERS_V3_URL}admin/users`,
    onboardUser: `${USERS_V3_URL}users/onboard`,
    updateZone: (zoneId: string) => `${USERS_V3_URL}zones/${zoneId}`,
    deleteZone: (zoneId: string) => `${USERS_V3_URL}zones/${zoneId}`,
    updateKeyAccount: (keyAccountId: string) =>
      `${USERS_V3_URL}key-accounts/${keyAccountId}`,
    getUsers: (zoneId: string) => `${USERS_V3_URL}zones/${zoneId}/users`,
    getStatus: (userId: string) => `${USERS_V3_URL}users/${userId}/status`,
    updateUser: (userId: string) => `${USERS_V3_URL}users/${userId}`,
    getRequests: (zoneId: string) => `${USERS_V3_URL}zones/${zoneId}/requests`,
    getExperiments: (zoneId: string) =>
      `${EXP_BASE_URL_V3}/zones/${zoneId}/experiments`,
    getKeyAccounts: (zoneId: string) =>
      `${USERS_V3_URL}zones/${zoneId}/key-accounts`,
    migrateZone: (zoneId: string, userId: string) =>
      `${USERS_V3_URL}zones/${zoneId}/users/${userId}/migrate`,
    revokeZone: (zoneId: string, userId: string) =>
      `${USERS_V3_URL}zones/${zoneId}/users/${userId}/revoke`,
    approveRejectRequest: (zoneId: string, requestId: string) =>
      `${USERS_V3_URL}zones/${zoneId}/requests/${requestId}`,
    syncKeyAccounts: (zoneId: string, userId: string) =>
      `${USERS_V3_URL}zones/${zoneId}/users/${userId}/key-accounts`,
    requestNewZone: (zoneId: string) => `${USERS_V3_URL}users/zones/${zoneId}`,
    requestNewKeyAccount: (zoneId: string) =>
      `${USERS_V3_URL}users/zones/${zoneId}/key-accounts`,
  },
  planogram: {
    fileUpload: `${BASE_PLANO_URL}api/v1/`,
    fileUploadV2: `${BASE_PLANO_URL}api/v2/`,
    psaFileUpload: `${BASE_PLANO_URL}api/v2/uploads/experiment`,
    plnFileUpload: `${BASE_PLANO_URL}api/v3/uploads/experiment`,
    downloadSampleTemplate: `${BASE_PLANO_URL}api/v1/planogram/sample/download`,
    GetPSAOfPlanogram: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/export/psa`,
    GetPLNOfPlanogram: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v3/${expId}/plans/${planId}/export/pln`,
    GetExcelOfPlanogram: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/export/excel`,
    getExcelOfAllPlanograms: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/export/excel`,
    GetLeftoutExcelOfPlanogram: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/leftout/export/excel`,
    GetPDFOfPlanogram: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/export/pdf`,
    saveShelf: `${BASE_PLANO_URL}api/v1/`,
    getExistingPlanoDetails: `${BASE_PLANO_URL}/api/v1/`,
    getAllocatedPlanoDetails: `${BASE_PLANO_URL}api/v1/planogram/segment_allocation`,
    getPlansOfPlanogram: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans`,
    getFiltersOfPlanogram: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/filters`,
    getProductsOnFilters: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/products/list`,
    getPlanOfPlano: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}`,
    getSystemPlanOfPlano: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/system`,
    updatePlanOfShelfDimension: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/dimensions`,
    updatePlanOfSegmentAllocation: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/segment-allocations`,
    updatePlanGrid: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}`,
    saveTemplate: () => `${BASE_PLANO_URL}api/v1/templates`,
    getItemsForAPlan: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/products`,
    syncPlanProducts: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/plans/${planId}/products`,
    deleteAllProducts: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/plans/${planId}/products/all`,
    processCheck: (taskId: string) => `${GO_PROCESS_URL}${taskId}/status`,
    getLeftout: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/products/leftout`,
    updateCellItems: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/cells/update`,
    bulkUpdateCellItems: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/plans/${planId}/cells/update`,
    resetPlano: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/reset`,
    planoFromLeftout: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/products/leftout/refresh`,
    getSystemGenItemsForAPlan: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/system/products`,
    getOverallManufacturerShare: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/space-shares`,
    getImportTaskId: (expId: string, version: string = "v2") =>
      `${BASE_PLANO_URL}api/${version}/${expId}/import`,
    deletePlanFromPlanogram: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}`,
    createPlanoInitValidations: (expId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/validations`,
    getPlansOfPlanogramV2: (expId: string, version: string = "v2") =>
      `${BASE_PLANO_URL}api/${version}/${expId}/plans`,
    getAllPSAOfPlanogram: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/export/psa`,
    getAllPLNOfPlanogram: (expId: string) =>
      `${BASE_PLANO_URL}api/v3/${expId}/export/pln`,
    getAllPlansProducts: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/products/all`,
    uploadProducts: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/products/upload`,
    versionHistory: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/versions`,
    setVersionAsCurrent: (expId: string, planId: string, versionId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/versions/${versionId}/current`,
    reAllocateProducts: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/rules/new/reallocate`,
    spacePlanoInitValidation: (refId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/validations`,
    createSpacePlano: (refId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans`,
    createSpacePlanoDynamicNesting: (refId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/dynamic-nesting`,
    cloneSpacePlanoDynamicNesting: (refId: string, planCloneId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/${planCloneId}/dynamic-nesting/clone`,
    getSpacePlanData: (refId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/${planId}`,
    dynamicNestingPreprocessing: (refId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/${planId}/dynamic-nesting/preprocessing`,
    dynamicNesting: (refId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/${planId}/dynamic-nesting`,
    updateSpacePlanData: (refId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/${planId}/dynamic-nesting`,
    initSpaceDynamicNestingValidations: (refId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/${planId}/dynamic-nesting/validations`,
    spaceImpactAnalysis: (refId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/${planId}/impact_analysis`,
    dynamicNestingRefresh: (refId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${refId}/plans/${planId}/dynamic-nesting/refresh`,
    imageVersionUpdate: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans/${planId}/image-version`,
    fetchColumnMappingData: (expId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/psa/data/get`,
    mirroringPlanogram: (expId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/plans/${planId}`,
    migratePlanogram: (expId: string) =>
      `${BASE_PLANO_URL}api/v3/${expId}/migrate-to-v3`,
    packVariant: (expId: string, productId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/products/${productId}`,
    packVariantImageUpload: (expId: string, variant: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/products/${variant}/variants-images/upload`,
    packVariantImageDelete: (expId: string, variant: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/products/${variant}/variants-images/delete`,

    //Plano Dashboard APIs
    getCharts: (expId: string) => `${BASE_PLANO_URL}api/v2/${expId}/charts`,
    updateChart: (expId: string, chartId: string = "") =>
      `${BASE_PLANO_URL}api/v2/${expId}/charts${chartId ? "/" + chartId : ""}`,
    deleteChart: (expId: string, chartId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/charts/${chartId}`,
    fetchChartData: (expId: string) =>
      `${BASE_PLANO_URL}api/v2/${expId}/charts/aggregations/data`,

    // Planogram V3 API's
    getPlanogramObjects: (refId: string, planId: string) =>
      `${BASE_PLANO_URL}api/v3/${refId}/plans/${planId}/objects`,
  },
  templates: {
    getTemplateDetails: (templateId: string) =>
      `${BASE_PLANO_URL}api/v1/templates/${templateId}`,
    getTemplates: (options: {
      country: string;
      key_account: string;
      region: string;
    }) =>
      `${BASE_PLANO_URL}api/v1/templates?country=${options.country}&key_account=${options.key_account}`,
  },
  experiments: {
    getExperiments: `${EXP_BASE_URL}/experiments`,
    getExperimentsV3: `${EXP_BASE_URL_V3}/experiments`,
    dbconnectV1: `${BASE_URL}connect_db`,
    dbStatusV1: `${BASE_URL}db/status`,
    dbconnectV2: `${EXP_BASE_URL}/connect_db`,
    dbStatusV2: `${EXP_BASE_URL}/db/status`,
    createExperiment: `${BASE_URL}experiments`,
    uploadFile: `${BASE_URL}upload_file_experiment`,
    uploadFileV2: `${BASE_URL}/experiments/:experiment_id/file/:category`,
    columnMapping: `${BASE_URL}column_mapping`,
    validation: `${BASE_URL}validation`,
    delete: `${BASE_URL}experiments`,
    getprocess: `${EXPURL}process_by_experiment`,
    individualProcess: `${EXPURL}process`,
    download: `${EXPURL}download`,
    celeryTask: `${SOCKET_URL}celery-task`,
    beforeEda: `${BASE_URL}standardize_column_names`,
    upload: `${EXPURL}upload`,
    updateState: `${EXPURL}process/state_name/`,
    states: `${EXPURL}states`,
    getSimulation: `${SIMULATIONURL}simulation`,
    goProcess: `${GO_PROCESS_URL}`,
    goProcessStates: `${GOPROCESSSTATES}`,
    assortment: `${ASSORTMENT_URL}`,

    phoExperimentsFileUpload: `${PHOBASEURL}/experiments`,
    getLogisticsData: (expId: string, granularityLevel: string) =>
      `${PHOBASEURL}/${expId}/logistics?granularity_level=${granularityLevel}`,
    postLogisticsData: (expId: string) => `${PHOBASEURL}/${expId}/logistics`,
    phoExperiments: `${PHOBASEURL}/experiments`,
    phoBaseUrl: `${PHOBASEURL}`,
    postLevel0Pareto: (expId: string) => `${PHOBASEURL}/${expId}/level0/pareto`,
    getLevel0Pareto: (expId: string, granularityLevel: string) =>
      `${PHOBASEURL}/${expId}/level0/pareto?granularity_level=${granularityLevel}`,
    level0Refresh: (expId: string) => `${PHOBASEURL}/${expId}/level0/refresh`,
    level0ParetoRefresh: (expId: string) =>
      `${PHOBASEURL}/${expId}/level0/pareto/refresh`,
    postNestingCall: (expId: string) =>
      `${PHOBASEURL}/${expId}/nesting/nesting`,
    nestingRefresh: (expId: string) =>
      `${PHOBASEURL}/${expId}/nesting/refresh-nesting`,
    financialImpactProcessCall: (expId: string) =>
      `${PHOBASEURL}/${expId}/financial-impact/process-mapping`,
    financialImpactCall: (expId: string) =>
      `${PHOBASEURL}/${expId}/financial-impact`,
    getSimulationResult: (expId: string) =>
      `${PHOBASEURL}/${expId}/simulation/result`,
    getThresold: (expId: string) =>
      `${PHOBASEURL}/${expId}/simulation/minimum-threshold`,
    createOptimization: (expId: string) =>
      `${PHOBASEURL}/${expId}/optimization/pygad`,
    updateSimulation: (expId: string) =>
      `${PHOBASEURL}/${expId}/simulation/update-output`,
    simulationReport: (expId: string) =>
      `${PHOBASEURL}/${expId}/simulation/report`,
    simulatioUpdateResult: (expId: string) =>
      `${PHOBASEURL}/${expId}/simulation/update-result`,
    votingSummary: (expId: string) => `${PHOBASEURL}/${expId}/voting/summary`,
    votingDepartments: (expId: string) =>
      `${PHOBASEURL}/${expId}/voting/departments`,
    initiateVoting: (expId: string) => `${PHOBASEURL}/${expId}/voting/voting`,
    votingUsers: (expId: string) => `${PHOBASEURL}/${expId}/voting/users`,
    addUser: (expId: string) => `${PHOBASEURL}/${expId}/voting/users`,
    resetUser: (expId: string) => `${PHOBASEURL}/${expId}/voting/users/reset`,
    sendNotification: (expId: string) =>
      `${PHOBASEURL}/${expId}/voting/users/remind`,
    closeVoting: (expId: string) =>
      `${PHOBASEURL}/${expId}/voting/close-voting`,
    updateVoting: (expId: string) => `${PHOBASEURL}/${expId}/voting/voting`,
    submitVoting: (expId: string) =>
      `${PHOBASEURL}/${expId}/voting/vote/submit`,
    saveVoting: (expId: string) => `${PHOBASEURL}/${expId}/voting/vote/save`,
    simulationResult: (expId: string) =>
      `${PHOBASEURL}/${expId}/voting/vote/simulation`,
    skuVoting: (expId: string, voterId: string) =>
      `${PHOBASEURL}/${expId}/voting/vote/skus?voter_id=${voterId}`,
    skuForSpr: (expId: string) => `${PHOBASEURL}/${expId}/spr/sku-for-spr`,
    saveSprDecision: (expId: string) =>
      `${PHOBASEURL}/${expId}/spr/save-spr-decision`,
    concludeSpr: (expId: string) => `${PHOBASEURL}/${expId}/spr/conclude-spr`,
    summarySimulate: (expId: string) =>
      `${PHOBASEURL}/${expId}/summary/simulate`,
    summaryPublish: (expId: string) => `${PHOBASEURL}/${expId}/summary/publish`,
    skuJourneyData: (expId: string) =>
      `${PHOBASEURL}/${expId}/summary/sku_journey`,
    excelDownload: (expId: string) => `${PHOBASEURL}/${expId}/summary/download`,
    skuDetailsDownload: (expId: string) =>
      `${PHOBASEURL}/${expId}/dashboard/report`,
    shareExecutiveSummary: (expId: string) =>
      `${PHOBASEURL}/${expId}/summary/share`,
    sharePprSummary: (expId: string) => `${PHOBASEURL}/${expId}/spr/share`,
    phoSearchUser: `${USERS_V3_URL}users/search-entra-user`,
    downloadSprReport: (expId: string) => `${PHOBASEURL}/${expId}/spr/download`,
    financialImpactReport: (expId: string) =>
      `${PHOBASEURL}/${expId}/financial-impact/download-report`,
    summarySellout: (expId: string) => `${PHOBASEURL}/${expId}/summary/sellout`,
  },
  assortment: {
    prepareClusterData: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/clustering/prepare-cluster-data`,
    clusterProfiling: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/clustering/profiling`,
    clusteringClusters: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/clustering/clusters`,
    clusterProfilingRefresh: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/clustering/profiling/refresh`,
    level0Refresh: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/level0/refresh`,
    level0ParetoRefresh: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/level0/pareto/refresh`,
    postLevel0Pareto: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/level0/pareto`,
    getLevel0Pareto: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/level0/pareto`,
    optimization: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/optimization/pygad`,
    nesting: (expId: string) => `${ASSORTMENT_URL}${expId}/nesting`,
    refreshNesting: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/nesting/refresh`,
    simulation: (expId: string) => `${ASSORTMENT_URL}${expId}/simulation`,
    simulationReport: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/simulation/report`,
    financialImpact: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/financial-impact`,
    financialImpactPDF: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/financial-impact/pdf`,
    clustersList: (expId: string) => `${ASSORTMENT_URL}${expId}/clusters`,
    getClusterData: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/clusters/${clusterId}`,
    dynamicNesting: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/clusters/${clusterId}/dynamic-nesting`,
    // dynamicNestingPreprocessing: (expId: string, clusterId: string) =>
    //   `${ASSORTMENT_URL}${expId}/clusters/${clusterId}/dynamic-nesting/preprocessing`,
    dynamicNestingPreprocessing: (expId: string, planId: string) =>
      `${ASSORTMENT_URL}${expId}/plans/${planId}/dynamic-nesting/preprocessing`,
    downloadSeasonalityTemplate: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/templates/seasonality/download`,
    downloadStyleMatrix: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/templates/style-matrix/download`,
    downloadAssortment: () => `${SIMULATIONURL}simulation/export/excel`,

    // Advanced clustering apis
    getClusterList: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering`,
    prepareBaseData: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/prepare-cluster-data`,
    clusterStoreData: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/store-data-path`,
    modifyStoreData: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/modify/store-data`,
    downloadFile: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/download`,
    deleteClusters: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}`,
    mergeClusters: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/profiling/merge`,
    getColumnsForClusters: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/columns`,
    createColumnClusters: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/profiling/column`,
    getFeaturesForClusters: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/cluster-recommendations`,
    createFeatureClusters: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/profiling/features`,
    refreshClusterProfiling: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/profiling/refresh`,
    finalOutputClusterProfiling: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/profiling`,
    clusterOutput: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/profiling`,
    suggestBins: (expId: string, clusterId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/${clusterId}/suggest-bins`,
    userUploadedCluster: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/profiling/uploaded-data`,
    migrateExperiment: (expId: string) =>
      `${ASSORTMENT_URL}${expId}/advanced-clustering/migrate-experiment`,
  },
  dashboard: {
    getfilters: (expId: string) =>
      `${PHOBASEURL}/${expId}/dashboard/exp-details`,
    getSKUDetailsTablePagination: (
      expId: string,
      pageNumber: number,
      perPage: number
    ) =>
      `${PHOBASEURL}/${expId}/dashboard/sku-details?page_number=${pageNumber}&per_page=${perPage}`,
  },
};

const status = [
  "started",
  "not_started",
  "pending",
  "in_progress",
  "completed",
  "success",
  "failed",
];
const xStatus = {
  started: "started",
  notStarted: "not_started",
  pending: "pending",
  inProgress: "in_progress",
  completed: "completed",
  failed: "failed",
  failure: "failure",
  success: "success",
};

@Injectable({
  providedIn: "root",
})
export class StateService {
  public sharedProperties: { [key: string]: any } = {};
  selectedtemplate: any = false;
  private level = new BehaviorSubject<any>(null);
  public Result = new BehaviorSubject<any>(null);
  private statesResetCall = new Subject<any>();
  setFileName: Subject<any> = new Subject<any>();
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  readonly callSetLevel = this.level.asObservable();
  createNewExp = false;
  guideLayerShow = false;
  hashChanged = false;
  apiList = apiData;
  status = status;
  xStatus = xStatus;
  isDownload = false;
  calledApiList: any = [];
  setTrafficFlow: Subject<any> = new Subject();
  fileValidationData: Subject<any> = new Subject();
  errorMessage = ["Something went wrong, Please try again"];
  permissions = {
    tag_user_role: "users.roles.tag",
    untag_user_role: "users.roles.untag",

    admin_users_list: "admin.users.list",
    admin_requests_view: "admin.requests.view",
    admin_requests_approve: "admin.requests.approve",

    zone_users_list: "zones.users.list",
    zone_users_add: "zones.users.add",
    zone_users_migrate: "zones.users.migrate",
    zone_users_revoke: "zones.users.revoke",

    zone_users_key_account_sync: "zones.users.key-accounts.sync",
    zone_requests_view: "zones.requests.view",
    zone_requests_approve: "zones.requests.approve",

    zones_add: "zones.add",
    zones_edit: "zones.edit",
    zones_delete: "zones.delete",

    key_accounts_add: "key-accounts.add",
    key_accounts_edit: "key-accounts.edit",
    key_accounts_delete: "key-accounts.delete",

    roles_add: "roles.add",
    roles_edit: "roles.edit",
    roles_delete: "roles.delete",

    permissions_add: "permissions.add",
    permissions_edit: "permissions.edit",
    permissions_delete: "permissions.delete",
  };
  process = {
    pending: "(1/3) Data processing is not started yet",
    in_progress: "(2/3) Data processing is started",
    completed: "(3/3) Fetching result",
    failed: "Data processing is failed",
    dotLoader: "...",
  };
  searchCols: any = {
    cluster_output: [
      { key: "cluster_name", dropDown: true, sort: true },
      { key: "cluster_id", dropDown: true, sort: true },
      { key: "poc_id", textSearch: true, sort: true },
      { key: "address", textSearch: true, sort: true },
      { key: "latitude", textSearch: true, sort: true },
      { key: "longitude", textSearch: true, sort: true },
      { key: "store_name", textSearch: true, sort: true },
      { key: "revenue", textSearch: true, sort: true },
      { key: "volume", textSearch: true, sort: true },
      { key: "units_sold", textSearch: true, sort: true },
    ],

    level0_output: [
      { key: "Cluster_Parent_SKU", textSearch: true },
      { key: "cluster_name", dropDown: true },
      { key: "category", dropDown: true },
      { key: "style", dropDown: true },
      { key: "discontinued", dropDown: true },
      { key: "price_anomaly", dropDown: true },
      { key: "low_abs_vol", dropDown: true },
      { key: "seasonal", dropDown: true },
      { key: "distribution", dropDown: true },
      { key: "recent_data", dropDown: true },
      { key: "lack_of_data", dropDown: true },
      { key: "SKU_funnel_flag", dropDown: true },
    ],

    pareto_output: [
      { key: "cluster_name", dropDown: true },
      { key: "category", dropDown: true },
      { key: "style", dropDown: true },
      { key: "brand_category", dropDown: true },
      { key: "ptc_segment", dropDown: true },
      { key: "Cluster_Parent_SKU", textSearch: true },
      { key: "pareto_level", dropDown: true },
      { key: "pareto_flag", dropDown: true },
      { key: "Total_Volume", numberSearch: true },
      { key: "Cumulative_Volume", numberSearch: true },
      { key: "cumulative_%", numberSearch: true },
      { key: "volume_contribution_%", numberSearch: true },
      { key: "volume", numberSearch: true },
    ],

    nesting_output: [
      { key: "cluster_name", dropDown: true },
      { key: "Cluster_Parent_SKU", textSearch: true },
      { key: "nest_no", textSearch: true },
      { key: "brand_category", dropDown: true },
      { key: "ptc_segment", dropDown: true },
      { key: "category", dropDown: true },
      { key: "Parent_SKU", textSearch: true },
      { key: "price_per_ltr", textSearch: true },
      { key: "ROS", textSearch: true },
      { key: "pack_size_bins", dropDown: true },
      { key: "price_bins", dropDown: true },
      { key: "ROS_bins", dropDown: true },
      { key: "pack_size", dropDown: true },
      { key: "pack_type", dropDown: true },
      { key: "volume", numberSearch: true },
      { key: "revenue", numberSearch: true },
      { key: "no_of_stores", numberSearch: true },
      { key: "pack_qty", dropDown: true },
      { key: "style", dropDown: true },
      { key: "cluster_id", dropDown: true },
      { key: "pack_type_bins", dropDown: true },
      { key: "pack_qty_bins", dropDown: true },
    ],

    optimization_output: [
      { key: "Cluster_Parent_SKU", textSearch: true },
      { key: "category", dropDown: true },
      { key: "cluster_name", dropDown: true },
      { key: "sku", textSearch: true },
      { key: "sku_detail", textSearch: true },
      { key: "brand_category", dropDown: true },
      { key: "ptc_segment", dropDown: true },
      { key: "manufacturer", dropDown: true },
      { key: "abi_vs_others", dropDown: true },
      { key: "pack_size", dropDown: true },
      { key: "pack_type", dropDown: true },
      { key: "volume", numberSearch: true },
      { key: "revenue", numberSearch: true },
      { key: "no_of_stores", numberSearch: true },
      { key: "Module", dropDown: true },
      { key: "model_color", dropDown: true },
      { key: "cultivate_flag", dropDown: true },
      { key: "final_color", dropDown: true },
      { key: "Final Color", dropDown: true },
      { key: "size", dropDown: true },
      { key: "style", dropDown: true },
      { key: "cluster_id", dropDown: true },
      { key: "Reason", dropDown: true },
      { key: "model_output", dropDown: true },
    ],

    simulation_output: [
      { key: "Cluster_Parent_SKU", textSearch: true },
      { key: "category", dropDown: true },
      { key: "cluster_name", dropDown: true },
      { key: "sku", textSearch: true },
      { key: "sku_detail", textSearch: true },
      { key: "brand_category", dropDown: true },
      { key: "ptc_segment", dropDown: true },
      { key: "manufacturer", dropDown: true },
      { key: "abi_vs_others", dropDown: true },
      { key: "pack_size", dropDown: true },
      { key: "pack_type", dropDown: true },
      { key: "volume", textSearch: true },
      { key: "revenue", textSearch: true },
      { key: "no_of_stores", numberSearch: true },
      { key: "Module", dropDown: true },
      { key: "model_color", dropDown: true },
      { key: "cultivate_flag", dropDown: true },
      { key: "final_color", dropDown: true },
      { key: "Final Color", dropDown: true },
      { key: "size", dropDown: true },
      { key: "style", dropDown: true },
      { key: "cluster_id", dropDown: true },
      { key: "Reason", dropDown: true },
      { key: "model_output", dropDown: true },
      { key: "simulate_mix", dropDown: true },
    ],
  };
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  country: any;
  keyacc: any;
  downloadProgressForSimulation = new BehaviorSubject<any>(null);
  openSoftWarningToast = new BehaviorSubject<any>(null);
  downloadProgressForPDF = new BehaviorSubject<any>(null);
  pdfGeneration = new BehaviorSubject<any>(null);
  individualProcess: any;
  zoomType = new BehaviorSubject<any>(null);
  assortDateRange: null | undefined | string;
  region: null | undefined | string;
  levelsKeyOrder: any = ["Country", "State", "Region", "Channel"];
  private _lockAssortment$ = new BehaviorSubject(null);
  kbFactor = 1e3;
  mbFactor = 1e6;
  gbFactor = 1e9;
  allowedFileType: any = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/wps-office.xlsx",
    "text/csv",
  ];
  setStatesCall: boolean = false;
  setStatesList: any = [];
  experimentTypes: any = [
    "assortment",
    "space-assortment",
    "data-exploration",
    "clustering",
    "pho",
    "global-dashboard",
  ];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CommonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpService
  ) {
    this.keyacc = sessionStorage.getItem("expKeyAccount");
    this.country = sessionStorage.getItem("expCountry");
    this.region = sessionStorage.getItem("expRegion");
    this.assortDateRange = sessionStorage.getItem("assortDateRange");
  }

  get getAssortmentLock(): any {
    return this._lockAssortment$;
  }

  setAssortmentLock(newValue: any) {
    this._lockAssortment$.next(newValue);
  }

  checkAppConfig() {
    return config?.app;
  }

  getAppDetails() {
    return config;
  }

  addCalledApi(url: string) {
    setTimeout(() => {
      this.calledApiList.push(url);
    }, 0);
  }

  removeCalledApi(url: string) {
    setTimeout(() => {
      this.calledApiList.splice(this.calledApiList.indexOf(url), 1);
    }, 0);
  }
  popOutCalledApi() {
    setTimeout(() => {
      this.calledApiList.pop();
    }, 0);
  }

  showMessage(options: any): void {
    const dialogRef = this.dialog.open(CommonModalComponent, {
      data: {
        ...options,
        callback: (data: any) => {
          this.dialog.closeAll();
        },
      },
      width: "600px",
      maxHeight: "inherit",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  dialogClose() {
    this.dialog.closeAll();
  }

  orderByNumber = (list: any = []) =>
    list.sort(
      (a: { order: number }, b: { order: number }) => a.order - b.order
    );

  getMethodName = (key: string = "") =>
    key &&
    typeof key === "string" &&
    key
      .split("-")
      .map((item, index) =>
        index === 0 ? item : item.charAt(0).toUpperCase() + item.substr(1)
      )
      ?.join("");

  titleCase = (text: string = "") => {
    return (
      text &&
      typeof text === "string" &&
      text
        ?.split("_")
        .map((item) => item.charAt(0).toUpperCase() + item.substr(1))
        ?.join(" ")
    );
  };

  titleRemoveUnderscore = (text: string = "") =>
    text &&
    typeof text === "string" &&
    text?.split("_").join(" ").toLowerCase();

  titleFirstLetterCaps = (text: string = "") =>
    text &&
    typeof text === "string" &&
    text
      .split("_")
      .map((item, index) =>
        index === 0 ? item.charAt(0).toUpperCase() + item.substr(1) : item
      )
      ?.join(" ");

  expTypeTransform(value: string) {
    if (!value) {
      return "";
    }
    return value.replace(/[\s_]+/g, "-").toLowerCase();
  }

  staticPayload(
    experiment_id: any,
    proProcssing: any = {},
    featureList: any = [],
    noOfClustering: any = "",
    country: string = "",
    keyacc: string = "",
    region: string = "",
    assortDateRange: string = ""
  ) {
    return {
      prepareClusterData: {
        state_id: "prepare-cluster-data",
        experiment_id: experiment_id,
        meta: {
          id: "prepare-cluster-data",
          queue: "clustering-queue",
          args: [
            experiment_id,
            proProcssing,
            country,
            keyacc,
            region,
            assortDateRange,
          ],
          kwargs: {},
        },
      },
      clusters: {
        state_id: "clusters",
        experiment_id: experiment_id,
        meta: {
          id: "clusters",
          queue: "clustering-queue",
          args: [experiment_id, featureList],
          kwargs: {},
        },
      },
      profiling: {
        state_id: "profiling",
        experiment_id: experiment_id,
        meta: {
          id: "profiling",
          queue: "clustering-queue",
          args: [
            experiment_id,
            featureList,
            parseInt(noOfClustering),
            true,
            null,
            keyacc,
            country,
            region,
            assortDateRange,
          ],
          kwargs: {},
        },
      },
      profilingWithoutStore: {
        state_id: "profiling",
        experiment_id: experiment_id,
        meta: {
          id: "profiling",
          queue: "clustering-queue",
          args: [
            experiment_id,
            [],
            0,
            false,
            null,
            keyacc,
            country,
            region,
            assortDateRange,
          ],
          kwargs: {},
        },
      },
      refreshProfiling: {
        state_id: "refresh-profiling",
        experiment_id: experiment_id,
        meta: {
          id: "refresh-profiling",
          queue: "clustering-queue",
          args: [experiment_id],
          kwargs: {},
        },
      },
    };
  }

  preProcessing() {
    return [
      {
        key: "size",
        option: ["pivot"],
      },
      {
        key: "pack_type",
        option: ["pivot"],
      },
      {
        key: "ptc_segment",
        option: ["pivot"],
      },
    ];
  }

  getHeader(data: any = []) {
    const head: any = [];
    data.forEach((element: any) => {
      for (let key in element) {
        if (head.indexOf(key) === -1) {
          head.push(key);
        }
      }
    });
    return head;
  }

  getHeaderWithFilters(data: any = [], filter: any = []) {
    const head: any = [];
    data.forEach((element: any) => {
      for (let key in element) {
        if (head.map((e: any) => e.title).indexOf(key) === -1) {
          const search =
            (Array.isArray(filter) &&
              filter?.find((item: any) => item?.key === key)) ||
            {};
          let dropDownValues: any[] = [];
          if (search.dropDown) {
            dropDownValues = [...new Set(data.map((item: any) => item[key]))];
          }
          head.push({
            title: key,
            textSearch: search?.textSearch || false,
            dropDown: search?.dropDown || false,
            numberSearch: search?.numberSearch || false,
            numberValues: search?.numberSearch ? ["<=", ">="] : [],
            dropDownValues: search?.dropDown ? dropDownValues : [],
            sortOrder: "sort",
            show: true,
          });
        }
      }
    });
    return head;
  }

  getTableHeaderWithFilters(data: any = [], filter: any = []) {
    const head: any = [];
    data.forEach((element: any) => {
      for (let key in element) {
        if (head.map((e: any) => e.value).indexOf(key) === -1) {
          const search =
            (Array.isArray(filter) &&
              filter?.find((item: any) => item?.key === key)) ||
            {};
          let dropDownValues: any[] = [];
          if (search.dropDown) {
            dropDownValues = [...new Set(data.map((item: any) => item[key]))];
          }

          head.push({
            label: this.titleCase(key),
            value: key,
            valueType: "",
            filterType: search?.textSearch
              ? "input_text"
              : search?.dropDown
                ? "dropdown"
                : "",
            filterOptions: [],
            filterMatchMode: search?.textSearch ? "contains" : "",
            sortable: search?.sort || true,
          });
        }
      }
    });
    return head;
  }

  nFormatter(num: number) {
    if (+num >= 1000000000) {
      return (+num / 1000000000).toFixed().replace(/\.0$/, "") + "B";
    }
    if (+num >= 1000000) {
      return (+num / 1000000).toFixed().replace(/\.0$/, "") + "M";
    }
    if (+num >= 1000) {
      return (+num / 1000).toFixed().replace(/\.0$/, "") + "K";
    }
    return num;
  }

  nFormatterDecimal(num: number) {
    const isNegative = num < 0;
    num = Math.abs(num); // Take the absolute value

    let formattedNum;
    if (num >= 1000000000) {
      formattedNum = (num / 1000000000).toFixed(1) + "B";
    } else if (num >= 1000000) {
      formattedNum = (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      formattedNum = (num / 1000).toFixed(1) + "K";
    } else {
      formattedNum = num.toString();
    }

    return isNegative ? "-" + formattedNum : formattedNum;
  }

  nFormatterVolume(num: number, type: string) {
    const isNegative = num < 0;
    num = Math.abs(num); // Take the absolute value

    let formattedNum;
    if (type === "Total Volume" || type === "Volume") {
      if (num >= 1000000000) {
        formattedNum =
          (num / 1000000000).toFixed().replace(/\.0$/, "") + "B HL";
      } else if (num >= 1000000) {
        formattedNum = (num / 1000000).toFixed().replace(/\.0$/, "") + "M HL";
      } else if (num >= 1000) {
        formattedNum = (num / 1000).toFixed().replace(/\.0$/, "") + "K HL";
      } else {
        formattedNum = num.toString();
      }
    } else {
      formattedNum = num.toString();
    }

    return isNegative ? "-" + formattedNum : formattedNum;
  }

  nFormatterVolumeDecimal(num: number, type: string) {
    const isNegative = num < 0;
    num = Math.abs(num); // Take the absolute value

    let formattedNum;
    if (type === "Total Volume") {
      if (num >= 1000000000) {
        formattedNum = (num / 1000000000).toFixed(1) + "B HL";
      } else if (num >= 1000000) {
        formattedNum = (num / 1000000).toFixed(1) + "M HL";
      } else if (num >= 1000) {
        formattedNum = (num / 1000).toFixed(1) + "K HL";
      } else {
        formattedNum = num.toString();
      }
    } else {
      formattedNum = num.toString();
    }

    return isNegative ? "-" + formattedNum : formattedNum;
  }

  nParseFloat(num: number) {
    return num.toFixed(2);
  }

  sortObject(obj: any) {
    // Get an array of the keys:
    let keys = Object.keys(obj);

    // Then sort by using the keys to lookup the values in the original object:
    keys.sort((a, b) => obj[b] - obj[a]);

    return keys;
  }

  formatPayload(
    state_id: string,
    experiment_id: string,
    id: string,
    queue: string,
    args: any,
    kwargs: any = {}
  ) {
    return {
      state_id: state_id,
      experiment_id: experiment_id,
      meta: {
        id: id,
        queue: queue,
        args: args,
        kwargs: kwargs,
      },
    };
  }

  getStateArgs(state_id: string) {
    let stateArgs: any = [];
    const allState =
      (localStorage.allState && JSON.parse(localStorage.allState)) || [];
    allState.forEach((element: any) => {
      if (
        element?.name === state_id &&
        element.meta_schema?.args?.datatype === "list"
      ) {
        stateArgs = element.meta_schema?.args?.params || [];
      }
    });
    return stateArgs;
  }

  getSearchCols(key: string) {
    return this.searchCols[key] || [];
  }

  filterValues(
    originalTableData: any = [],
    formValue: any = {},
    tableName: any = "level0_output"
  ) {
    const tableFields = this.getSearchCols(tableName);
    return originalTableData.filter((element: any) => {
      let isPresent = true;

      for (let key in formValue) {
        if (
          formValue[key] === "" ||
          formValue[key] === null ||
          formValue[key] === undefined
        ) {
          isPresent = true;
          continue;
        }

        const tableKey = tableFields.find((item: any) => item.key === key);

        if (typeof element?.[key] === "number" && tableKey?.number) {
          isPresent = element[key] >= parseInt(formValue[key]);
        }

        if (tableKey?.dropDown) {
          if (element[key] === formValue[key]) {
            isPresent = true;
          } else {
            isPresent = false;
          }
        }

        if (tableKey?.textSearch) {
          if (typeof element?.[key] === "string") {
            isPresent = element[key]
              .toLowerCase()
              .includes(formValue[key].toLowerCase());
          } else if (typeof element?.[key] === "number") {
            isPresent = element[key].toString().includes(formValue[key]);
          }
        }

        if (
          tableKey?.numberSearch &&
          formValue[`${key}_numberSearchOperator`] &&
          formValue[key] !== null
        ) {
          if (formValue[`${key}_numberSearchOperator`] == ">=") {
            isPresent = parseInt(element[key]) >= parseInt(formValue[key]);
          } else {
            isPresent = parseInt(element[key]) <= parseInt(formValue[key]);
          }
        }

        if (!isPresent) {
          break;
        }
      }

      return isPresent;
    });
  }

  showClearAll(values: any = {}) {
    let isShow = false;
    for (let key in values) {
      if (values[key]?.toString()) {
        isShow = true;
      }
    }
    return isShow;
  }

  setTokenExpiryDate() {
    window.localStorage.removeItem("tokenExpiryDate");

    let tokenExpiryDuration: number = ACCESS_TOKEN_EXPIRY_DURATION;
    let currentDate = new Date();
    let tokenExpiryDate = new Date(currentDate.getTime());

    tokenExpiryDate.setMinutes(currentDate.getMinutes() + tokenExpiryDuration);
    window.localStorage.setItem("tokenExpiryDate", tokenExpiryDate.toString());
  }

  getTokenExpiryDate() {
    return new Date(Date.parse(window.localStorage.tokenExpiryDate));
  }

  getToken() {
    return window.localStorage.c;
  }

  getRefreshToken(): string | null {
    return window.sessionStorage.d;
  }

  getProcessByExpId() {
    setTimeout(() => {
      const expId = sessionStorage.getItem("expId");
      const url = `${this.apiList.experiments.getprocess}/${expId}/self`;
      this.http.getMethod(url).subscribe(
        (val: any) => {
          if (val) {
            const states = val.map((item: any) => ({
              order: item.order,
              status: item.status,
              state_id: item.state_id,
            }));

            sessionStorage.setItem("process", JSON.stringify(states));
            this.updatedProcess(states);
          }
        },
        (err: any) => {}
      );
    }, 300);
  }

  refreshToken() {
    const url = `${this.apiList.user.refreshToken}`;
    return this.http.getMethod(url);
  }

  updatedProcess(states: any) {
    this.level.next(states);
  }
  openToastService(result: any) {
    this.Result.next(result);
  }
  setDownloadProgress(status: any) {
    this.downloadProgressForSimulation.next(status);
  }
  setPDFDownloadProgress(status: any) {
    this.downloadProgressForPDF.next(status);
  }
  //planogram services
  createPlanogram(params: any) {
    const url = `${this.apiList.experiments.getExperiments}`;
    return this.http.postMethod(url, params);
  }

  saveShelfDetailsService(url: any, params: any) {
    return this.http.postMethod(url, params);
  }

  // API to fetch templates list
  getTemplatesList(options: any) {
    const url = this.apiList.templates.getTemplates(options);
    return this.http.getMethod(url);
  }

  // API to get template details
  getTemplateDetails(templateId: string) {
    const url = this.apiList.templates.getTemplateDetails(templateId);
    return this.http.getMethod(url);
  }

  saveTemplate(params: any) {
    const url = this.apiList.planogram.saveTemplate();
    return this.http.postMethod(url, params);
  }

  saveExistingPlanShelfService(url: any, params: any) {
    return this.http.putMethod(url, params);
  }
  // API to fetch the Plans
  getPlansOfPlanogram(expId: string) {
    const url = this.apiList.planogram.getPlansOfPlanogram(expId);
    return this.http.getMethod(url);
  }

  // API for Create Plan with leftout skus
  createPlanWithLeftOutSkus(expId: string) {
    const url = this.apiList.planogram.getPlansOfPlanogram(expId);
    return this.http.postMethod(url, {});
  }

  // API to fetch products of a specific plan
  getItemsForAPlan(expId: string, planId: string, version: any = null) {
    const url = this.apiList.planogram.getItemsForAPlan(expId, planId);
    if (version !== null) {
      return this.http.getMethod(url + "?version_id=" + version);
    }
    return this.http.getMethod(url);
  }

  // API to sync products of a specific plan
  syncProductChanges(
    expId: string,
    planId: string,
    data: { products: ProductSyncData[] }
  ) {
    const url = this.apiList.planogram.syncPlanProducts(expId, planId);
    return this.http.putMethod(url, data);
  }

  // API to delete all products of a specific plan
  deleteAllProducts(expId: string, planId: string) {
    const url = this.apiList.planogram.deleteAllProducts(expId, planId);
    return this.http.deleteMethod(url);
  }

  planoFileUploadProcessStatus(expId: string) {
    return this.http.getMethod(this.apiList.planogram.processCheck(expId));
  }

  getPlanoLeftoutItems(expId: string) {
    return this.http.getMethod(this.apiList.planogram.getLeftout(expId));
  }

  updateItemsOfShelfCell(
    expId: string,
    planId: string,
    data: Partial<UpdateShelfProductsPayload>
  ) {
    const url = this.apiList.planogram.updateCellItems(expId, planId);
    return this.http.putMethod(url, data);
  }

  bulkUpdateShelfItems(
    expId: string,
    planId: string,
    data: Partial<BulkUpdateShelfProductsPayload>
  ) {
    const url = this.apiList.planogram.bulkUpdateCellItems(expId, planId);
    return this.http.putMethod(url, data);
  }

  resetPlanogram(expId: string, planId: string) {
    const url = this.apiList.planogram.resetPlano(expId, planId);
    return this.http.postMethod(url);
  }

  createPlanoFromLeftout(expId: string) {
    const url = this.apiList.planogram.planoFromLeftout(expId);
    return this.http.postMethod(url);
  }

  getSystemGenItemsForAPlan(
    expId: string,
    planId: string,
    version: any = null
  ) {
    const url = this.apiList.planogram.getSystemGenItemsForAPlan(expId, planId);
    if (version !== null) {
      return this.http.getMethod(url + "?version_id=" + version);
    }
    return this.http.getMethod(url);
  }

  //planogram final services

  clearPlanoV2LocalStorage() {
    sessionStorage.removeItem("plansDetails");
    sessionStorage.removeItem("selectedPlanogramDetails");
    sessionStorage.removeItem("selectedPlanOfPlano");
    sessionStorage.removeItem("experimentMode");
  }

  getExistingPlan(url: any) {
    return this.http.getMethod(url);
  }

  createPlans(url: any, params: any) {
    return this.http.postMethod(url, params);
  }
  getPlan(expId: string, planId: string) {
    const url = this.apiList.planogram.getPlanOfPlano(expId, planId);
    return this.http.getMethod(url);
  }

  getSystemPlan(expId: string, planId: string, version: any = null) {
    const url = this.apiList.planogram.getSystemPlanOfPlano(expId, planId);
    if (version !== null) {
      return this.http.getMethod(url + "?version_id=" + version);
    }
    return this.http.getMethod(url);
  }

  deletePlanFromPlanogram(expId: string, planId: string) {
    const url = this.apiList.planogram.deletePlanFromPlanogram(expId, planId);
    return this.http.deleteMethod(url);
  }

  updatePlanShelfDimension(expId: string, planId: string, params: any) {
    const url = this.apiList.planogram.updatePlanOfShelfDimension(
      expId,
      planId
    );
    return this.http.putMethod(url, params);
  }
  updatePlanSegmentAllocation(expId: string, planId: string, params: any) {
    const url = this.apiList.planogram.updatePlanOfSegmentAllocation(
      expId,
      planId
    );
    return this.http.putMethod(url, params);
  }
  updatePlanGrid(expId: string, planId: string, params: any) {
    const url = this.apiList.planogram.updatePlanGrid(expId, planId);
    return this.http.putMethod(url, params);
  }
  getCsvForDownloadTemplate() {
    const url = this.apiList.planogram.downloadSampleTemplate;
    return this.http.getExcelMethod(url);
  }
  getPSAFileForPlanogram(expId: string, planId: string, fileType?: string) {
    const url =
      fileType === "pln"
        ? this.apiList.planogram.GetPLNOfPlanogram(expId, planId)
        : this.apiList.planogram.GetPSAOfPlanogram(expId, planId);
    return this.http.getBlobMethod(url);
  }
  getExcelFileForPlanogram(
    expId: string,
    planId: string,
    allPlans: boolean = false
  ) {
    const url = allPlans
      ? this.apiList.planogram.getExcelOfAllPlanograms(expId)
      : this.apiList.planogram.GetExcelOfPlanogram(expId, planId);
    return this.http.getExcelMethod(url);
  }
  getLeftoutForPlanogram(expId: string) {
    const url = this.apiList.planogram.GetLeftoutExcelOfPlanogram(expId);
    return this.http.getExcelMethod(url);
  }
  getPDFForPlanogram(expId: any, planId: any, data: any) {
    const url = this.apiList.planogram.GetPDFOfPlanogram(expId, planId);
    return this.http.postMethod(url, data);
  }
  getAllPSAFileForPlanogram(expId: string, fileType?: string) {
    const url =
      fileType === "pln"
        ? this.apiList.planogram.getAllPLNOfPlanogram(expId)
        : this.apiList.planogram.getAllPSAOfPlanogram(expId);
    return this.http.getBlobMethod(url);
  }

  //open Soft warning toast
  openSoftWarningToastService(message: any) {
    let marginClass = sessionStorage.getItem("toastMarginClass");
    let openToastInfo: any = {
      message: message,
      marginClass: marginClass,
    };
    this.openSoftWarningToast.next(openToastInfo);
  }
  getBulkUserDetailsService(payload: any) {
    const url = this.apiList.user.sharedWith;
    return this.http.postMethod(url, payload);
  }

  connectDBProcessCheck(expId: string) {
    return this.http.getMethod(this.apiList.planogram.processCheck(expId));
  }
  //terms and conditions
  acceptTermsAndondition(params: any) {
    const url = this.apiList.user.acceptTerms;
    return this.http.putMethod(url, params);
  }

  getExperimentDetailsByExpId() {
    const expId = sessionStorage.getItem("expId");
    const url = `${this.apiList.experiments.getExperiments}/${expId}`;
    return this.http.getMethod(url);
  }
  getZones() {
    const url = this.apiList.admin.zones;
    return this.http.getMethod(url);
  }

  getSidebarStatesByExpId(refId?: any) {
    const expId = sessionStorage.getItem("expId");
    const url = `${this.apiList.experiments.goProcessStates}${refId ? refId : expId}/states`;
    return this.http.getMethod(url);
  }

  getProductFiltersService(expId: any, params: any) {
    const url = this.apiList.planogram.getFiltersOfPlanogram(expId);
    return this.http.postMethod(url, params);
  }

  getProductOnFiltersService(expId: any, params: any) {
    const url = this.apiList.planogram.getProductsOnFilters(expId);
    return this.http.postMethod(url, params);
  }

  getFileName(filename: any, extension: any) {
    let expDetails = JSON.parse(sessionStorage.getItem("expDetails")!)[0];
    if (expDetails?.keyAccount) {
      expDetails.keyAccount = expDetails.keyAccount.split(" ").join("_");
    }
    expDetails.expName = expDetails.expName.split(" ").join("_");
    let date = new Date().toISOString().substring(0, 10).split("-").join("");
    if (expDetails?.keyAccount) {
      expDetails.keyAccount = expDetails.keyAccount.split(" ").join("_");
      return (
        expDetails.expCountry +
        "_" +
        expDetails.keyAccount +
        "_" +
        expDetails.expName +
        "_" +
        filename +
        "_" +
        date +
        "." +
        extension
      );
    } else {
      return (
        expDetails.expCountry +
        "_" +
        expDetails.expName +
        "_" +
        filename +
        "_" +
        date +
        "." +
        extension
      );
    }
  }

  //for setting color code for different segments / styles of planogram
  setSegmentOrStyleColors(planResponse: any) {
    let segmentOrStyleColors: any = {};
    if (planResponse && planResponse.market_shares) {
      for (let basis in planResponse.market_shares) {
        for (let item in planResponse.market_shares[basis]) {
          if (planResponse.market_shares[basis][item]) {
            planResponse.market_shares[basis][item].sort((a: any, b: any) => {
              return b.market_share - a.market_share;
            });
            segmentOrStyleColors[item] = {};
            planResponse.market_shares[basis][item].forEach(
              (segment: any, index: any) => {
                if (segment.label == "PROMOTION") {
                  segmentOrStyleColors[item]["INNOVATION"] = "#FBD3ED";
                } else if (segment.label == "INNOVATION") {
                  segmentOrStyleColors[item][segment.label] = "#DCDCDC";
                } else {
                  segmentOrStyleColors[item][segment.label] =
                    SEGMENT_STYLE_COLORS_ARRAY[index];
                }
              }
            );
            //setting colors for promotion and innovation if is not present in market share
            segmentOrStyleColors[item]["PROMOTION"] = "#DCDCDC";
            segmentOrStyleColors[item]["INNOVATION"] = "#FBD3ED";
          }
        }
      }
      sessionStorage.setItem(
        "segmentOrStyleColors",
        JSON.stringify(segmentOrStyleColors)
      );
      return segmentOrStyleColors;
    }
  }
  getOverallManufacturerShareOfPlanogram(expId: any) {
    const url = this.apiList.planogram.getOverallManufacturerShare(expId);
    return this.http.getMethod(url);
  }
  getTaskIdForImport(expId: any, FileToUpload: FormData, fileType?: string) {
    const url = this.apiList.planogram.getImportTaskId(
      expId,
      fileType === "PLN" ? "v3" : "v2"
    );
    return this.http.postFileMethod(url, FileToUpload);
  }

  assortmentProcessApi(task_id: any) {
    const url = "../../assets/assortment-mock/process-response.json";
    return this.http.getMethod(url);
  }

  getExistingPlanV2(url: any, params: any) {
    return this.http.postMethod(url, params);
  }

  getAllPlansAndUploadStatusV2(url: any) {
    return this.http.getMethod(url);
  }

  getProductForAllPlans(expId: any) {
    const url = this.apiList.planogram.getAllPlansProducts(expId);
    return this.http.getMethod(url);
  }

  // postInitExperiment(exp_Id: string, state: string) {
  //   const payload = {
  //     experiment_type: state,
  //   };
  //   const url = `${this.apiList.experiments.assortment}experiments/${exp_Id}/init`;
  //   return this.http.postMethod(url, payload);
  // }

  createProcessUrl(task_id: string) {
    return `${this.apiList.experiments.goProcess}/${task_id}/status`;
  }

  // Formatting logic for Volume
  formatVolumeLabel(num: number): string {
    if (num < 0) {
      // If the number is negative, remove the sign and format the absolute value
      return "- " + this.formatVolumeLabel(-num);
    }

    if (+num >= 1000000000 && +num <= 999999999999) {
      return (+num / 1000000000).toFixed(1) + "B HL";
    }
    if (+num >= 1000000 && +num < 999999999) {
      return (+num / 1000000).toFixed(1) + "M HL";
    }
    if (+num >= 1000 && +num < 999999) {
      return (+num / 1000).toFixed(1) + "K HL";
    }
    return `${num}`;
  }

  // Formatting logic for Volume
  formatTrendVolumeLabel(num: number): string {
    if (num < 0) {
      // If the number is negative, remove the sign and format the absolute value
      return "- " + this.formatRevenueLabel(-num);
    }
    if (num >= 1000000000) {
      return `${(num / 1000000000).toFixed(1)} B`;
    }
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)} M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)} K`;
    }
    return `${num}`;
  }

  // Formatting logic for Revenue and Maco
  formatRevenueLabel(num: any): string {
    if (num < 0) {
      // If the number is negative, remove the sign and format the absolute value
      return "- " + this.formatRevenueLabel(-num);
    }
    if (num >= 1000000000) {
      return `$${(num / 1000000000).toFixed(1)} B`;
    }
    if (num >= 1000000) {
      return `$${(num / 1000000).toFixed(1)} M`;
    }
    if (num >= 1000) {
      return `$${(num / 1000).toFixed(1)} K`;
    }
    return `$${num}`;
  }

  sendStatesCall(apiFlag: boolean) {
    this.statesResetCall.next(apiFlag);
  }

  getStatesCall() {
    return this.statesResetCall.asObservable();
  }

  getStatesList(states: any) {
    this.setStatesList = states;
  }

  checkStatesFlag(routerSlug: string) {
    this.setStatesList.map((item: any) => {
      if (item.slug === routerSlug) {
        this.setStatesCall = item.status === "completed" ? true : false;
      }
    });
    return this.setStatesCall;
  }

  getStatesFlag(routerSlug: string) {
    this.setStatesList.map((item: any) => {
      if (item.slug === routerSlug) {
        this.setStatesCall = item.status === "completed" ? true : false;
      }
    });
    return this.setStatesCall;
  }

  parseFileSize(sizeInBytes: any) {
    let factor =
      sizeInBytes > this.gbFactor
        ? this.gbFactor
        : sizeInBytes > this.mbFactor
          ? this.mbFactor
          : this.kbFactor;
    let suffix =
      factor == this.gbFactor ? " GB" : factor == this.mbFactor ? " MB" : " KB";
    let size = sizeInBytes / factor;
    return String(parseFloat(size.toFixed(1))) + suffix;
  }

  setDockView(value: string = "0") {
    sessionStorage.setItem("dockView", value);
  }

  checkExperimentType() {
    let expType = sessionStorage.getItem("expType") || "";
    return this.expTypeTransform(expType) || null;
  }

  //service to export json data to excel
  exportToExcel(excelHeader: any[], excelData: any[], fileName: string): void {
    let ws: XLSX.WorkSheet;
    let merges: XLSX.Range[] = [];
    switch (fileName) {
      case "summaryTable":
        ws = XLSX.utils.aoa_to_sheet(excelData);
        merges.push(
          {
            s: { r: 0, c: 1 },
            e: { r: 0, c: 3 },
          },
          {
            s: { r: 0, c: 4 },
            e: { r: 0, c: 7 },
          }
        );
        break;

      case "portfolioHealthChart":
        ws = XLSX.utils.aoa_to_sheet(excelData);
        merges.push(
          {
            s: { r: 0, c: 0 },
            e: { r: 0, c: 2 },
          },
          {
            s: { r: 0, c: 3 },
            e: { r: 0, c: 5 },
          }
        );
        break;

      case "portfolioEfficiency":
        // Ensure there's only one header row
        ws = XLSX.utils.aoa_to_sheet([excelHeader, ...excelData]);

        // No need to merge if the header already aligns correctly
        break;

      case "prePostportfolioRecommendation":
        ws = XLSX.utils.aoa_to_sheet(excelData);
        const postRecommendationIndex = excelData.findIndex((row) =>
          row.includes("Post-Recommendation")
        );
        merges.push(
          {
            s: { r: 0, c: 0 },
            e: { r: 0, c: 5 },
          },
          {
            s: { r: postRecommendationIndex, c: 0 },
            e: { r: postRecommendationIndex, c: 5 },
          }
        );
        break;

      case "skuJourneyTable":
        // Convert the object array to a 2D array
        const formattedData = excelData.map((item) => [item.name, item.y]);

        // Check if formattedData is not empty
        if (!Array.isArray(formattedData) || formattedData.length === 0) {
          console.error("No data to export for skuJourneyTable.");
          return;
        }

        // Add header if needed (uncomment if you want headers)
        // const header = ['Name', 'Value'];
        // const dataWithHeader = [header, ...formattedData];

        ws = XLSX.utils.aoa_to_sheet(formattedData);
        break;

      // New case for tail analysis charts
      case "first-tail":
        ws = XLSX.utils.aoa_to_sheet(excelData);
        // Add any specific merge logic if needed here
        break;

      case "second-tail":
        ws = XLSX.utils.aoa_to_sheet(excelData);
        // Add any specific merge logic if needed here
        break;

      case "third-tail":
        ws = XLSX.utils.aoa_to_sheet(excelData);
        // Add any specific merge logic if needed here
        break;

      case "fourth-tail":
        ws = XLSX.utils.aoa_to_sheet(excelData);
        // Add any specific merge logic if needed here
        break;

      default:
        const tableData = this.parseTableData(excelHeader, excelData);
        ws = XLSX.utils.aoa_to_sheet([tableData.header, ...tableData.data]);
        break;
    }

    if (merges.length > 0) {
      ws["!merges"] = merges;
    }
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  parseTableData(excelHeader: any[], excelData: any[]) {
    const header = excelHeader.map((item) => [item.label]); // Build header array
    const data = excelData.map((item) =>
      excelHeader.map((entry) => item[entry.value])
    ); // Build data array
    return { header, data };
  }

  sendData(data: any) {
    this.dataSubject.next(data);
  }

  // Helper function convert the number
  nParseFloatNumber(val: any) {
    if (typeof val == "string") {
      return val != "" ? val : "-";
    } else if (this.isInt(val)) {
      return this.formatNumber(val);
    } else if (val && typeof val != "string" && !this.isInt(val)) {
      const floatVal: any = this.nParseFloat(val);
      return this.formatNumber(floatVal);
    } else {
      return val || "-";
    }
  }

  // Helper function for check the number integer
  isInt(num: any) {
    return Number(num) === num && num % 1 === 0;
  }

  // Helper function for format the number
  formatNumber(num: number) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  // Helper function for find the zone name
  getZoneName(zoneID: string, zoneList: any) {
    const item = zoneList.find(
      (item: any) => item.id === zoneID || item.name === zoneID
    );
    return item?.name;
  }

  removeQueryParamsFromUrl(url: string): string {
    return url.split("?")[0]; // Remove query params
  }
  getGlobalDashboardData(expId: any) {
    const url = `${this.apiList.experiments.phoBaseUrl}/${expId}/dashboard/result`;
    return this.http.getMethod(url);
  }

  // Helper function for adding the underscore instead of space between the text
  titleAddUnderscore(text: string = "") {
    return (
      text &&
      typeof text === "string" &&
      text.trim().split(" ").join("_").toLowerCase()
    );
  }

  // Helper function geting the Zone Level Image path for SKU
  getZoneImageSubUrl(experimentData: any) {
    const zoneName = this.titleAddUnderscore(experimentData.zone_name);
    const countryName = this.titleAddUnderscore(experimentData.country);
    const regionName = this.titleAddUnderscore(experimentData.region);
    const keyAccountName = this.titleAddUnderscore(experimentData.key_account);
    return `zones/${zoneName}/${countryName}/${regionName}/${keyAccountName}/`;
  }
}
