import { Component, Input, OnInit } from '@angular/core';
import { CatExpertRouterLink } from '../../app-routing-enum';

@Component({
  selector: 'app-kpi-cards',
  templateUrl: './kpi-cards.component.html',
  styleUrl: './kpi-cards.component.scss'
})
export class KpiCardsComponent implements OnInit{

  constructor() {}

  @Input() breadCrumbsList: any = [];
  @Input() content: any;

  expID = sessionStorage.getItem("expId");
  breadCrumbs: any = [
    {
      title: "PHO",
      slug: `${CatExpertRouterLink.Edit}`,
      expId: sessionStorage.getItem("expId"),
    },
    {
      title: "Generate and Publish",
      slug: `${CatExpertRouterLink.GeneratePublish}`,
      expId: sessionStorage.getItem("expId"),
    },
  ];

  // edaStatic: any = EdaStatic;

  ngOnInit(): void {}

}
