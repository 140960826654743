<div class="login login-container">
  <div class="bg">
    <div class="login-bg-backdrop">
      <img
        src="/assets/images/catman-logo-white-black.png"
        class="login-banner-logo"
      />
    </div>
  </div>
  <div class="login-section">
    <form>
      <div [ngClass]="this.domainType === 'pho' ? 'login-logo logo-pho' : ''">
        <img
          *ngIf="this.domainType !== 'pho'"
          src="/assets/images/login-logo.png"
          class="login-logo"
        />
      </div>
      <div>
        <label *ngIf="signUp" class="login-head">User On Boarding</label>
      </div>
      <ng-container *ngIf="!signUp">
        <div>
          <button class="btn btn-primary login-btn" (click)="login()">
            <span class="login-btn-text">Login with ABInBev</span>
            <img
              class="collection-forward"
              src="/assets/images/white-arrow.svg"
            />
          </button>
        </div>
        <div>
          <label class="form-check-label my-4"
            >Don’t have an account?
            <a href="/home#onboarding" class="login-sign-up"
              >Raise onboarding request
            </a></label
          >
        </div>
      </ng-container>
    </form>
  </div>
</div>
