<mat-drawer-container class="flow-chart-section" [hasBackdrop]="true">
  <div class="flow-chart accordian">
    <div class="accordion-item">
      <div
        class="flex justify-between p-3 {{
          hideChart ? '' : 'border-bottom'
        }} head-view accordion-header"
      >
        <div class="head-h2 my-auto">
          <p>{{ options.heading }}</p>
        </div>
        <div class="max-min flex">
          <div class="migrate-section mr-2" *ngIf="!presentMode && isOldExp">
            <app-button-primary
              buttonCaption="Migrate to New Clustering"
              buttonClassName="min-height"
              (actionButtonCall)="migrateToNewCluster()"
            ></app-button-primary>
          </div>
          <div
            class="accordion-button px-2 cursor-pointer"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#chartView"
            aria-expanded="false"
            aria-controls="chartView"
            (click)="toggleAccordian()"
            *ngIf="!presentMode"
          >
            <i class="fas {{ hideChart ? 'fa-plus' : 'fa-minus' }}"></i>
          </div>
          <div class="ml-2 text-lg my-auto">
            <i
              class="fas {{
                presentMode ? 'fa-expand' : 'fa-expand'
              }} cursor-pointer"
              (click)="togglePresentationView()"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="accordion-collapse collapse show"
        aria-labelledby="chartView"
        id="chartView"
      >
        <div
          class="accordion-body chart-view"
          [ngStyle]="{ height: presentMode ? '87vh' : '' }"
        >
          <div id="paper"></div>
          <div class="zoom-view">
            <img
              class="zoom-in"
              src="assets/images/icon-zoom-plus.svg"
              alt="plus"
              (click)="handleZoom(1)"
            />
            <div class="label mb-0">{{ zoomValue }}%</div>
            <img
              class="zoom-out"
              src="assets/images/icon-zoom-minus.svg"
              alt="minus"
              (click)="handleZoom(0)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-drawer
    #flowDrawer
    [style]="{ width: overlayFormList.width }"
    mode="over"
    position="end"
    disableClose="true"
  >
    <div class="flow-overlay">
      <div class="body-section">
        <form [formGroup]="inputForm" *ngIf="inputForm">
          <div class="sidebar-scroll">
            <div class="mt-2">
              <ng-container
                *ngFor="let input of overlayFormList?.fieldList; let i = index"
              >
                <div class="w-full max mb-3">
                  <label
                    class="w-full flex items-center justify-content-between"
                    ><span
                      class="form-label {{
                        input.type == 'radio' ? 'text-lg' : 'text-sm'
                      }}"
                      >{{ input.label }}</span
                    >
                  </label>

                  <p-dropdown
                    styleClass="form-dd"
                    panelStyleClass="form-dd-panel"
                    *ngIf="input.type == 'select'"
                    [formControlName]="input.model"
                    [options]="input?.selectOptions"
                    [placeholder]="input.placeholder"
                  ></p-dropdown>

                  <div
                    class="flex items-center justify-between primary_view mt-2"
                    *ngIf="input.clusteringGraph"
                  >
                    <span class="left-text flex"
                      >Recommended
                      <mat-spinner
                        *ngIf="
                          featureDefaults?.cluster_recommendation?.length === 0
                        "
                        class="example-margin ml-2"
                        color="primary"
                        mode="indeterminate"
                        value="70"
                        strokeWidth="1"
                        diameter="16"
                      >
                      </mat-spinner>

                      <span
                        class="pl-3"
                        *ngIf="
                          featureDefaults?.cluster_recommendation?.length > 0
                        "
                        ><ng-container
                          *ngFor="
                            let item of featureDefaults?.cluster_recommendation;
                            let i = index
                          "
                          ><span
                            class="pl-1 pr-1"
                            *ngIf="
                              i ===
                              featureDefaults?.cluster_recommendation.length - 1
                            "
                            >or</span
                          >
                          <span
                            (click)="getClusteringItem(item)"
                            class="cursor-pointer pl-1"
                            >{{ item }}</span
                          ><span *ngIf="!i">,</span>
                        </ng-container></span
                      >
                    </span>
                    <span
                      class="right-text flex items-center cursor-pointer"
                      (click)="clusteringGraphView()"
                      ><img
                        src="/assets/images/icon-line-chart.svg"
                        class="mr-1"
                      />
                      View Graph</span
                    >
                  </div>

                  <ng-select
                    class="form-multi-select"
                    *ngIf="input.type === 'ng_select'"
                    [multiple]="input?.multiSelect || false"
                    [formControlName]="input.model"
                    [appendTo]="'body'"
                    [placeholder]="input.placeholder"
                    (blur)="input?.multiSelect ? getRecommendation() : ''"
                  >
                    <ng-option
                      *ngFor="let item of input.selectOptions"
                      [value]="item?.value ? item.value : item"
                    >
                      {{ item?.label ? item.label : item }}
                    </ng-option>
                  </ng-select>

                  <div *ngIf="input.type == 'radio'">
                    <div *ngFor="let item of input.options" class="block m-3">
                      <p-radioButton
                        name="{{ input.model }}"
                        value="{{ item.value }}"
                        [formControlName]="input.model"
                        (onClick)="
                          radioItemChange(item, this.overlayFormList?.cellData)
                        "
                        inputId="{{ item.label }}"
                      >
                      </p-radioButton>
                      <label
                        for="{{ item.label }}"
                        class="mx-3 form-radio-label"
                        >{{ item.label }}</label
                      >
                    </div>
                  </div>

                  <app-form-error
                    type="2"
                    [formControlName]="input.model"
                    [errors]="input['errors']"
                  >
                  </app-form-error>
                </div>
              </ng-container>
            </div>
          </div>
        </form>

        <div *ngIf="overlayFormList.key == 'bin-chart'">
          <label class="w-full flex items-center justify-content-between"
            ><span class="head-h2 my-auto">{{ overlayFormList.label }}</span>
            <div class="drop-section flex justify-content-between">
              <ng-container *ngFor="let item of overlayFormList.dropList">
                <div class="px-3 field">
                  <label class="form-label">{{ item.label }}</label>
                  <div class="">
                    <div class="relative" *ngIf="item.type == 'dropdown'">
                      <p-dropdown
                        styleClass="form-dd"
                        [options]="item.optionList"
                        [(ngModel)]="binCount"
                      >
                      </p-dropdown>
                      <div class="input-tick" (click)="updateChart('binCount')">
                        <i class="fas fa fa-check"></i>
                      </div>
                    </div>
                    <div class="relative" *ngIf="item.type == 'input'">
                      <input
                        class="form-control"
                        [(ngModel)]="interval"
                        type="text"
                      />
                      <div class="input-tick" (click)="updateChart('interval')">
                        <i class="fas fa fa-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </label>
          <div class="mt-4">
            <p-chart
              #binChart
              type="bar"
              height="275"
              [options]="options.binChartOption"
              [data]="chartData"
            ></p-chart>
            <div class="bin-range mt-2 ms-5 me-5" *ngIf="refreshBins">
              <app-multi-slider
                [start]="binsData"
                [max]="maxData"
                [min]="minData"
                (valueChange)="updateBinsInfo($event)"
              ></app-multi-slider>
            </div>
          </div>
        </div>

        <div *ngIf="overlayFormList.key == 'add'">
          <div class="head-h2 my-auto pt-2 pb-4">
            {{ overlayFormList.label }}
          </div>
          <div class="px-4">
            <div class="flex justify-between mb-2">
              <p class="upload-label">Upload Edited Data</p>
              <span
                class="download-temp flex items-center"
                [ngClass]="
                  downloadSpinner ? 'cursor-default pointer-events-none' : ''
                "
                (click)="onFileAction($event, 'download')"
                ><mat-spinner
                  *ngIf="downloadSpinner"
                  class="example-margin mr-2"
                  color="primary"
                  mode="indeterminate"
                  value="100"
                  strokeWidth="1"
                  diameter="17"
                >
                </mat-spinner>
                <span class="dowload-temp-label">Download Template</span>
              </span>
            </div>
            <app-upload-file
              [uploadDataContent]="uploadDataContent"
              (actionBrowseButtonCall)="onFileAction($event, 'browse')"
              (actionFileDropCall)="onFileAction($event, 'drop')"
              (actionCancelCall)="onFileAction($event, 'cancel')"
            ></app-upload-file>
            <div class="flex mt-2.5">
              <app-svg-icon
                class="info-icon"
                icon="info"
                color="#000"
                padding="8px"
              >
              </app-svg-icon>
              <span class="upload-info-txt">
                Please download the editable template and make the changes and
                upload it above.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="footer-section m-1"
        *ngIf="inputForm || overlayFormList.key == 'space'"
      >
        <div class="flex justify-end gap-3">
          <app-button-secondary
            (actionButtonCall)="flowDrawer.toggle(); clearForm()"
            buttonCaption="Cancel"
            [buttonDisabled]="loadingSpinner"
          ></app-button-secondary>
          <app-button-primary
            buttonCaption="Proceed"
            [buttonDisabled]="inputForm ? checkValid() : false"
            (actionButtonCall)="submitForm()"
            [loaderEnabledFlag]="loadingSpinner"
          ></app-button-primary>
        </div>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
