<p-autoComplete
  [(ngModel)]="selectedItem"
  [suggestions]="suggestions"
  (completeMethod)="search($event)"
  styleClass="auto-comp"
  [placeholder]="placeholder"
  appendTo="body"
  dropdownIcon="pi pi-angle-down"
  [dropdown]="showDropdown"
  [completeOnFocus]="isCompleteOnFocus"
  [showEmptyMessage]="showEmptyMessage"
  [multiple]="isMultipleSelected"
  (onSelect)="onSelectItem($event)"
>
  <ng-template let-data pTemplate="empty">
    <div (click)="addNewOption($event)">
      Create department <b>{{ searchedString }}</b>
    </div>
  </ng-template>
</p-autoComplete>
