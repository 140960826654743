
<p-table
  [columns]="tableColumns"
  [value]="tableData"
  dataKey="id"
  rowExpandMode="single"
  expandableRows="true"
  styleClass="expandable-table"
  [scrollable]="true"
  [scrollHeight]="'430px'"
>
  <ng-template pTemplate="header" let-columns>
    <tr *ngIf="dataFrom === 'executive-summary'" class="h-[51px]">
      <th
        class="bg-black text-white text-center"
        [attr.colspan]="8"
      >
        AIPHO Portfolio Analysis
      </th>
      <th
        *ngIf="withFinancialImpact"
        class="bg-black text-white text-center"
        [attr.colspan]="3"
      >
       AIPHO KPIs Output
      </th>
    </tr>
    <tr class="h-[61px]">
      <th style="width: 80px"></th>
      <ng-container *ngFor="let col of columns; let i = index">
        <th
          *ngIf="col.value != 'brand' && col.value != 'obppc_category'"
          [ngClass]="dataFrom === 'executive-summary' ? 'header-' + i : ''"
          class="h-15"
        >
          <span class="text-base font-bold">{{ col.label }}</span>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
    let-expanded="expanded"
  >
    <tr
      [ngClass]="{
        'expanded-row': expanded,
        'last-row':
          rowIndex === tableData.length - 1 && dataFrom === 'executive-summary'
      }"
      class="font-xs font-semibold h-[40px]"
    >
      <td
        *ngIf="
          rowIndex !== tableData.length - 1 || dataFrom !== 'executive-summary'
        "
      >
        <p-button
          type="button"
          pRipple
          [pRowToggler]="rowData"
          [text]="true"
          [rounded]="true"
          [plain]="true"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></p-button>
      </td>
      <td
        *ngIf="
          rowIndex === tableData.length - 1 && dataFrom === 'executive-summary'
        "
      ></td>
      <ng-container *ngFor="let col of columns">
        <td
          *ngIf="col.value != 'brand' && col?.valueType != 'eda_name'"
          class="parent-row"
          [ngClass]="{ 'h-[89px]': dataFrom === 'eda' }"
        >
          <span class="text-base font-semibold"
            >{{ toFixedToZeroDigits(rowData[col.value]) | uppercase
            }}</span
          >
        </td>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
    <tr *ngFor="let brand of rowData?.brands" class="collapsible-border">
      <ng-container style="height: 200px">
        <td class="inner-collapsible-rows">
          <!-- added this button for CSS adjustment -->
          <p-button
            type="button"
            pRipple
            [pRowToggler]="rowData"
            [text]="true"
            [rounded]="true"
            [plain]="true"
            [icon]="'pi pi-chevron-down'"
            [style.display]="'none'"
          ></p-button>
        </td>
      </ng-container>
      <ng-container *ngFor="let col of columns; let i = index">
        <td
          *ngIf="col.value != 'name' && col.value != 'obppc_category'"
          class="inner-collapsible-rows"
        >
          <span class="text-base font-semibold">
            {{ toFixedToZeroDigits(brand[col.value]) | titleCase
            }}
          </span>
        </td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>
