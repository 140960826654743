import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { HttpService } from "../../../services/http.service";

@Component({
  selector: "app-autocomplete-email-input",
  templateUrl: "./autocomplete-email-input.component.html",
  styleUrl: "./autocomplete-email-input.component.scss",
})
export class AutocompleteEmailInputComponent implements OnInit, OnChanges {
  selectedItem!: any;
  @Input() ctrlName: any;
  @Input() suggestions: any[] = [];
  @Input() isMultipleSelected: boolean = false;
  @Input() isCompleteOnFocus: boolean = false;
  @Input() isDisabled: boolean = false;
  @Output() onSearch = new EventEmitter<any>();
  @Output() onEmailChange = new EventEmitter<any>();

  ngOnInit(): void {
  
    if (!this.isMultipleSelected && (this.ctrlName || this.ctrlName == '')) {
      this.selectedItem = this.ctrlName;
     }

     if(this.isMultipleSelected && this.ctrlName ){
      this.selectedItem =this.ctrlName;
     }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes?.ctrlName?.currentValue || changes?.ctrlName?.currentValue == '') && !this.isMultipleSelected) {
      this.selectedItem = changes?.ctrlName?.currentValue;
    }

    if(this.isMultipleSelected && this.ctrlName ){
      this.selectedItem =this.ctrlName;
     }
  }

  search(event: any) {
    let query = event.query;
    setTimeout(() => {
      this.onSearch.emit(query);
    }, 1500);
  }

  onSelectItem(event: any) {
    this.onEmailChange.emit(
      this.isMultipleSelected ? this.selectedItem : this.selectedItem?.email
    );
  }
}
