import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delist-button',
  templateUrl: './delist-button.component.html',
  styleUrl: './delist-button.component.scss'
})
export class DelistButtonComponent {
  @Input() isRecommended: boolean = false;
  @Input() isKeepRecommended: boolean = false;
  @Input() btnDisable: boolean = false;
  @Input() isActive: boolean = false;
  @Input() btnSize: string = '';
  @Input() hostingFrom: string = '';
  @Output() actionBtnCallback = new EventEmitter<any>();

  onBtnClick() {
    this.actionBtnCallback.emit();
  }
}
