import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button-primary",
  templateUrl: "./button-primary.component.html",
  styleUrls: ["./button-primary.component.scss"],
})
export class ButtonPrimaryComponent implements OnInit {
  @Input() buttonCaption: string = "Publish";
  @Input() buttonType: string = "submit";
  @Input() buttonClassName: string = "";
  @Input() loaderEnabledFlag: boolean = false;
  @Input() buttonSubmitEnabled: boolean = false;
  @Input() buttonDisabled: boolean = false;
  @Input() btnMinWidth: string = 'initial';
  @Output() actionButtonCall = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  clickEvent(buttonData: any) {
    this.actionButtonCall.emit(buttonData);
  }
}
