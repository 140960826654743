<mat-slider
  #slider
  tickInterval="1"
  step="1"
  min="1"
  max="100"
  showTickMarks
  discrete
  [displayWith]="formatLabel"
  (change)="onChangeSlider($event)"
>
  <input [value]="sliderValue" matSliderThumb />
</mat-slider>
