<div class="pho-sidebar-form">
  <div class="pho-sidebar-form-in">
    <form [formGroup]="inputForm">
      <div class="sidebar-scroll">
        <div class="flex items-center justify-between mb-4 xl:mb-4 2xl:mb-5">
          <div class="head-h3 flex items-center mb-0">
            <span
              *ngIf="nestingSideBarForm?.formIcon"
              class="form-icon flex items-center justify-center mr-3"
              ><img
                src="/assets/images/{{ nestingSideBarForm?.formIcon }}.svg"
                alt="icon"
            /></span>
            {{ nestingSideBarForm?.formTitle }}

            <span
              *ngIf="nestingSideBarForm?.selectedClusterName"
              [pTooltip]="nestingSideBarForm?.selectedClusterName"
              tooltipPosition="bottom"
              class="ml-2"
              ><img
                src="/assets/images/info.svg"
                alt="icon"
                class="cursor-pointer"
            /></span>
          </div>

          <span *ngIf="nestingSideBarForm?.helpIcon" (click)="actionHelpModal()"
            ><img
              src="/assets/images/{{ nestingSideBarForm?.helpIcon }}.svg"
              alt="icon"
              class="cursor-pointer"
          /></span>

          <span
            *ngIf="nestingSideBarForm?.iconEdit"
            (click)="actionEditUpload.emit()"
            ><app-edit-modify-button iconSize="17px"></app-edit-modify-button>
          </span>
        </div>

        <div class="info-text" *ngIf="nestingSideBarForm?.formInfoText">
          {{ nestingSideBarForm?.formInfoText }}
        </div>

        <div class="mt-2">
          <ng-container
            *ngFor="let input of nestingSideBarForm?.formField; let i = index"
          >
            <div
              class="w-full max {{
                nestingSideBarForm?.extraParamPosition == i ? 'mb-0' : 'mb-4'
              }}"
              *ngIf="advancedParamsShow ? true : !input?.isHide"
            >
              <label class="w-full relative pr-4"
                ><span>{{ input.label }}</span>
                <span *ngIf="input?.isRequired" class="text-red-500 pl-1">
                  *</span
                >
                <div
                  *ngIf="input?.infoIconTooltip"
                  class="flex items-center absolute top-2 right-1"
                  [pTooltip]="input?.infoIconTooltip"
                  tooltipPosition="bottom"
                  tooltipStyleClass="tooltip-big-width"
                >
                  <app-svg-icon
                    class="flex items-center ml-1"
                    icon="info"
                    color="#666"
                    padding="5px"
                  ></app-svg-icon>
                </div>
              </label>

              <div
                *ngIf="input.type == 'keep-delist-btns'"
                class="flex justify-between gap-3"
              >
                <app-keep-button
                  hostingFrom="pho-sidebar"
                  btnSize="small-btn"
                  [isRecommended]="nestingFormDefault?.recommended == 'Keep'"
                  [isDelistRecommended]="
                    nestingFormDefault?.recommended == 'Delist'
                  "
                  [isActive]="activeKeepBtn"
                  [btnDisable]="isVotingClosed"
                  (actionBtnCallback)="onKeepDelist($event, 'Keep')"
                ></app-keep-button>
                <app-delist-button
                  hostingFrom="pho-sidebar"
                  btnSize="small-btn"
                  [isRecommended]="nestingFormDefault?.recommended == 'Delist'"
                  [isKeepRecommended]="
                    nestingFormDefault?.recommended == 'Keep'
                  "
                  [isActive]="activeDelistBtn"
                  [btnDisable]="isVotingClosed"
                  (actionBtnCallback)="onKeepDelist($event, 'Delist')"
                ></app-delist-button>
              </div>
              <mat-slider
                *ngIf="input.type === 'slider'"
                tickInterval="1"
                [step]="input?.stepNumber ? input?.stepNumber : '1'"
                [min]="input?.minNumber ? input?.minNumber : '1'"
                [max]="input?.maxNumber ? input?.maxNumber : '1'"
                showTickMarks
                discrete
                [displayWith]="formatLabel"
              >
                <input [formControlName]="input.model" matSliderThumb />
              </mat-slider>

              <div
                class="input-group"
                *ngIf="input.type === 'text' || input.type === 'number'"
              >
                <input
                  class="form-control"
                  [ngClass]="{ 'border-right-none': input.inputIcon }"
                  [type]="input?.type"
                  [min]="input?.min"
                  [max]="input?.max"
                  [placeholder]="input.placeholder"
                  [formControlName]="input.model"
                />
                <div class="input-group-append" *ngIf="input?.inputIcon">
                  <span class="input-group-text">%</span>
                </div>
              </div>

              <app-autocomplete-email-input
                *ngIf="input.type == 'multi-select'"
                [suggestions]="users"
                (onSearch)="getUsersOnType($event)"
                (onEmailChange)="onOwnerSelect($event, input.model)"
                [ctrlName]="getCtrlValue(input.model)"
                [isMultipleSelected]="true"
                [isCompleteOnFocus]="false"
                [isDisabled]="isVotingClosed"
              >
              </app-autocomplete-email-input>

              <p-dropdown
                styleClass="sidebar-form-dd"
                panelStyleClass="sidebar-form-dd-panel"
                *ngIf="input.type == 'select'"
                [formControlName]="input.model"
                [options]="input?.selectOptions"
                [placeholder]="input.placeholder"
              ></p-dropdown>

              <app-date-picker
                *ngIf="input.type == 'calendar'"
                [enableTimePicker]="false"
                (onDateSelect)="onExpiryDateTimeSelect($event, input.model)"
                [ctrlName]="getCtrlValue(input.model)"
                [isDisabled]="isVotingClosed"
              ></app-date-picker>

              <ng-select
                class="sidebar-multiple-select"
                *ngIf="input.type === 'ng_select'"
                [multiple]="input?.multiSelect || false"
                [formControlName]="input.model"
                [appendTo]="'body'"
                [closeOnSelect]="false"
                (blur)="input.isChangeEvent ? onBlurEvent() : ''"
                [placeholder]="input.placeholder"
              >
                <ng-option
                  *ngFor="let item of input.selectOptions"
                  [value]="item?.value ? item.value : item"
                >
                  {{ item?.label ? item.label : item }}
                </ng-option>
              </ng-select>

              <app-form-error
                type="2"
                [formControlName]="input.model"
                [errors]="input['errors']"
              >
              </app-form-error>

              <ng-container
                *ngIf="
                  input?.clusteringGrap && nestingSideBarForm.formAccessType
                "
              >
                <div
                  class="flex items-center justify-between primary_view mt-3"
                >
                  <span class="left-text flex"
                    >Recommended
                    <mat-spinner
                      *ngIf="
                        nestingSideBarForm?.clusterRecommendation?.length === 0
                      "
                      class="example-margin ml-2"
                      color="primary"
                      mode="indeterminate"
                      value="70"
                      strokeWidth="1"
                      diameter="16"
                    >
                    </mat-spinner>

                    <span
                      class="pl-3"
                      *ngIf="
                        nestingSideBarForm?.clusterRecommendation?.length > 0
                      "
                      ><ng-container
                        *ngFor="
                          let item of nestingSideBarForm?.clusterRecommendation;
                          let i = index
                        "
                        ><span
                          class="pl-1 pr-1"
                          *ngIf="
                            i ===
                            nestingSideBarForm?.clusterRecommendation.length - 1
                          "
                          >or</span
                        >
                        <span
                          (click)="getClusteringItem(item)"
                          class="cursor-pointer pl-1"
                          >{{ item }}</span
                        ><span *ngIf="!i">,</span>
                      </ng-container></span
                    >
                  </span>
                  <span
                    class="right-text flex items-center cursor-pointer"
                    (click)="clickViewGraph()"
                    ><img
                      src="/assets/images/icon-line-chart.svg"
                      class="mr-1"
                    />
                    View Graph</span
                  >
                </div>
              </ng-container>
            </div>
            <div
              class="float-right flex mb-4 mt-1 cursor-pointer primary_view"
              *ngIf="
                nestingSideBarForm?.extraParamPosition == i &&
                nestingSideBarForm?.extraParams
              "
              (click)="toggleAdvancedParams()"
            >
              <span class="right-text"
                >Advanced Params
                <i
                  class="ml-1 fa {{
                    advancedParamsShow ? 'fa-angle-up' : 'fa-angle-down'
                  }}"
                ></i>
              </span>
            </div>
          </ng-container>

          <!-- Dynamic Nesting Form field -->
          <ng-container
            *ngFor="
              let fieldArray of nestingSideBarForm?.accordionList;
              let j = index
            "
          >
            <div class="w-full dynamic_nesting_form">
              <h4
                class="head-h4 accord-head"
                [ngClass]="activeAccord === j ? 'active' : ''"
                (click)="setActiveAccod(j)"
              >
                {{ fieldArray.headText }}
              </h4>

              <ng-container *ngIf="activeAccord === j">
                <ng-container
                  *ngFor="let input of fieldArray?.formField; let i = index"
                >
                  <div class="w-full max mb-4">
                    <label class="w-full flex"
                      ><span>{{ input.label }}</span>
                    </label>

                    <div class="relative">
                      <mat-slider
                        *ngIf="input.type === 'slider'"
                        [min]="input?.minNumber"
                        [max]="input?.maxNumber"
                        [step]="input?.stepNumber"
                        [ngClass]="input?.styleClassName"
                        showTickMarks
                        discrete
                        [displayWith]="formatLabel"
                      >
                        <input [formControlName]="input.model" matSliderThumb />
                      </mat-slider>

                      <span class="step_min">{{ input?.minNumber }}</span>
                      <span class="step_max">{{ input?.maxNumber }}</span>
                    </div>

                    <app-form-error
                      type="2"
                      [formControlName]="input.model"
                      [errors]="input['errors']"
                    >
                    </app-form-error>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <div
        class="w-full absolute bottom-16 z-40 form-footer px-3"
        *ngIf="nestingSideBarForm.formAccessType || !lockAssortment"
      >
        <div class="info-text" *ngIf="nestingSideBarForm?.formInfoTextBottom">
          {{ nestingSideBarForm?.formInfoTextBottom }}
        </div>
        <app-button-primary
          buttonClassName="w-full"
          [buttonCaption]="nestingSideBarForm?.buttonCaption"
          [buttonType]="nestingSideBarForm?.buttonType"
          [loaderEnabledFlag]="nestingSideBarForm?.isLoaderEnabled"
          [buttonDisabled]="isVotingClosed"
          (actionButtonCall)="formAction($event)"
        ></app-button-primary>
      </div>
    </form>
  </div>
</div>
