export const requestStatus = {
  REJECT_REQUEST: 'rejected',
  APPROVE_REQUEST: 'approved',
  PENDING_REQUEST: 'pending',
};

export enum requestTypes {
  migrate_zone = 'Migrate',
  onboard_user = 'Onboarding',
  revoke_zone = 'Zone Revoke',
  sync_keyaccount = 'Sync Key Account',
  add_zone = 'Add Zone',
  add_keyaccount = 'KA Access',
  revoke_keyaccount = 'KA Revoke',
}
