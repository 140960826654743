import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-planogram-footer',
  templateUrl: './planogram-footer.component.html',
  styleUrls: ['./planogram-footer.component.scss'],
})
export class PlanogramFooterComponent implements OnInit {
  @Input() leftoutProductsCount: any;
  @Input() placedProductsCount: any;
  @Input() accessType: any;
  @Input() trafficFlowAllocation: any;
  @Output() showPlacedSkuPanel = new EventEmitter<any>();
  @Output() showLeftoutSkuPanel = new EventEmitter<any>();
  @Input() totalSpaceAllocated: any;
  @Input() footerPanel: string = '';
  @Input() planoLoadingMode!: boolean;
  @Input() dockRight: number = 0;
  selectedPlanName: any;
  planosLockUnlockStatus: any = {};
  planCount: any = {};
  isLoading!: boolean;
  loaderData = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  @Input() contentToDisplayInRightSideBar: string = '';
  enablePopup: boolean = false;
  activePlanName: any;

  constructor(
    public stateService: StateService,
    public router: Router,
  ) {}

  ngOnInit(): void {}

  openLeftoutSkuTab() {
    this.showLeftoutSkuPanel.emit();
  }

  openPlacedSkuTab() {
    this.showPlacedSkuPanel.emit();
  }
}
