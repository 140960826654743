<p-dialog
  header="SKU Journey"
  [(visible)]="showSkuDetailsDialog"
  [style]="{ width: '78vw' }"
  [modal]="true"
  styleClass="voting-dialog"
  [draggable]="false"
  (onHide)="closeDialog()"
>
  <ng-template pTemplate="content">
    <div class="flex justify-between sku-name-id-levels">
      <div class="sku-name-id">
        <p class="label">
          {{ skuDetails?.sku_detail }}
          <span class="innovation-chip">{{
            skuDetails.is_innovation ? "Innovation" : "Non-Innovation"
          }}</span>
        </p>
        <p class="value">
          ID <span class="sku-id pr-1">{{ skuDetails?.sku }}</span
          >|<span class="pl-1">{{ skuDetails?.age }}</span> Months |
          <span class="pl-1">{{
            skuDetails?.brand | titleFirstLetterCaps
          }}</span>
          |<span class="pl-1">{{
            skuDetails?.ptc_segment | titleFirstLetterCaps
          }}</span>
          |<span class="pl-1">{{
            skuDetails?.pack_type | titleFirstLetterCaps
          }}</span>
        </p>
      </div>
      <div class="flex justify-between levels-block">
        <div class="mr-3 pl-2 pr-4 border-r border-gray-200">
          <p class="label">Level 1</p>
          <p class="value level1_value">
            {{ skuDetails?.level1_recommendation }}
          </p>
        </div>
        <div class="px-2">
          <p class="label">Level 2</p>
          <p class="value level2_value">
            {{ skuDetails?.level2_recommendation }}
          </p>
        </div>
        <div class="flex items-center sku-ranking rounded justify-between">
          <div class="my-auto p-1">
            <img src="/assets/images/credit-score.svg" alt="creditScore" />
          </div>
          <div class="my-auto p-2">
            <p class="label pt-1">SKU Ranking Score</p>
            <p class="value sku_rank_value">
              {{ skuDetails?.sku_ranking_score?.toFixed(0) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="overflow-y-auto overflow-container">
          <div class="w-full mt-3">
            <div class="grid grid-cols-5 sku-features-block gap-4 p-3">
              <div class="sku-features" *ngFor="let item of skuFeatures">
                <p class="label">
                  {{ item.label }}
                  <span *ngIf="item.unit">{{ item.unit }}</span>
                </p>
                <p class="value">
                  {{ item.key ? getKeyValue(item) : "" }}
                  <span *ngIf="item.showPercentage">%</span>
                  <span *ngIf="item.key == 'maco_uplift'">
                    ({{
                      nParseFloat(
                        (skuDetails?.maco_uplift / skuDetails?.total_maco) *
                          100 || 0
                      )
                    }}%)</span
                  >
                  <span *ngIf="item.key == 'volume_retained'"
                    >({{
                      nParseFloat(
                        (skuDetails?.volume_retained /
                          skuDetails?.total_volume) *
                          100 || 0
                      )
                    }}%)</span
                  >
                </p>
              </div>
            </div>
          </div>

          <div class="mt-3 flex justify-between gap-3">
            <div class="voting-card">
              <h3 class="head-h3">MACO & Country MACO Margin Trend</h3>
              <p-chart
                #macoSegmentChart
                height="300"
                type="line"
                [data]="macoLineChartData"
                [options]="macoLineChartOptions"
              ></p-chart>

              <div class="legend-item-out mt-2">
                <div
                  *ngFor="let item of legendMacoSegmentChart; let i = index"
                  class="legend-item"
                  (click)="
                    onLegendItemClick(
                      i,
                      'macoSegmentChart',
                      'legendMacoSegmentChart'
                    )
                  "
                >
                  <div
                    class="legend-color"
                    [style.background-color]="item.color"
                  ></div>
                  <div
                    class="legend-label"
                    [ngClass]="!item?.textHide ? 'overline' : 'no-underline'"
                  >
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>
            <div class="voting-card">
              <h3 class="head-h3">Revenue & Volume Trend</h3>
              <p-chart
                #revenueVolumeChart
                height="300"
                type="line"
                [data]="revenueLineChartData"
                [options]="revenueLineChartOptions"
              ></p-chart>
              <div class="legend-item-out mt-2">
                <div
                  *ngFor="let item of legendRevenueVolumeChart; let i = index"
                  class="legend-item"
                  (click)="
                    onLegendItemClick(
                      i,
                      'revenueVolumeChart',
                      'legendRevenueVolumeChart'
                    )
                  "
                >
                  <div
                    class="legend-color"
                    [style.background-color]="item.color"
                  ></div>
                  <div
                    class="legend-label"
                    [ngClass]="!item?.textHide ? 'overline' : 'no-underline'"
                  >
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-3 flex justify-between gap-3 abi-nonabi-block"
            *ngIf="withFinancialImpact"
          >
            <div class="voting-card">
              <div class="table-heading">Top 5 ABI Similar SKUs</div>
              <table>
                <thead>
                  <tr>
                    <th>SKU Name</th>
                    <th>Vol Absorbed %</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngIf="skuDetails?.similar_abi_skus?.length > 0"
                  >
                    <tr *ngFor="let sku of skuDetails?.similar_abi_skus">
                      <td class="text-left">{{ sku?.sku_name }}</td>
                      <td class="text-center">
                        {{ (nParseFloat(sku?.vol_absorbed*100)) }}%
                      </td>
                    </tr>
                  </ng-container>
                  <tr *ngIf="skuDetails?.similar_abi_skus?.length == 0">
                    <td colspan="2">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="voting-card">
              <div class="table-heading">Top 5 Non ABI Similar SKUs</div>
              <table>
                <thead>
                  <tr>
                    <th>SKU Name</th>
                    <th>Vol Absorbed %</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngIf="skuDetails?.similar_non_abi_skus?.length > 0"
                  >
                    <tr *ngFor="let sku of skuDetails?.similar_non_abi_skus">
                      <td class="text-left">{{ sku?.sku_name }}</td>
                      <td class="text-center">
                        {{ (nParseFloat(sku?.vol_absorbed * 100)) }}%
                      </td>
                    </tr>
                  </ng-container>
                  <tr *ngIf="skuDetails?.similar_non_abi_skus?.length == 0">
                    <td colspan="2">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="mt-3">
          <div class="validation-heading">
            AI PHO Validation<span class="sku-age">
              ( {{ skuDetails?.age }} Months )</span
            >
          </div>
          <div class="mt-1">
            <div
              class="flex justify-between validation-item"
              *ngFor="let validation of skuDetails?.validation[0] | keyvalue"
            >
              <div>{{ validation?.key }}</div>
              <div>
                <app-svg-icon
                  *ngIf="!skuDetails?.validation[0][getString(validation.key)]"
                  padding="8px"
                  icon="cross"
                  color="#D14447"
                >
                </app-svg-icon>
                <app-svg-icon
                  *ngIf="skuDetails?.validation[0][getString(validation.key)]"
                  padding="10px"
                  icon="tick-green"
                  color="#6EBC14"
                >
                </app-svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
