import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss'],
})
export class StatusMessageComponent implements OnInit {
  @Input() result: any;
  @Input() state: any;
  @Input() tableName: any;
  constructor() {}

  ngOnInit() {}
}
