import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StateService } from "../services/state.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  signUp: boolean = false;
  domainType: string = "";

  constructor(
    private router: Router,
    private stateService: StateService
  ) {
    this.domainType = this.stateService.checkAppConfig();
  }

  ngOnInit(): void {}

  login() {
    window.location.replace(this.stateService.apiList.user.login);
  }
  userBoarding() {
    this.signUp = !this.signUp;
  }
}
