import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-button-secondary",
  templateUrl: "./button-secondary.component.html",
  styleUrls: ["./button-secondary.component.scss"],
})
export class ButtonSecondaryComponent implements OnInit {
  @Input() buttonCaption: string = "Publish";
  @Input() buttonType: string = "submit";
  @Input() buttonClassName: string = "";
  @Input() buttonIcon: string = "";
  @Input() buttonDisabled: boolean = false;
  @Output() actionButtonCall = new EventEmitter<any>();
  @Input() btnMinWidth: string = 'initial';
  @Input() btnHeight: string = '40px';
  @Input() isBtnDisabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  clickEvent(buttonData: any) {
    this.actionButtonCall.emit(buttonData);
  }
}
