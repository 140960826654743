<div
  class="w-100 mt-3 justify-content-center align-items-center d-flex"
  *ngIf="!result?.[tableName]"
>
  <div class="text-center">
    <div class="eda-level-error-text">
      <span *ngIf="result?.status === 'pending'">
        {{ state?.process?.pending }}</span
      >
      <span *ngIf="result?.status === 'in_progress'">
        {{ state?.process?.in_progress }}</span
      >
      <span *ngIf="result?.status === 'completed'">
        {{ state?.process?.completed }}</span
      >
      <span *ngIf="result?.status === 'failed'">
        {{ state?.process?.failed }}</span
      >
      <span class="eda-level-dot-loader" *ngIf="result?.status !== 'failed'">{{
        state?.process?.dotLoader
      }}</span>
    </div>
    <div
      class="eda-level-bar-section"
      *ngIf="result?.status !== 'failed'"
      [ngClass]="result?.class"
    >
      <div
        class="eda-level-progressbar"
        [ngStyle]="{ 'width.%': result?.percent }"
      >
        {{ result?.percent > 8 ? result?.percent + "%" : "" }}
      </div>
    </div>
  </div>
</div>
