import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";

@Component({
  selector: "app-autocomplete-input",
  templateUrl: "./autocomplete-input.component.html",
  styleUrl: "./autocomplete-input.component.scss",
})
export class AutoCompleteInputComponent implements OnInit, OnChanges {
  selectedItem: any;
  suggestions: any[] = [];
  searchedString: string = '';
  @Input() options: any[] = [];
  @Input() ctrlName: any;
  @Output() onSelectAction = new EventEmitter<any>();
  @Input() showDropdown: boolean = true;
  @Input() isCompleteOnFocus: boolean = true;
  @Input() showEmptyMessage: boolean = true;
  @Input() isMultipleSelected: boolean = false;
  @Input() placeholder: string = 'Type or select';
  

  ngOnInit(): void {
    this.suggestions = this.options;
    this.selectedItem = this.ctrlName;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.selectedItem = changes?.ctrlName?.currentValue;
      this.suggestions = changes?.options?.currentValue;
    }
  }

  search(event: AutoCompleteCompleteEvent) {
    this.searchedString = event.query;
    if (event.query == "") {
      this.suggestions = this.options;
    }
    const filteredItems = this.options.filter((item) =>
      item.value?.includes(event.query)
    );
    this.suggestions = filteredItems;
    if (filteredItems?.length == 0) {
      this.onSelectAction.emit({ value: event.query });
    }
  }

  onSelectItem(event: any) {
    this.selectedItem = event?.value;
    this.onSelectAction.emit(this.selectedItem);
  }

  addNewOption(event: any) {
    event.target.parentNode.remove();
    this.selectedItem = this.searchedString;
  }
}
