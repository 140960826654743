<div class="flex justify-center footer-nav">
  <button
    class="left-btn flex items-center justify-between"
    (click)="onPrevClick()"
    [disabled]="previousButtonDisabledFlag"
  >
    <app-svg-icon
      icon="dropdown-left"
      color="#fff"
      padding="15px"
    ></app-svg-icon>
    <span>{{ prevBtnText }}</span>
  </button>
  <button
    class="right-btn flex items-center justify-between"
    [ngClass]="nextBtnExpandFlag ? 'width-auto' : ''"
    (click)="onNextClick()"
    [disabled]="nextButtonDisabledFlag"
  >
    <span>{{ nextBtnText }}</span>
    <app-svg-icon
      *ngIf="!nextBtnExpandFlag"
      icon="dropdown-right"
      color="#fff"
      padding="15px"
    ></app-svg-icon>
  </button>
</div>
