<p-dialog
  [modal]="true"
  [showHeader]="false"
  [(visible)]="visible"
  [style]="{
    width: '467px',
    border: '1px solid #E0E2EB',
    borderRadius: '8px',
    minHeight: '350px'
  }"
  [closable]="false"
  [blockScroll]="true"
  [baseZIndex]="10000"
  [resizable]="false"
  [maximizable]="true"
  [draggable]="false"
  appendTo="body"
  class="shared-experiment-dialog"
>
  <ng-template pTemplate="content">
    <div class="contanier dialog-container">
      <div
        class="row dialog-header-row"
        style="border-bottom: 1px solid #e0e2eb"
      >
        <div class="col-11 share-experiment-wrapper" style="padding-left: 0%">
          <span class="invite-text">Share experiment</span>
        </div>
        <div
          class="col-1 share-experiment-wrapper"
          style="padding-right: 0%; text-align: right; cursor: pointer"
        >
          <span (click)="closeDialog()">
            <img src="/assets/images/close-dialog.svg" />
          </span>
        </div>
      </div>
      <span class="search-name-icon" *ngIf="!myTextBox.value"
        ><i class="pi pi-search"></i
      ></span>
      <span
        class="close-name-icon"
        *ngIf="myTextBox.value"
        (click)="searchText = ''"
      >
        <img src="/assets/images/autocomplete-cross-icon.svg" />
      </span>
      <p-autoComplete
        placeholder="Type to search or add new users"
        class="search-box-input"
        [(ngModel)]="searchText"
        [showClear]="false"
        field="email"
        [suggestions]="suggestions"
        (completeMethod)="getUserSuggestions($event)"
        (keyup)="getUserDetail()"
        [showEmptyMessage]="true"
        #myTextBox="ngModel"
      >
        <ng-template let-data pTemplate="item">
          <div
            class="gap-2 email-list-item"
            [ngClass]="{
              'disabled-auto-complete-suggestion': !data.has_key_account_access
            }"
            (click)="
              data.has_key_account_access ? '' : $event.stopPropagation()
            "
          >
            <p class="suggested-name">{{ data.name }}</p>
            <p class="suggested-email">{{ data.email }}</p>
            <p *ngIf="!data.has_key_account_access" class="suggested-error">
              User lacks necessary key account access for sharing.
            </p>
          </div>
        </ng-template>
      </p-autoComplete>
      <div class="row no-gutters">
        <div class="col-12 button-col">
          <span class="allow-editing">
            <mat-checkbox [(ngModel)]="allowEditing" class="edit-checkbox">
              <span
                style="
                  font: normal normal 600 12px/17px Open Sans;
                  color: #0a0a0a;
                "
                >Allow Editing</span
              >
            </mat-checkbox>
          </span>
          <button class="share-invite-button" (click)="shareInvite()">
            <span *ngIf="shareLoader" class="loader-align">
              <mat-spinner
                class="example-margin"
                color="primary"
                mode="indeterminate"
                value="100"
                strokeWidth="2"
                diameter="20"
              >
              </mat-spinner>
            </span>
            <span *ngIf="!shareLoader">Share</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="sharedWithClicked" class="contanier dialog-container">
      <div class="row dialog-header-row" style="margin-top: 16px">
        <div class="col-12" style="padding-left: 0%">
          <span class="invite-text-shared"> Shared with </span>
        </div>
      </div>
      <div class="shared-with-wrapper">
        <div class="shared-list">
          <div
            class="row shared-users"
            *ngFor="let item of sharedUsers; let i = index"
          >
            <div class="col-12" style="display: flex">
              <span
                class="shared-user-name-circle"
                [ngStyle]="{ 'background-color': getColor(i) }"
              >
                {{
                  hasValidValue(item.first_name)
                    ? item.first_name[0] + item.last_name[0]
                    : (item.email[0] | uppercase)
                }}
              </span>
              <span class="shared-user-name"
                >{{
                  hasValidValue(item.first_name)
                    ? item.first_name + " " + item.last_name
                    : item.email
                }}
              </span>
              <div class="remove-div" style="padding-right: 32px">
                <span
                  class="loader-align"
                  *ngIf="
                    removeShareLoader && item.id === sharedUserIDTobeRemoved
                  "
                >
                  <mat-spinner
                    class="example-margin"
                    color="primary"
                    mode="indeterminate"
                    value="100"
                    strokeWidth="3"
                    diameter="20"
                  >
                  </mat-spinner>
                </span>
              </div>
              <span
              style="
                  font: normal normal 600 10px/14px Open Sans !important;
                  color: #325a6d !important;
                  padding-top: 5px;
                  padding-right: 2px;
                "
                *ngIf="item.id !== sharedUserIDTobeRemoved"
                >Can
              </span>
              <p-dropdown
                [options]="optionsList"
                optionLabel="name"
                optionValue="value"
                class="edit-user-dropdown"
                (onChange)="sharedUserActions(item.id, $event, item)"
                [(ngModel)]="item.permission"
                appendTo="body"
                optionDisabled="inactive"
                panelStyleClass="edit-user-dropdown-panel"
                *ngIf="item.id !== sharedUserIDTobeRemoved"
              >
                <ng-template let-option pTemplate="item">
                  <span
                    [ngClass]="{
                      checkmark: item.permission == option.value,
                      noCheckmark: item.permission != option.value
                    }"
                  >
                  </span>
                  <span
                    style="
                      padding-left: 4px;
                      color: #325a6d;
                      font: normal normal 600 10px/14px Open Sans;
                    "
                    >{{ option.name }}</span
                  >
                </ng-template>
              </p-dropdown>
            </div>
            <span class="shared-user-email">{{ item.email }}</span>
          </div>
        </div>
        <div class="mt-4" *ngIf="sharedUsers.length === 0">
          <span class="validation-msg flex justify-center items-center font-semibold">No shared user found</span>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
