import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingComponent } from "./landing/landing.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./services/auth.service";
import { UnauthorizedPageComponent } from "./unauthorized/unauthorized.component";

const routes: Routes = [
  { path: "home", component: LandingComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "login", canActivate: [AuthGuard], component: LoginComponent },
  {
    path: "unauthorized/:unauthorizedType",
    component: UnauthorizedPageComponent,
  },
  {
    path: "studio",
    loadChildren: () =>
      import("./main-container/main-container.module").then(
        (m) => m.MainContainerModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
