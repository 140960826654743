<button
  [disabled]="showLoader"
  class="download-results-btn flex justify-between"
  pTooltip="Download"
  tooltipPosition="top"
  [ngClass]="btnClassName"
  (click)="btnAction($event); triggerMenu && menu.toggle($event)"
>
  <app-svg-icon
    *ngIf="!showLoader"
    icon="download-results"
    color="#000"
    [padding]="padding"
  >
  </app-svg-icon>
  <mat-spinner *ngIf="showLoader" diameter="20"></mat-spinner>
</button>
<p-menu
  styleClass="w-36 text-sm"
  [model]="menuItems"
  [appendTo]="'body'"
  #menu
  [popup]="true"
/>
