import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import noUiSlider from "nouislider";

@Component({
  selector: "app-multi-slider",
  templateUrl: "./multi-slider.component.html",
  styleUrl: "./multi-slider.component.scss",
})
export class MultiSliderComponent {
  @ViewChild("slider", { static: false })
  slider!: ElementRef;

  @Input() start: any = [];
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Output() valueChange = new EventEmitter<number[]>();

  ngAfterViewInit() {
    if (this.slider) {
      noUiSlider.create(this.slider.nativeElement, {
        start: this.start,
        step: 10,
        behaviour: "drag-smooth-steps-tap",
        connect: true,
        tooltips: true,
        range: {
          min: this.min,
          max: this.max,
        },
      });
      
      // Listen to slider update events
      this.slider.nativeElement.noUiSlider.on(
        "update",
        (values: string[], handle: number, unencoded: number[]) => {
          this.valueChange.emit(unencoded.map((v) => parseFloat(v.toString())))
        }
      );
    }
  }

  ngOnChanges(changes: any) {
    if (this.slider && this.slider.nativeElement.noUiSlider && changes) {
      this.slider.nativeElement.noUiSlider.updateOptions({
        range: {
          min: this.min,
          max: this.max,
        },
      });

      if (changes.start) {
        this.slider.nativeElement.noUiSlider.set(this.start);
      }
    }
  }
}
