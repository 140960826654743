import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpService } from '../../services/http.service';
import { StateService } from '../../services/state.service';
import { AdminModalComponent } from '../../shared/admin-modal/admin-modal.component';
import { requestTypes } from '../admin.constants';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-admin-table',
  templateUrl: './admin-table.component.html',
  styleUrls: ['./admin-table.component.scss'],
})
export class AdminTableComponent implements OnInit, OnChanges {
  @Input() zoneDict?: any = {};
  @Input() userDict?: any = {};
  @Input() tableColumns: any[] = [];
  @Input() tableData: any[] = [];
  @Input() tableActions: any[] = [];
  @Input() dataFrom!: string;
  @Input() skeletonLoader?: boolean = false;
  @Output() emitTableAction = new EventEmitter<any>();
  @Output() emitTableSelection = new EventEmitter<any>();

  selectedItems: any[] = [];
  skeletonData = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  pagination = {
    limit: 5,
    options: [5, 10, 20, 30],
  };
  requestTypes: any = requestTypes;
  paginationFirst!: number;
  toggleStatus: boolean = false;
  popupEnabled :boolean =false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private stateService: StateService,
    private httpService: HttpService,
    private msgService: MessageService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.tableData) {
      this.paginationFirst = 0;
    }
  }

  onHeaderCheckboxToggle(evt: any) {
    this.emitTableSelection.emit(this.selectedItems);
  }

  checkRow(evt: any) {
    this.emitTableSelection.emit(this.selectedItems);
  }

  onTableAction(data: any, action: any) {
    this.emitTableAction.emit({ ...data, ...action });
  }

  viewKeyAccounts(data: any, action: any) {
    this.emitTableAction.emit({ ...data, action: action });
  }

  openExperimentDetails(rowData: any) {
    this.dialogRef = this.dialogService.open(AdminModalComponent, {
      header: rowData.name,
      width: '20%',
      styleClass: 'user-management-dialog experiment-details-dialog',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      data: {
        type: 'exp_details',
        experiment: rowData,
      },
    });

    this.dialogRef.onClose.subscribe((res: any) => {
      if (res) {
      }
    });
  }

  toggleUserStatus(event: any, data: any) {
    const status = event.checked;
    const index = this.tableData.findIndex((row) => row.id === data.id);
    this.tableData[index].is_active = status;

    const url = `${this.stateService.apiList.admin.getStatus(data.id)}`;
    const payload = {
      is_active: status,
    };

    this.httpService.patchMethod(url, payload).subscribe({
      next: (res:any) => {
        if (res) {
          this.showAlertMessage(
            'success',
            'User Status',
            'Status updated successfully',
          );
        }
      },
      error: (error:any) => {
        this.showAlertMessage('warn', 'User status', error?.error?.message);
      },
    });
  }

  showAlertMessage(type: string, heading: string, message: string) {
    this.msgService.add({
      severity: type,
      summary: heading,
      detail: message,
    });
  }

  openDownloadPopup(){
    this.popupEnabled = !this.popupEnabled;
  }
}
