import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-select-dropdown",
  templateUrl: "./select-dropdown.component.html",
  styleUrls: ["./select-dropdown.component.scss"],
})
export class SelectDropdownComponent implements OnInit {
  @Input() dropdownOptions: any[] = [];
  @Input() additionalStyleClass?: string = "";
  @Input() selectedValue?: string = "";
  @Input() parentComponent?: string = "";
  @Input() manualSelect: boolean = false;
  @Input() showClearFlag: boolean = false;
  @Input() isReadonlyMode: boolean = false;
  @Output() actionBtnCallback = new EventEmitter<any>();
  @Input() isDisabled: boolean = false;

  selectedOption: string = "";
  styleClass: string = "";

  constructor() {}

  ngOnInit(): void {
    if (this.parentComponent == "table_filters") {
      const index = this.dropdownOptions.findIndex(
        (each) => each.label == "Select"
      );
      if (index == -1) {
        this.dropdownOptions.unshift({
          label: "Select",
          value: "",
          isDisabled: true,
        });
      }
      this.selectedOption =
        this.selectedValue || this.dropdownOptions[0]?.value;
    }
    this.styleClass = this.additionalStyleClass || "select-dropdown";
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      let options = changes?.dropdownOptions?.currentValue;
      this.selectedOption = !this.manualSelect
        ? this.selectedValue
          ? this.selectedValue
          : options[0]?.value
        : "";
    }
  }

  onDopdownChange() {
    this.actionBtnCallback.emit(this.selectedOption);
  }
}
