import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "commaSeparate",
})
export class CommaSeparatePipe implements PipeTransform {
  constructor() {}

  transform(value: number | string, decimalPlaces: number = 0): string {
    if (value == null || value === "") {
      return "-";
    }

    const num: number = +value;

    if (isNaN(num)) {
      return String(value);
    }

    const roundedNum: number = +num.toFixed(decimalPlaces);
    const formattedNum: string = roundedNum.toLocaleString();

    return formattedNum;
  }
}
