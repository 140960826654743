import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function clone(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function getValueOrUnknown(value: any) {
  if (value === null || value === "") {
    return "Unkown";
  }

  return value;
}

export function getOS() {
  const userAgent = navigator.userAgent;
  if (/windows/i.test(userAgent)) {
    return "Windows";
  }

  if (/mac/i.test(userAgent)) {
    return "MacOS";
  }

  return "Unknown";
}

export function isMacOS() {
  return getOS() === "MacOS";
}

export function isCtrlOrCmdPressed(event: KeyboardEvent) {
  return isMacOS() ? event.metaKey : event.ctrlKey;
}

export function deleteEmptyArrayInObject(data: { [key: string]: [] }) {
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key]) && data[key].length === 0) {
      delete data[key];
    }
  });

  return data;
}

export function handleDecimal(value: any, decimalNumber: number = 0) {
  if (value === null || value === "" || value === undefined) {
    return "-";
  }
  return decimalNumber
    ? removeTrailingZeros(value.toFixed(decimalNumber))
    : value;
}

export function groupBy(list: any[], property: string) {
  console.log("called", list);
  return list?.reduce((groups, item) => {
    const val = item[property];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
}

export function sortObject(obj: any) {
  // Get an array of the keys:
  let keys = Object.keys(obj);

  // Then sort by using the keys to lookup the values in the original object:
  keys.sort((a, b) => obj[b] - obj[a]);

  return keys;
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getRandomColor() {
  return "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
}

export function validateEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

// to get time format in HHMMA 0506PM
export function getTimeFormat(time: any) {
  const am_pm = time.split(" ")[1];
  let hours = time.split(" ")[0]?.split(":")[0];
  const mins = time.split(" ")[0]?.split(":")[1];
  hours = hours?.length > 1 ? hours : "0" + hours;
  return hours + mins + am_pm;
}

// Custom validator to enforce a pattern using regular expressions
export function patternValidator(pattern: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = pattern.test(control.value);
    return valid ? null : { pattern: { value: control.value } };
  };
}

export function isStateCompleted(stateName: string) {
  const allStates = JSON.parse(localStorage.getItem("sideBarState")!);
  let value = false;
  allStates.forEach((each: any) => {
    if (each.title == stateName) {
      value = each.status == "COMPLETED" ? true : false;
    }
  });
  return value;
}

export function formatDateToYYYYMMDD(date: Date): string {
  const year = date?.getFullYear();
  const month = (date?.getMonth() + 1).toString().padStart(2, "0");
  const day = date?.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}`;
}

export function fixedFloat(value: number) {
  return parseFloat(value.toFixed(2));
}

export function removeTrailingZeros(number: any) {
  let num = Number(number);
  return num % 1 === 0 ? num : parseFloat(num.toString());
}

// To handle empty content in the form fields
export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || "").trim().length === 0;
    return isWhitespace ? { required: true } : null;
  };
}
