import {
  Component,
  OnInit,
  Input,
  ElementRef,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
})
export class MessagePopupComponent implements OnInit {
  isReadMore = true;
  copyText: any;
  @Input() data: any;
  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    if (this.data.timer) {
      setTimeout(() => {
        this.data.callback();
      }, this.data.timer);
    }
  }
  showText() {
    this.isReadMore = !this.isReadMore;
  }

  copyClip(message: any) {
    const copyText = message;
    navigator.clipboard.writeText(copyText);
  }
  // @HostListener('document:click', ['$event.target'])
  // public onClick(targetElement: any) {
  //   const clickedInside =
  //     this._elementRef.nativeElement.contains(targetElement);
  //   if (!clickedInside && this.data['type'] == 'message') {
  //     this.data.callback();
  //   }
  // }
}
