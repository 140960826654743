import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessageService } from "primeng/api";
import { debounceTime } from "rxjs/operators";
//import { Version } from '../../main-container/planogram/plano-final.types';
import { HttpService } from "../../services/http.service";
import { StateService } from "../../services/state.service";

@Component({
  selector: "app-version-confirm",
  templateUrl: "./version-confirm.component.html",
  styleUrls: ["./version-confirm.component.scss"],
})
export class VersionConfirmComponent implements OnInit {
  @Input() experimentId: any = "";
  @Input() planId: any = "";
  @Input() currentVersion!: string;
  @Output() closeVersionPreview: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private stateService: StateService,
    private httpService: HttpService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  // Method to handle error
  handleError(error: any) {
    if (error.status === 400) {
      this.messageService.add({
        key: "br",
        severity: "warn",
        summary: "Warn Message",
        detail: error.error.message,
      });
    } else if (error.status !== 401) {
      this.messageService.clear();
      this.messageService.add({
        key: "br",
        severity: "warn",
        summary: error.error.message,
        detail: error.error.traceback,
      });
    }
  }

  // Method to set the current preview version as latest version
  // in planogram
  setCurrentVersion(): void {
    let refId = sessionStorage.getItem("refId") || "";
    const url = `${this.stateService.apiList.planogram.setVersionAsCurrent(
      refId ? refId : this.experimentId,
      this.planId,
      this.currentVersion
    )}`;
    this.stateService.addCalledApi(url);
    this.httpService
      .postMethod(url)
      .pipe(debounceTime(500))
      .subscribe(
        (response) => {
          this.stateService.removeCalledApi(url);
          this.exitVersionPreview();
        },
        (error) => {
          this.stateService.removeCalledApi(url);
          this.handleError(error);
        }
      );
  }

  // Method to exit the version preview
  exitVersionPreview(): void {
    // Emit event to host component to close the version preview
    this.closeVersionPreview.emit();
  }
}
