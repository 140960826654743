import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button-toggle",
  templateUrl: "./button-toggle.component.html",
  styleUrls: ["./button-toggle.component.scss"],
})
export class ButtonToggleComponent implements OnInit {
  @Input() icons: string = "";
  @Input() text: string = "";
  @Input() btnClass: string = "";
  @Input() tooltipText: string = "";
  @Input() toggleOnOff: boolean = false;

  @Output() actionButtonCall = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  clickEvent() {
    this.actionButtonCall.emit();
  }
}
