import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-table-input',
  templateUrl: './table-input.component.html',
  styleUrls: ['./table-input.component.scss']
})
export class TableInputComponent implements OnInit {
  @Input() bindedValue: string = '';
  @Input() isInputDisabled: boolean = false;
  @Output() actionBtnCallback = new EventEmitter<any>();
  
  selectedValue: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      let value = changes?.bindedValue?.currentValue;
      this.selectedValue = value || '';
    }
  }

  onBlur() {
    this.actionBtnCallback.emit(this.selectedValue);
  }

}
