<section class="page-not-found">
  <div class="page-not-found-in">
    <div class="page-not-found-icon mb-1">
      <img src="/assets/images/icon-not-found.gif" alt="No Data to show" />
    </div>
    <h3 class="page-not-found-head-text">
      {{ pageNotFoundHeadText }}
    </h3>
    <p class="page-not-found-text">
      {{ pageNotFoundText }}
    </p>
  </div>
</section>
