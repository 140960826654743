<div class="collection">
  <main>
    <header>
      <div class="collection-header">
        <div class="collection-back">
          <img
            class="collection-head-image"
            *ngIf="sideModal.isBack"
            src="/assets/images/arrow.svg"
          />
          <h4 class="collection-head-text">{{ title }}</h4>
        </div>
      </div>
    </header>

    <body>
      <div class="collection-content">
        <div
          *ngFor="let content of staticContent; let i = index"
          style="clear: both"
        >
          <div class="collection-objective" id="stepbystep{{ i }}">
            <h5>{{ content.title }}</h5>
            <p [innerHTML]="content.desc"></p>
            <div class="collection-step">
              <div *ngFor="let stepcontent of content.steps; let j = index">
                <div class="collection-step-by-step">
                  <span class="col-step">{{ stepcontent.step }}</span
                  >&nbsp;&nbsp;<span class="col-num">{{
                    stepcontent.stepnum
                  }}</span
                  >&nbsp;&nbsp;
                  <span
                    class="step-title"
                    [innerHTML]="stepcontent.stepContent"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </main>
</div>
