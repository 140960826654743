<div class="pt-3 px-3">
  <span class="material material-icons report_icon">report_problem</span>
  <h3>{{ data.summary }}</h3>
  <ng-container *ngIf="data.detail">
    <p
      class="message"
      [ngClass]="isReadMore ? 'limitTextHeight' : 'limitmaxTextheight'"
    >
      <span class="material-icons copy_all" (click)="copyClip(data.detail)"
        >copy_all</span
      >
      {{ data.detail }}
    </p>
  </ng-container>
  <button
    type="button"
    class="read-more"
    (click)="showText()"
    *ngIf="data.detail"
  >
    <span class="material material-icons" style="vertical-align: middle">{{
      isReadMore ? "keyboard_arrow_down" : "keyboard_arrow_up"
    }}</span
    >{{ isReadMore ? "Read More" : "Read Less" }}
  </button>
</div>
