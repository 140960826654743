import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  @Input() pageNotFoundHeadText: string = "No Data to show";
  @Input() pageNotFoundText: string = "";

  constructor() {}

  ngOnInit(): void {}
}
