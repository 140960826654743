import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MenuItem } from "primeng/api";

@Component({
  selector: "app-download-btn",
  templateUrl: "./download-btn.component.html",
  styleUrls: ["./download-btn.component.scss"],
})
export class DownloadBtnComponent implements OnInit {
  @Output() actionBtnCallback = new EventEmitter<any>();
  @Output() downloadExcel = new EventEmitter<any>();
  @Output() downloadChart = new EventEmitter<any>();
  @Input() showLoader?: boolean = false;
  @Input() padding: string = "10px";
  @Input() btnClassName: string = "";
  @Input() menuItems: MenuItem[] = [];
  @Input() triggerMenu?: boolean = false;
  constructor() {
    this.menuItems = [
      {
        label: "Image",
        command: (event) => {
          this.triggerChartDownload(event);
        },
      },
      {
        label: "Excel",
        command: (event) => {
          this.triggerExcelDownload(event);
        },
      },
    ];
  }

  ngOnInit(): void {}

  btnAction(event: any) {
    this.actionBtnCallback.emit(event);
  }

  triggerExcelDownload(event: any) {
    this.downloadExcel.emit(event);
  }
  triggerChartDownload(event: any) {
    this.downloadChart.emit(event);
  }
}
