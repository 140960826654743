<div class="dateRangePicker-wrapper" *ngIf="parentFormGroup">
  <div #content>
    <ng-content></ng-content>
  </div>
  <div class="form-group" [formGroup]="parentFormGroup">
    <ng-container *ngIf="content.childNodes.length === 0">
      <label [attr.for]="instanceId">{{ options.labelText }}</label>
      <input
        [attr.id]="instanceId"
        class="form-control"
        type="text"
        [formControlName]="controlName"
      />
    </ng-container>

    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      [ngClass]="{ 'modal-display': displayStyle }"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div
              [ngClass]="{
                dateRangePickerModal: true,
                'dateRangePicker-single': options.singleCalendar,
                'is-chrome': checkChrome(),
                mobile: isMobile
              }"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col">
                    <!-- Manual Inputs -->
                    <manual-inputs
                      [options]="options"
                      [fromDate]="fromDate"
                      [toDate]="toDate"
                      [isMobile]="isMobile"
                      (dateFromInput)="setDateFromInput($event)"
                    ></manual-inputs>

                    <!-- Calendars -->
                    <div class="row row-calendars">
                      <div
                        [ngClass]="{
                          'col-12': true,
                          'col-sm-6': !isMobile && !options.singleCalendar
                        }"
                      >
                        <calendar
                          [ngClass]="{
                            calendar: true,
                            'calendar-single': options.singleCalendar
                          }"
                          [isLeft]="true"
                          [month]="fromMonth"
                          [year]="fromYear"
                          (monthChanged)="monthChanged($event)"
                          (yearChanged)="yearChanged($event)"
                          (dateChanged)="dateChanged($event)"
                          [format]="options.format"
                          [selectedFromDate]="fromDate"
                          [selectedToDate]="toDate"
                          [minDate]="options.minDate"
                          [maxDate]="options.maxDate"
                          [singleCalendar]="options.singleCalendar"
                          [icons]="options.icons"
                        ></calendar>
                      </div>

                      <div
                        [ngClass]="{
                          'col-12': true,
                          'col-sm-6': !isMobile,
                          col__mobile: isMobile
                        }"
                        *ngIf="!options.singleCalendar"
                      >
                        <calendar
                          class="calendar"
                          [month]="toMonth"
                          [year]="toYear"
                          [format]="options.format"
                          (dateChanged)="dateChanged($event)"
                          (monthChanged)="monthChanged($event)"
                          (yearChanged)="yearChanged($event)"
                          [selectedFromDate]="fromDate"
                          [selectedToDate]="toDate"
                          [minDate]="options.minDate"
                          [maxDate]="options.maxDate"
                          [icons]="options.icons"
                        ></calendar>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Pre-Defined Date Ranges -->
                <predefined-date-ranges
                  [options]="options"
                  [range]="range"
                  [enableApplyButton]="enableApplyButton"
                  (closeEvent)="close($event)"
                  (resetEvent)="reset($event)"
                  (applyEvent)="apply($event)"
                  (predefinedRange)="applyPredefinedRange($event)"
                ></predefined-date-ranges>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm"
              (click)="close($event)"
              *ngIf="options.showCloseButton"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm ml-3"
              (click)="reset($event)"
              *ngIf="range && options.showResetButton"
            >
              Reset
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm ml-3"
              [disabled]="!enableApplyButton"
              (click)="apply($event)"
              *ngIf="!options.autoApply"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Desktop Browsers -->
    <div
      [ngClass]="{
        dateRangePicker: true,
        invisible: !showCalendars,
        'dateRangePicker-single': options.singleCalendar,
        'is-chrome': checkChrome(),
        'open-left': options.position === 'left',
        'open-right': options.position === 'right',
        mobile: isMobile
      }"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <!-- Manual Inputs -->
            <manual-inputs
              [options]="options"
              [fromDate]="fromDate"
              [toDate]="toDate"
              [isMobile]="isMobile"
              (dateFromInput)="setDateFromInput($event)"
            ></manual-inputs>

            <!-- Calendars -->
            <div class="row row-calendars">
              <div
                [ngClass]="{
                  'col-12': true,
                  'col-sm-6': !isMobile && !options.singleCalendar
                }"
              >
                <calendar
                  [ngClass]="{
                    calendar: true,
                    'calendar-single': options.singleCalendar
                  }"
                  [isLeft]="true"
                  [month]="fromMonth"
                  [year]="fromYear"
                  (monthChanged)="monthChanged($event)"
                  (yearChanged)="yearChanged($event)"
                  (dateChanged)="dateChanged($event)"
                  [format]="options.format"
                  [selectedFromDate]="fromDate"
                  [selectedToDate]="toDate"
                  [minDate]="options.minDate"
                  [maxDate]="options.maxDate"
                  [singleCalendar]="options.singleCalendar"
                  [icons]="options.icons"
                ></calendar>
              </div>

              <div
                [ngClass]="{
                  'col-12': true,
                  'col-sm-6': !isMobile,
                  col__mobile: isMobile
                }"
                *ngIf="!options.singleCalendar"
              >
                <calendar
                  class="calendar"
                  [month]="toMonth"
                  [year]="toYear"
                  [format]="options.format"
                  (dateChanged)="dateChanged($event)"
                  (monthChanged)="monthChanged($event)"
                  (yearChanged)="yearChanged($event)"
                  [selectedFromDate]="fromDate"
                  [selectedToDate]="toDate"
                  [minDate]="options.minDate"
                  [maxDate]="options.maxDate"
                  [icons]="options.icons"
                ></calendar>
              </div>
            </div>
          </div>
        </div>

        <!-- Pre-Defined Date Ranges -->
        <p class="calendar-legend">{{ dateLimitLegend }}</p>
        <predefined-date-ranges
          [options]="options"
          [range]="range"
          [enableApplyButton]="enableApplyButton"
          (closeEvent)="close($event)"
          (resetEvent)="reset($event)"
          (applyEvent)="apply($event)"
        ></predefined-date-ranges>
      </div>
    </div>
  </div>
</div>
