import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
})
export class FilterPipe implements PipeTransform {
  transform(arrayToFilter: Array<any>, key: string, value: string) {
    if (key && value) {
      let filter: any = {};
      key.split(',').forEach((k) => {
        filter[k] = value.toLowerCase();
      });
      return arrayToFilter.filter((obj) => {
        return Object.keys(filter).find((k) =>
          obj[k].toLowerCase().includes(filter[k]),
        );
      });
    }
    return arrayToFilter;
  }
}
