<div class="content">
  <title>Site Maintenance</title>
  <article>
    <h1>:) Oops!</h1>

    <ng-container [ngSwitch]="unauthorizedType">
      <div *ngSwitchCase="'1'">
        <div class="mt-4">
          Look&rsquo;s like you do not have access to the tool.
          <a href="home#contact"> Click here</a> to get access.
        </div>
      </div>
      <div *ngSwitchCase="'2'">
        <p class="mt-4">
          Look&rsquo;s like your access request has not been approved yet.
        </p>
        <p class="mt-4">
          Kindly, try again later... or email us at
          <a target="_blank" href="mailto:{{ supportTeamMail }}">{{
            supportTeamMail
          }}</a>
          and we will help you shortly!
        </p>
      </div>

      <div *ngSwitchCase="'3'">
        <p class="mt-4">Your access request has been rejected.</p>
        <p class="mt-4">
          For further assistance email us at
          <a target="_blank" href="mailto:{{ supportTeamMail }}">{{
            supportTeamMail
          }}</a>
          and we will help you shortly!
        </p>
      </div>

      <div *ngSwitchCase="'4'">
        <p class="mt-4">Your access has been revoked.</p>
        <p class="mt-4">
          For further assistance email us at
          <a target="_blank" href="mailto:{{ supportTeamMail }}">{{
            supportTeamMail
          }}</a>
          and we will help you shortly!
        </p>
      </div>
    </ng-container>
  </article>
</div>
