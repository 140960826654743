import { IDateRangePickerOptions } from '../interfaces';

import { defaultDateFormat } from '../constants/default-formats';
import moment from 'moment';

export const defaultDateRangePickerOptions: any = {
  autoApply: true,
  clickOutsideAllowed: true,
  disabled: false,
  disableInputDisplay: false,
  icons: 'default',
  format: defaultDateFormat,
  labelText: 'Date Range',
  maxDate: moment(),
  minDate: moment(),
  position: 'left',
  preDefinedRanges: [],
  showResetButton: true,
  showCloseButton: false,
  singleCalendar: false,
};
