import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnInit {
  @Input() uploadDataContent: any = [];
  @Input() styleClassName: string = "";
  @Input() isMultiple: boolean = false;
  @Output() actionBrowseButtonCall = new EventEmitter<any>();
  @Output() actionFileDropCall = new EventEmitter<any>();
  @Output() actionCancelCall = new EventEmitter<any>();
  @Input() processingValidation:any;

  saleFileName: string = "";
  saleFileSize: string = "";

  constructor() {}

  ngOnInit(): void {
  }

  actionFileBrowse(file: any) {
    this.actionBrowseButtonCall.emit(file);
  }

  onFileDragOver(event: any) {
    event.preventDefault();
  }

  // Function for Drag and Drop call
  onFileDrop(event: any, callFlag: string = "") {
    event.preventDefault();
    this.actionFileDropCall.emit({ file: event, type: callFlag });
  }

  actionFileClear(type: string) {
    this.actionCancelCall.emit(type);
  }
}
