<p-autoComplete
  [(ngModel)]="selectedItem"
  [suggestions]="suggestions"
  [multiple]="isMultipleSelected"
  [completeOnFocus]="isCompleteOnFocus"
  [disabled]="isDisabled"
  (completeMethod)="search($event)"
  (onSelect)="onSelectItem($event)"
  field="email"
  appendTo="body"
  panelStyleClass="user-email-acomplete-panel"
  styleClass="user-email-acomplete"
  placeholder="Type & select email"
>
</p-autoComplete>
