<div class="btn-block">
  <span *ngIf="isRecommended" class="recomm-label">Recommended</span>
  <p
    *ngIf="isDelistRecommended"
    class="h-5"
  ></p>
  <button
    [disabled]="btnDisable"
    class="btn flex justify-center items-center gap-3 {{ btnSize }}"
    (click)="onBtnClick()"
    [ngClass]="{ 'active-btn': isActive }"
  >
    <app-svg-icon
      class="green-tick"
      [ngClass]="{ 'white-cross': isActive }"
      color="#6EBC14"
      icon="green-tick"
      padding="6px"
    ></app-svg-icon>
    <span class="keep-label font-bold">Keep</span>
  </button>
</div>
