import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-multi-select",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.scss"],
})
export class MultiSelectComponent implements OnInit {
  @Input() dropdownOptions: any[] = [];
  @Input() selectedOptions: any[] = [];
  selectedOption: any[] = [];
  @Output() emitAction = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onSelectItem(event: any) {
    this.emitAction.emit(event.value);
  }
}
