import { Pipe, PipeTransform } from "@angular/core";
import { StateService } from "../services/state.service";

@Pipe({
  name: "titleCase",
})
export class TitleCasePipe implements PipeTransform {
  constructor(private stateService: StateService) {}

  transform(text: any) {
    if (text?.toLowerCase() === "ptc_segment") {
      return "PTC Segment";
    }
    if (text?.toLowerCase() === "abi_vs_others") {
      return "ABI vs Others";
    }
    if (text?.toLowerCase() === "sku_detail") {
      return "SKU Detail";
    }
    if (text?.toLowerCase() === "sku") {
      return "SKU";
    }
    if (text?.toLowerCase() === "simulate_mix") {
      return "Simulation";
    }
    if (text?.toLowerCase() === "revenue") {
      return "Revenue (LCU)";
    }
    if (text?.toLowerCase() === "volume") {
      return "Volume (Ltr)";
    }
    if (text?.toLowerCase() === "low_abs_vol") {
      return "Low Volume";
    }
    if (text?.toLowerCase() === "recent_data") {
      return "Recent/Innovation";
    }
    if (text?.toLowerCase() === "pack_type") {
      return "Pack type";
    }
    if (text?.toLowerCase() === "manufacturer") {
      return "Manufacturer";
    }
    if (text?.toLowerCase() === "style") {
      return "Style";
    }
    if (text?.toLowerCase() === "segment") {
      return "Segment";
    }
    if (text) {
      return this.stateService.titleCase(text);
    }
    return text;
  }
}
