<button
  class="btn flex items-center justify-center"
  [ngClass]="buttonClassName"
  [disabled]="buttonDisabled ? buttonDisabled : loaderEnabledFlag"
  [type]="buttonType"
  (click)="clickEvent(buttonType)"
  [style.min-width.px]="btnMinWidth"
>
  <mat-spinner
    *ngIf="loaderEnabledFlag"
    class="example-margin mr-2"
    color="primary"
    mode="indeterminate"
    value="100"
    strokeWidth="2"
    diameter="20"
  >
  </mat-spinner>
  {{ buttonCaption }}
</button>
