<div class="container soft-warning-container row">
  <div class="col-1" (click)="closeToast()">
    <img class="warn-icon" src="/assets/images/warning-icon.svg" />
  </div>
  <div class="col-10" (click)="closeToast()">
    {{ message }}
  </div>
  <div class="col-1">
    <img
      class="close-icon"
      (click)="closeToast()"
      src="/assets/images/close-toast.svg"
    />
  </div>
</div>
