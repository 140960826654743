import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "./state.service";

@Injectable({
  providedIn: "root",
})
export class TitleService {
  appDetails = this.stateService.getAppDetails();

  constructor(
    private title: Title,
    private stateService: StateService
  ) {}

  setTitle(title: string, appendSuffix: boolean = true): void {
    let suffix = title && appendSuffix ? " | CatExpert.ai" : "";
    this.title.setTitle(title + suffix);
  }

  setDefaultTitle(): void {
    this.title.setTitle(this.appDetails.appTitle);
  }

  setFavicon(): void {
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = this.appDetails.appFavicon;
    } else {
      link = document.createElement("link");
      link.rel = "icon";
      link.href = this.appDetails.appFavicon;
      document.head.appendChild(link);
    }
  }
}
