<div class="landing">
  <header>
    <div class="header wrapp" [ngClass]="isBottom ? 'headerbgclr' : ''">
      <div class="header-icons mr-l">
        <span style="margin: 0px 4px 0px 10px; display: none">
          <i
            class="fa fa-bars"
            aria-hidden="true"
            style="font-size: 30px; color: white"
          ></i>
        </span>
        <span>
          <a href="/">
            <img
              src="/assets/images/{{
                this.domainType === 'pho' ? 'logo-pho.svg' : 'header-logo.png'
              }}"
              class="header-logo"
              [ngClass]="domainType === 'pho' ? 'logo-pho' : ''"
          /></a>
        </span>
      </div>
      <div class="header-icons mr-r">
        <button class="nav-item" (click)="scrollToElement(home)">Home</button>
        <button class="nav-item" (click)="scrollToElement(features)">
          Features
        </button>
        <button class="nav-item" (click)="scrollToElement(about)">
          About Us
        </button>
        <button class="nav-item" (click)="scrollToElement(faqs)">FAQs</button>
        <button class="nav-item" (click)="scrollToElement(contact)">
          Contact Us
        </button>
        <button class="go-to-studio" (click)="continueToLogin()">
          Login to Studio
        </button>
      </div>
    </div>
  </header>

  <section #home id="home">
    <div class="landing-banner">
      <div id="bg" class="bg" style="position: relative">
        <div class="banner-layer-2">
          <div class="overlay"></div>
        </div>
        <div style="width: 100%; float: left">
          <div class="inner_content_home">
            <div class="hg-wrapper">
              <div class="hg hg-1" [ngClass]="{ active: animationIndex === 0 }">
                <div class="h-line">
                  <p>Best-In-Class Software</p>
                </div>
                <div class="h-line">
                  <p>Architecture</p>
                </div>
                <div class="sub-h-line css-typing" #typewriterContainerDiv>
                  <p>Leveraging AI analytics and robust data.</p>
                </div>
              </div>
              <div class="hg hg-2" [ngClass]="{ active: animationIndex === 1 }">
                <div class="h-line">
                  <p>100% Customized</p>
                </div>
                <div class="h-line">
                  <p>Recommendations</p>
                </div>
                <div class="sub-h-line css-typing">
                  <p>For clustering, assortment and planograms.</p>
                </div>
              </div>
              <div class="hg hg-3" [ngClass]="{ active: animationIndex === 2 }">
                <div class="h-line">
                  <p>Unlimited Simulations</p>
                </div>
                <div class="h-line">
                  <p>Produced In Just Minutes</p>
                </div>
                <div class="sub-h-line css-typing">
                  <p>Adaptable to your needs.</p>
                </div>
              </div>
              <div class="hg hg-4" [ngClass]="{ active: animationIndex === 3 }">
                <div class="h-line">
                  <p>Objective & Unbiased</p>
                </div>
                <div class="h-line">
                  <p>Results</p>
                </div>
                <div class="sub-h-line css-typing">
                  <p>To maximize retailer's incremental revenue</p>
                  <p>and improve the shopper experience.</p>
                </div>
              </div>
            </div>
            <button class="know_more" (click)="scrollToElement(features)">
              Learn More
            </button>
          </div>
        </div>
        <div class="bottom_content_home">
          <div
            class="section_three"
            *ngFor="let content of bannerContent; let i = index"
          >
            <div class="icon" [style.background]="content.color">
              <img src="{{ content.image }}" />
            </div>
            <div class="banner_label">{{ content.label }}</div>
            <div class="banner_desc" [innerHTML]="content.description"></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section #features id="features">
    <div class="feature-section section">
      <div class="section_title text-v-border">
        <h5>PRODUCT</h5>
        <h3><span>Features for you</span></h3>
      </div>

      <div id="all-features" class="all-features">
        <div
          *ngFor="let feature of allFeature; let i = index"
          class="feature-wrapp"
        >
          <div class="feature" style="width: 100%">
            <img src="{{ feature.bg_image }}" />
            <div class="description">
              <label
                ><strong>{{ feature.label }}</strong>
              </label>
              <p class="short_desc{{ feature.id }}">
                {{ feature.short_description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #about class="about" id="about">
    <div class="about_content" *ngIf="domainType !== 'pho'">
      <div class="lft_content">
        <h5>ABOUT US</h5>
        <h3><span>Our Competencies</span></h3>
        <div class="about_desc">
          <ul>
            <li>
              End-to-end category management solution, from store clustering to
              planograming to tracking
            </li>
            <li>
              Proprietary expertise & insights provided directly through
              collaboration with leading markets
            </li>
            <li>
              Embedded advanced analytics & genetic, algorithm-based modeling,
              providing exhaustive analytical possibilities
            </li>
            <li>
              Best-in-class architecture built to enable scalability, speed and
              reliability
            </li>
            <li>
              Comprehensive, workshop-based onboarding process for all users
            </li>
            <li>Transparent, user-friendly and fully-integrated design</li>
            <li>
              Customizable scenario simulations, allowing user to test revenue
              impact based on assortment output
            </li>
            <li>
              Machine Learning-powered planogram feature, delivering customized
              planograms in record speed
            </li>

            <strong class="text-pop"></strong>
          </ul>
        </div>
      </div>
      <div class="rht_content">
        <div style="position: relative" class="wrapper">
          <div class="overlay"></div>
          <div class="circle_content circle_contentB">
            <div class="icon">
              <img src="/assets/images/key_acc.svg" />
            </div>
            <div class="content">
              <span> 37 Key Accounts </span>
              <span> Live till date </span>
            </div>
          </div>

          <div class="circle_content circle_contentV">
            <div class="icon">
              <img src="/assets/images/country.svg" />
            </div>
            <div class="content">
              <span> 10 Countries </span>
              <span> Using our solution so far</span>
            </div>
          </div>

          <div class="circle_content circle_contentS">
            <div class="icon">
              <img src="/assets/images/data.svg" />
            </div>
            <div class="content">
              <span> 500+ GB </span>
              <span> Data processed for 15K+ stores </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #faqs id="faqs">
    <div class="faq-section section">
      <div class="all-faqs text-v-border">
        <h5>FAQs</h5>
        <h3><span>Frequently Asked Questions</span></h3>
        <div class="accordion-item">
          <h2 class="accordion-header" id="heading-0">
            <button
              type="button"
              class="accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-0"
            >
              How do I get access to the application?
            </button>
          </h2>
          <div
            id="collapse-0"
            class="accordion-collapse collapse"
            data-bs-parent="#myAccordion"
          >
            <div class="card-body">
              <p>
                You must be an ABI employee and submit a request
                <a
                  (click)="scrollToElement(contact)"
                  style="color: #e5b629; font-weight: bold; cursor: pointer"
                  >here</a
                >
                to gain access to the application. When we receive your request,
                we will generate an id for you and send you an email once your
                account is activated.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion" id="myAccordion">
          <div
            *ngFor="let faq of allFaq; let i = index"
            class="accordion-item"
            id="{{ i === 2 ? 'onboarding' : '' }}"
          >
            <h2 class="accordion-header" id="heading-{{ i + 1 }}">
              <button
                type="button"
                class="accordion-button collapsed"
                data-bs-toggle="collapse"
                attr.data-bs-target="#collapse-{{ i + 1 }}"
              >
                {{ faq.question }}
              </button>
            </h2>
            <div
              id="collapse-{{ i + 1 }}"
              class="accordion-collapse collapse"
              data-bs-parent="#myAccordion"
            >
              <div class="card-body">
                <p [innerHTML]="faq.answer"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #contact id="contact" class="get_in_touch">
      <h3 *ngIf="!isSuccessMessage">Ready to get started?</h3>
      <p *ngIf="!isSuccessMessage">
        Does not have access? Raise onboarding request.
      </p>
      <form
        class="get_in_touch_form"
        *ngIf="!isSuccessMessage"
        [formGroup]="custom"
      >
        <div class="form_fields form-group">
          <input
            type="text"
            formControlName="email"
            class="input-get"
            placeholder="Your AB-InBev email"
          />
          <app-form-error
            type="landing"
            formControlName="email"
            [errors]="errors.email"
          >
          </app-form-error>
        </div>

        <div class="form_fields">
          <select class="select-get" formControlName="zone">
            <option value="">Zone</option>
            <option *ngFor="let list of zonelist" value="{{ list.id }}">
              {{ list.name }}
            </option>
          </select>
          <app-form-error
            type="landing"
            formControlName="zone"
            [errors]="errors.zone"
          >
          </app-form-error>
        </div>

        <div class="form_fields">
          <button class="get_in_touch_btn" (click)="Getstarted()">
            Get Started
          </button>
        </div>
      </form>
      <div class="get_in_touch_error" *ngIf="isSuccessMessage">
        {{ successMessage }}
      </div>
    </div>
  </section>

  <footer>
    <div class="landing-footer">
      <div class="newsletter_section">
        <span *ngIf="domainType !== 'pho'">
          <a href="/">
            <img
              src="/assets/images/catexpert-footer-logo.png"
              class="footer-logo"
              alt="Footer Logo" /></a
          ><br />
        </span>

        <span
          class="footer-logo"
          [ngClass]="domainType === 'pho' ? 'logo-pho' : ''"
          *ngIf="domainType === 'pho'"
        >
        </span>

        <span>
          <a
            href="https://abinbev-india.com/teams/gcc-india"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>
              <img
                src="../../assets/images/gac-footer-logo.png"
                class="img-space img-dim"
              />
            </i>
          </a>

          <a
            href="https://abinbev-india.com/teams/gcc-india"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>
              <img
                src="../../assets/images/gcc-footer-logo.png"
                class="img-space img-dim"
              />
            </i>
          </a>

          <a
            href="https://abinbev-india.com/teams/gcc-india"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>
              <img
                src="../../assets/images/brewdat-footer-logo.png"
                class="img-space brewdat"
              />
            </i>
          </a>

          <a
            href="https://www.ab-inbev.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>
              <img
                src="../../assets/images/abinbev-footer-logo.png"
                class="abinbev"
              />
            </i>
          </a>
        </span>
      </div>
      <div class="copy-rights-border"></div>
      <div class="copy-rights-section">
        <div>
          <span class="social-medias">
            <a
              href="https://www.facebook.com/abinbev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                <img
                  src="../../assets/images/facebook-social-logo.png"
                  class="img-space social-images"
              /></i>
            </a>

            <a
              href="https://www.instagram.com/abinbev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                ><img
                  src="../../assets/images/instagram-social-logo.png"
                  class="img-space social-images"
              /></i>
            </a>

            <a
              href="https://twitter.com/abinbev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                <img
                  src="../../assets/images/twitter-social-logo.png"
                  class="img-space social-images"
              /></i>
            </a>

            <a
              href="https://www.linkedin.com/company/ab-inbev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                <img
                  src="../../assets/images/linkedin-social-logo.png"
                  class="img-space social-images"
                />
              </i>
            </a>
          </span>
        </div>

        <div class="copy-right">
          <span
            >© 2022
            <a
              href="https://www.ab-inbev.com/"
              target="_blank"
              rel="noopener noreferrer"
              >Anheuser-Busch InBev</a
            >
            All rights reserved.
          </span>
        </div>
      </div>
    </div>
  </footer>
</div>
