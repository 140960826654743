<p-multiSelect
  [options]="dropdownOptions"
  [(ngModel)]="selectedOptions"
  optionLabel="label"
  optionValue="value"
  placeholder="Column Options"
  [displaySelectedLabel]="false"
  [showHeader]="false"
  styleClass="column-options"
  panelStyleClass="column-options-panel"
  (onChange)="onSelectItem($event)"
>
</p-multiSelect>
