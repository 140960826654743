import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalDate'
})
export class OrdinalDatePipe implements PipeTransform {

  transform(value: string, includeTime: boolean = true): string {
    const date = new Date(value);
    const now = new Date();
    const suffixes = ['th', 'st', 'nd', 'rd'];
    let options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
    let timeString = '';

    // Check if the date is 'Today' or 'Yesterday'
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const dateValue = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    // If includeTime is true, format the time part
    if (includeTime) {
      const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
      const timeFormatter = new Intl.DateTimeFormat('en-US', timeOptions);
      timeString = timeFormatter.format(date);
    }

    if (dateValue.getTime() === today.getTime()) {
      return `Today${includeTime ? ', ' + timeString : ''}`;
    } else if (dateValue.getTime() === yesterday.getTime()) {
      return `Yesterday${includeTime ? ', ' + timeString : ''}`;
    }

    // If the date is not in the current year, include the year in the format
    if (date.getFullYear() !== now.getFullYear()) {
      options.year = 'numeric';
    }

    const dtFormat = new Intl.DateTimeFormat('en-US', options);
    let formattedDate = dtFormat.format(date);
    const day = date.getDate();
    const relevantDigits = (day < 30) ? day % 20 : day % 30;
    const suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];

    // Append the ordinal suffix to the day
    formattedDate = formattedDate.replace(/(\d+)(th|st|nd|rd)?/, `$1${suffix}`);

    return formattedDate + (includeTime ? ', ' + timeString : '');
  }

}
