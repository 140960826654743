import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import { Router } from "@angular/router";
import { StateService } from "../../services/state.service";
import { CommonModalComponent } from "../../shared/common-modal/common-modal.component";
import { HttpClient, HttpHeaders, HttpEventType } from "@angular/common/http";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
@Component({
  selector: "app-upload-download",
  templateUrl: "./upload-download.component.html",
  styleUrls: ["./upload-download.component.scss"],
})
export class UploadDownloadComponent implements OnInit {
  @Input("data") dataValue: any = {};
  @Input() isSpaceClustering: boolean = false;
  @Output() uploadEditedParent = new EventEmitter<any>();
  @Output() refreshData = new EventEmitter<any>();
  loggedInUser: any;
  accessType: any;
  constructor(
    private router: Router,
    public stateService: StateService,
    private http: HttpClient,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CommonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.loggedInUser = sessionStorage.getItem("role");
    this.accessType = sessionStorage.getItem("access_type");
    this.stateService.fileValidationData.subscribe((data) => {
      if (!data || this.isEmpty(data)) {
        this.refreshData.emit();
        this.dialog.closeAll();
      }
    });
  }

  isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  continue() {
    this.router.navigate(["/studio/experiments/1/clustering"]);
  }

  downloadAndUpload() {
    this.dataValue.progress = false;
    if (
      this.loggedInUser !== "visitor" &&
      this.stateService.guideLayerShow !== true
    ) {
      const dialogRef = this.dialog.open(CommonModalComponent, {
        data: {
          type: "downloadAndUpload",
          data: this.dataValue,
          callback: (data: any) => {
            if (data?.close) {
              this.dialog.closeAll();
              return;
            }
            this.uploadEditedParent.emit(data);
            setTimeout(() => {
              this.refreshData.emit();
              this.dialog.closeAll();
            }, 2000);
          },
        },
        width: "600px",
        maxHeight: "inherit",
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.refreshData.emit();
      });
    }
  }

  downloadFile(event: any, fileURL: string) {
    // Prevent default behavior when clicking a link
    event.preventDefault();

    this.http.get(fileURL, { responseType: "arraybuffer" }).subscribe(
      (val) => {
        if (val) {
          const blob: Blob = new Blob([val], {
            type: "text/csv;charset=UTF-8",
          });
          let hiddenElement = document.createElement("a");
          hiddenElement.href = URL.createObjectURL(blob);
          hiddenElement.target = "_blank";
          let items = fileURL.split("/").slice(-1)[0].split(".");
          let filename = items[0];
          let extension = items[1];
          hiddenElement.download = this.stateService.getFileName(
            filename,
            extension
          );
          hiddenElement.click();
        }
      },
      (err) => {
        // TODO - Add error toast here
      }
    );
  }

  upload() {
    if (
      this.loggedInUser !== "visitor" &&
      this.stateService.guideLayerShow !== true
    ) {
      this.stateService.showMessage({
        type: "upload",
        data: this.data,
      });
    }
  }
}
