import { Pipe, PipeTransform } from '@angular/core';
import { StateService } from '../services/state.service';

@Pipe({
  name: 'titleFirstLetterCaps',
})
export class TitleFirstLetterCapsPipe implements PipeTransform {
  constructor(private stateService: StateService) {}

  transform(text: string) {
    if (text) {
      return this.stateService.titleFirstLetterCaps(text);
    }
    return text;
  }
}
