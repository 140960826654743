import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EdaStatic } from "../../../main-container/pho/eda/eda";

@Component({
  selector: "app-filter-panel",
  templateUrl: "./filter-panel.component.html",
  styleUrl: "./filter-panel.component.scss",
})
export class FilterPanelComponent implements OnInit {
  @Input() segmentsOptions: any[] = [];
  @Input() skusOptions: any[] = [];
  @Output() emitApplyFilters = new EventEmitter<any>();

  segmentSelectedValue: string = "";
  skuSelectedValue: string = "";
  edaStatic: any = EdaStatic;
  selectedFilter: any = {};

  ngOnInit(): void {
    this.skuSelectedValue = this.skusOptions[1]?.value;
  }

  featureDataChange(event: any, type: string) {
    if (type == 'ptc_segments') {
      this.skuSelectedValue = 'Select'
    }
    if (type == 'skus') {
      this.segmentSelectedValue = 'Select'
    }
    this.selectedFilter = {};
    this.selectedFilter[type] = event;
  }

  applyFilters() {
    this.emitApplyFilters.emit(this.selectedFilter);
  }
}
