<!-- add user dialog -->
<div
  class="add-user"
  *ngIf="data?.type === 'add_user' || data?.type === 'edit_user'"
>
  <form [formGroup]="addUserFromGroup">
    <div class="form-field row" *ngIf="data?.type === 'edit_user'">
      <div class="input-group py-2 col">
        <label class="w-100 mb-2" for=""> First Name </label><br />
        <input
          type="text"
          class="form-control"
          placeholder="Enter name"
          formControlName="fname"
          readonly
        />
      </div>
      <div class="input-group py-2 col">
        <label class="w-100 mb-2" for=""> Last Name </label><br />
        <input
          type="text"
          class="form-control"
          placeholder="Enter name"
          formControlName="lname"
          readonly
        />
      </div>
    </div>

    <div class="form-field">
      <div class="input-group" *ngIf="data?.type === 'add_user'">
        <label class="w-100 mb-2" for="">Email</label><br />
        <input
          type="text"
          class="form-control"
          placeholder="Select email"
          formControlName="email"
        />
        <i class="pi pi-info-circle"></i>
      </div>
      <p
        class="error-text"
        *ngIf="
          data?.type === 'add_user' &&
          checkError('email', 'required') &&
          isFormSubmitted
        "
      >
        Email is required
      </p>
      <p
        class="error-text"
        *ngIf="
          data?.type === 'add_user' &&
          checkError('email', 'email') &&
          isFormSubmitted
        "
      >
        Email is not valid
      </p>
    </div>

    <div class="form-field">
      <div class="input-group py-2" *ngIf="data?.type === 'edit_user'">
        <label class="w-100 mb-2" for="">Email</label><br />
        <input
          type="text"
          class="form-control"
          placeholder="Select email"
          formControlName="email"
          readonly
        />
      </div>
    </div>

    <div class="form-field">
      <div class="input-group py-2" *ngIf="data?.type === 'add_user'">
        <label class="w-100 mb-2" for="">Zone</label><br />
        <p-dropdown
          appendTo="body"
          [options]="data?.zones"
          optionLabel="name"
          optionValue="id"
          placeholder="Select zone"
          styleClass="add-user-dropdown"
          panelStyleClass="add-user-dropdown-panel"
          formControlName="zone"
        ></p-dropdown>
        <p class="error-text" *ngIf="checkError('zone', 'required')">
          Please select zone
        </p>
      </div>
    </div>
    <div class="py-2" *ngIf="data?.type === 'edit_user'">
      <label class="w-100 mb-2" for="">Beta User</label><br />
      <span style="margin-right: 10px">
        <input
          type="radio"
          name="beta_user"
          [value]="true"
          formControlName="beta_user"
        />
        <span
          style="
            margin-left: 5px;
            font: normal normal normal 14px/19px Open Sans;
          "
          >Yes</span
        >
      </span>
      <span>
        <input
          formControlName="beta_user"
          type="radio"
          name="beta_user"
          [value]="false"
        />
        <span
          style="
            margin-left: 5px;
            font: normal normal normal 14px/19px Open Sans;
          "
          >No</span
        >
      </span>
    </div>
    <div class="input-group py-2" *ngIf="data?.editMode === 'true'">
      <label class="w-100 mb-2" for="">Status</label><br />
      <p-dropdown
        [options]="status"
        placeholder="Select status"
        optionName="label"
        optionValue="value"
        styleClass="add-user-dropdown"
        panelStyleClass="roles-dropdown-panel"
        formControlName="status"
      ></p-dropdown>
    </div>
  </form>
</div>
<!-- revoke zone access -->
<div class="revoke-key-account" *ngIf="data?.type === 'revoke_zone'">
  <div class="confirm-message">
    This will revoke the access for <b>{{ data?.rowData?.email }}</b> from
    <b>{{ data?.currentZone?.name }}</b> zone, and user will not be able to
    access any experiments created in this zone. Do you want to continue to
    revoke the zone access?
  </div>
</div>

<!-- revoke user profile zone access -->
<div class="revoke-key-account" *ngIf="data?.type === 'revoke_zone_access'">
  <div class="confirm-message">
    This will raise a request to revoke the access from
    <b>{{ data?.activeZone?.name }}</b> zone and you will not be able to access
    any experiments created in this zone. Do you want to continue to raise
    request?
  </div>
</div>

<!-- revoke user profile KA access -->
<div
  class="revoke-key-account"
  *ngIf="data?.type === 'revoke_key_account_access'"
>
  <div class="confirm-message">
    This will raise a request to revoke the access from
    <b>{{ data?.rowData?.name }}</b> KA and you will not be able to access any
    experiments created with this KA. Do you want to continue to raise request?
  </div>
</div>

<!-- migrate user -->
<div class="revoke-key-account" *ngIf="data?.type === 'migrate_user'">
  <div class="confirm-message">
    This will raise the request to migrate the
    <b>{{ data?.rowData?.email }}</b> to another zone and this will remove the
    user access from the <b>{{ data?.currentZone?.name }}</b> Zone experiments.
  </div>
  <div class="input-group py-2">
    <label class="w-100 mb-2" for="">Zone</label><br />
    <p-dropdown
      appendTo="body"
      [options]="data?.zones"
      optionLabel="name"
      optionValue="id"
      placeholder="Select"
      styleClass="add-user-dropdown"
      panelStyleClass="add-user-dropdown-panel"
      [(ngModel)]="selectedZoneId"
    ></p-dropdown>
    <div *ngIf="!selectedZoneId && isFormSubmitted" class="error-form-field">
      <p>Please select zone</p>
    </div>
  </div>
</div>

<!-- request zone -->
<div class="revoke-key-account" *ngIf="data?.type === 'request_zone'">
  <div class="input-group py-1">
    <label class="w-100 mb-2" for="">Zone</label><br />
    <p-dropdown
      appendTo="body"
      [options]="data?.zones"
      optionLabel="name"
      optionValue="id"
      placeholder="Select"
      styleClass="add-user-dropdown"
      panelStyleClass="add-user-dropdown-panel"
      [(ngModel)]="selectedZoneId"
    ></p-dropdown>
    <div *ngIf="!selectedZoneId && isFormSubmitted" class="error-form-field">
      <p>Please select zone</p>
    </div>
  </div>
</div>

<!--add zone-->
<div class="system-add-zone" *ngIf="data?.type === 'system_request_zone'">
  <div class="input-group py-1">
    <label class="w-100 mb-2" for="">Zone</label><br />
    <input
      placeholder="Zone name"
      type="text"
      class="form-control"
      [(ngModel)]="selectedZoneId"
    />
  </div>
  <div *ngIf="!selectedZoneId && isFormSubmitted" class="error-form-field">
    <p>Zone name is required</p>
  </div>
</div>

<!-- request key account access from user profile page -->
<div class="add-edit-key-account" *ngIf="data?.type === 'request_key_account'">
  <div class="search-input-group">
    <input
      placeholder="Type to search, add or edit the KA…"
      class="form-control"
      type="text"
      [formControl]="searchKeyAccount"
    />
    <div class="input-group-icon">
      <i
        (click)="clearSearchText()"
        *ngIf="searchKeyAccount.value"
        class="fa fa-times"
      ></i>
      <i *ngIf="!searchKeyAccount.value" class="pi pi-search"></i>
    </div>
    <div
      *ngIf="
        isFormSubmitted &&
        data?.rowData?.type !== 'onboard_user' &&
        selectedEditKeyAccounts.length === 0
      "
      class="error-block"
    >
      <p style="margin: 0">Please select the key account</p>
    </div>
  </div>
  <div class="key-accounts-block">
    <span>
      <p class="sub-heading">Assign key account</p>
      <ul *ngIf="filteredKeyAccounts.length > 0; else noKeyAccounts">
        <li *ngFor="let keyAccount of filteredKeyAccounts">
          <p-checkbox
            class="key-account-label"
            [value]="keyAccount"
            [(ngModel)]="selectedEditKeyAccounts"
            [label]="keyAccount.name"
            styleClass="ka-checkbox"
          ></p-checkbox>
          <p class="country-region">
            {{ keyAccount.country }} | {{ keyAccount.region }}
          </p>
        </li>
      </ul>
      <ng-template #noKeyAccounts>
        <ul>
          <li style="text-align: center; font-size: 12px; font-weight: 600">
            No key accounts found
          </li>
        </ul>
      </ng-template>
    </span>
  </div>
</div>

<!-- add edit key accounts -->
<div class="add-edit-key-account" *ngIf="data?.type === 'add_edit_key_account'">
  <div class="revoke-key-account" *ngIf="revokeKeyAccounts">
    <div class="confirm-message">
      This will revoke the access for the unselected key accounts, and user will
      not be able to access any experiments created with these key accounts. Do
      you want to continue?
    </div>
  </div>
  <div *ngIf="!revokeKeyAccounts">
    <div class="search-input-group">
      <input
        placeholder="Type to search, add or edit the KA…"
        class="form-control"
        type="text"
        [formControl]="searchKeyAccount"
        (click)="addNewKeyAccount = true"
        (blur)="addNewKeyAccount = false"
      />
      <div class="input-group-icon">
        <i
          (click)="clearSearchText()"
          *ngIf="searchKeyAccount.dirty"
          class="fa fa-times"
        ></i>
        <i *ngIf="!searchKeyAccount.dirty" class="pi pi-search"></i>
      </div>
    </div>
    <div class="key-accounts-block">
      <span
        *ngIf="data?.rowData?.key_accounts.length > 0"
        class="{{
          addNewKeyAccount ? 'hide-added-key-accounts' : 'added-key-accounts'
        }}"
      >
        <p class="sub-heading">
          {{ data?.rowData?.key_accounts.length }} key accounts added
        </p>
        <ul>
          <li *ngFor="let keyAccount of data?.rowData?.key_accounts">
            <p-checkbox
              class="key-account-label"
              [(ngModel)]="existingKeyAccounts"
              [value]="keyAccount"
              [label]="keyAccount.name"
            ></p-checkbox>
            <p class="country-region">
              {{ keyAccount.country }} | {{ keyAccount.region }}
            </p>
          </li>
        </ul>
      </span>
      <span>
        <p class="sub-heading">Add new key account</p>
        <ul *ngIf="filteredKeyAccounts.length > 0; else noKeyAccounts">
          <li *ngFor="let keyAccount of filteredKeyAccounts">
            <p-checkbox
              class="key-account-label"
              [value]="keyAccount"
              [(ngModel)]="selectedEditKeyAccounts"
              [label]="keyAccount.name"
            ></p-checkbox>
            <p class="country-region">
              {{ keyAccount.country }} | {{ keyAccount.region }}
            </p>
          </li>
        </ul>
        <ng-template #noKeyAccounts>
          <ul>
            <li style="text-align: center; font-size: 12px; font-weight: 600">
              No key accounts found
            </li>
          </ul>
        </ng-template>
      </span>
    </div>
  </div>
</div>

<!-- approve user request -->
<div class="approve-request" *ngIf="data?.type === 'approve_request'">
  <div class="search-input-group">
    <input
      placeholder="Type to search, add or edit the KA…"
      class="form-control"
      type="text"
      [formControl]="searchKeyAccount"
      (click)="addNewKeyAccount = true"
      (blur)="addNewKeyAccount = false"
    />
    <div class="input-group-icon">
      <i
        (click)="clearSearchText()"
        *ngIf="searchKeyAccount.dirty"
        class="fa fa-times"
      ></i>
      <i *ngIf="!searchKeyAccount.dirty" class="pi pi-search"></i>
    </div>
  </div>
  <div
    *ngIf="
      isFormSubmitted &&
      data?.rowData?.type === 'add_keyaccount' &&
      selectedEditKeyAccounts.length === 0
    "
    class="error-block"
  >
    <p>Please select the key account</p>
  </div>
  <div class="key-accounts-block">
    <div class="key-account-wrapper">
     <span>
     <mat-checkbox type="checkbox" class="key-account-checkbox" [(ngModel)]="selectAllKeyAccounts" (change)="selectAllAccounts()"> </mat-checkbox>
     </span>
     <span class="sub-heading">Select all</span>
    </div>
   
     <ul>
       <li *ngFor="let keyAccount of filteredKeyAccounts">
         <p-checkbox
           class="key-account-label"
           [value]="keyAccount"
           [(ngModel)]="selectedEditKeyAccounts"
           [label]="keyAccount.name"
           styleClass="ka-checkbox"
         ></p-checkbox>
         <p class="country-region">
           {{ keyAccount.country }} | {{ keyAccount.region }}
         </p>
       </li>
     </ul>
     <ul *ngIf="filteredKeyAccounts.length === 0">
       <li style="text-align: center; font-weight: 600; font-size: 12px">
         No key accounts found
       </li>
     </ul>
   </div>
 
</div>

<!-- reject user request -->
<div class="reject-request" *ngIf="data?.type === 'reject_request'">
  <div [ngClass]="{ 'error-form-field': !rejectReason && isFormSubmitted }">
    <p class="label">Reason</p>
    <textarea
      placeholder="Type here"
      cols="30"
      rows="5"
      class="form-control"
      name="reason"
      [(ngModel)]="rejectReason"
    ></textarea>
    <div *ngIf="!rejectReason && isFormSubmitted">
      <p>Reason required</p>
    </div>
  </div>
</div>

<!-- clone experiment -->
<div class="clone-experiment" *ngIf="data?.type === 'clone_exp'">
  <p>Experiment Name</p>
  <input type="text" class="form-control" />
</div>

<!-- delete experiment -->
<div class="delete-experiment" *ngIf="data?.type === 'delete_exp'">
  <p>
    Are you sure you want to delete <b>{{ data?.rowData?.name }}</b> Experiment?
  </p>
</div>

<!-- delete zone -->
<div class="delete-experiment" *ngIf="data?.type === 'appRevokeZone'">
  <p>
    Are you sure you want to delete <b>{{ data?.rowData?.name }}</b> zone ?
  </p>
</div>
<!-- delete key account -->
<div class="delete-experiment" *ngIf="data?.type === 'delete_key_account'">
  <p>
    Are you sure you want to delete <b>{{ data?.rowData?.name }}</b> key account
    ?
  </p>
</div>

<!-- share experiment -->
<div class="share-experiment" *ngIf="data?.type === 'share_exp'">
  <div
    class="share-search-input-group"
    [ngClass]="{
      'error-autocomplete':
        (isFormSubmitted &&
          shareAutocomplete.touched &&
          !shareAutocomplete.dirty) ||
        (isFormSubmitted &&
          emailIdsList.length === 0 &&
          shareAutocomplete.dirty)
    }"
  >
    <p-autoComplete
      [formControl]="shareAutocomplete"
      [suggestions]="emailIdsList"
      (completeMethod)="searchMailIds($event)"
      field="name"
      appendTo="body"
      styleClass="share-exp-autocomplete"
      panelStyleClass="share-exp-autocomplete-panel"
      placeholder="Type to search or add new user"
    >
      <ng-template let-data pTemplate="item">
        <div
          class="flex align-items-center gap-2 email-list-item"
          [ngClass]="{
            'disabled-auto-complete-suggestion': !data.has_key_account_access
          }"
          (click)="data.has_key_account_access ? '' : $event.stopPropagation()"
        >
          <p class="email-user-name">{{ data.name }}</p>
          <p class="email-id-name">{{ data.email }}</p>
          <p *ngIf="!data.has_key_account_access" class="email-error">
            User lacks necessary key account access for sharing.
          </p>
        </div>
      </ng-template>
    </p-autoComplete>
    <div class="error-block">
      <p
        *ngIf="
          isFormSubmitted &&
          shareAutocomplete.touched &&
          !shareAutocomplete.dirty
        "
      >
        Please type & select from the list
      </p>
      <p
        *ngIf="
          isFormSubmitted &&
          emailIdsList.length === 0 &&
          shareAutocomplete.dirty
        "
      >
        Entered value is invalid
      </p>
    </div>
    <div class="input-group-icon">
      <i
        (click)="clearSearchText()"
        *ngIf="isShareSearchInput"
        class="fa fa-times"
      ></i>
      <i
        *ngIf="!isShareSearchInput && shareAutocomplete.dirty"
        class="pi pi-search"
      ></i>
    </div>
  </div>
  <div class="share-btn-block">
    <p class="sub-heading">
      <p-checkbox
        styleClass="share-experiment-checkbox"
        [(ngModel)]="allowExperimentEditing"
        [binary]="true"
      ></p-checkbox>
      Allow Editing
    </p>
    <button
      *ngIf="!showSpinner"
      class="btn share-btn"
      (click)="shareExperiment()"
    >
      Share
    </button>
    <button *ngIf="showSpinner" class="btn share-btn">
      <mat-spinner
        style="left: 1em"
        strokeWidth="1"
        diameter="16"
        color="#000000"
        *ngIf="showSpinner"
      ></mat-spinner>
    </button>
  </div>
  <p class="shared-with">Shared with</p>
  <ul *ngIf="sharedUsers.length > 0 && !isApiCalling">
    <li *ngFor="let user of sharedUsers; let i = index">
      <div class="row">
        <div class="col-1">
          <span
            class="name-icon"
            *ngIf="user.first_name || user.last_name"
            [ngStyle]="{ 'background-color': getColor(i) }"
          >
            {{ user.first_name ? user.first_name[0] : ""
            }}{{ user.last_name ? user.last_name[0] : "" }}
          </span>
          <span
            class="name-icon"
            *ngIf="!user.first_name && !user.last_name"
            [ngStyle]="{ 'background-color': getColor(i) }"
          >
            {{ user.email[0] | uppercase }}
          </span>
        </div>
        <div class="col-3">
          <span *ngIf="user.first_name" class="name"
            >{{ user.first_name }} {{ user.last_name }}</span
          >
          <span *ngIf="!user.first_name" class="name">{{ user.email }}</span>
          <br />
          <span class="email">{{ user.email }}</span>
        </div>
        <div class="col-6 can-view">
          <p-dropdown
            placeholder="Can View"
            appendTo="body"
            styleClass="can-view-options-dropdown"
            panelStyleClass="can-view-options-dd-panel"
            optionDisabled="inactive"
            [options]="canViewOptions"
            [(ngModel)]="user.permission"
            (onChange)="onChangeShareOptions($event, user)"
          >
            <ng-template let-option pTemplate="item">
              <div class="row">
                <span class="col-3">
                  <i
                    *ngIf="
                      user.permission === option.value &&
                      option.value !== 'Remove'
                    "
                    class="fa fa-check"
                  ></i>
                </span>
                <span class="col-9" style="width: 70%; text-align: left">{{
                  option.value
                }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </li>
  </ul>
  <ul *ngIf="sharedUsers.length === 0 && !isApiCalling">
    <li style="text-align: center; font-weight: 600; font-size: 12px">
      No shared users found
    </li>
  </ul>
  <div class="row" *ngIf="isApiCalling">
    <div class="col-1">
      <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
    </div>
    <div class="col-3">
      <p-skeleton width="6rem" height="1rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="9rem" height="1rem" styleClass="mb-2"></p-skeleton>
    </div>
    <div class="col-6">
      <p-skeleton
        width="3rem"
        height="1rem"
        styleClass="mb-2 can-view-skeleton"
      ></p-skeleton>
    </div>
  </div>
</div>

<div class="experiment-details" *ngIf="data?.type === 'exp_details'">
  <div class="row">
    <div class="col-6">
      <label for="">Planogram Size</label>
      <p>123</p>
      <label for="">Traffic Flow</label>
      <p>Left to Right</p>
      <label for="">Number of Plans</label>
      <p>4</p>
    </div>
    <div class="col-6">
      <label for="">Number of Doors</label>
      <p>cccc</p>
      <label for="">Number of SKUs</label>
      <p>350</p>
      <label for="">Last Update</label>
      <p>02 Mar 2023, 01:23PM</p>
    </div>
  </div>
</div>

<!-- app settings edit zone -->
<div
  [formGroup]="addEditZoneFormGroup"
  class="app-edit-zone"
  *ngIf="data?.type === 'app_edit_zone'"
>
  <div class="input-elm">
    <p>Zone</p>
    <input type="text" formControlName="zone" class="form-control" />
  </div>
  <div
    *ngIf="
      addEditZoneFormGroup?.controls?.zone?.errors?.required && isFormSubmitted
    "
  >
    <p class="error-text">Zone name is required</p>
  </div>
</div>

<!-- system config add new key account -->
<div
  class="system-config-key-account"
  *ngIf="
    data?.type === 'app_add_key_account' || data?.type === 'edit_key_account'
  "
>
  <div [formGroup]="addKeyAccFormGroup">

    <div *ngIf="data?.type === 'app_add_key_account'" class="input-elm">
      <label for="">Zone</label>
      <p-dropdown
        appendTo="body"
        [options]="data?.zones"
        optionLabel="name"
        optionValue="id"
        placeholder="Select zone"
        styleClass="add-user-dropdown"
        panelStyleClass="add-user-dropdown-panel"
        formControlName="zone_id"
      >
      </p-dropdown>
      <p
        class="error-text"
        *ngIf="
          isFormSubmitted &&
          addKeyAccFormGroup?.controls?.zone_id?.errors?.required
        "
      >
        Please select zone
      </p>
    </div>

    <div class="input-elm">
      <label for="">Region</label>
      <p-dropdown
        appendTo="body"
        [options]="data?.regions"
        placeholder="Select region"
        styleClass="add-user-dropdown"
        panelStyleClass="add-user-dropdown-panel"
        formControlName="region"
        (onChange)="onRegionChange($event)"
      >
      </p-dropdown>

        <input
          type="text"
          [hidden]="selectedRegion != 'Other' && data?.type === 'app_add_key_account'"
          class="form-control"
          formControlName="otherRegion"
          placeholder="Enter region name"
          style="margin-top: 8px"
          #otherRegionInput
        />

      <p
        class="error-text"
        *ngIf="
          isFormSubmitted &&
          addKeyAccFormGroup?.controls?.region?.errors?.required
        "
      >
        Please select region
      </p>
    </div>
    <div class="input-elm">
      <label for="">Country</label>
      <p-dropdown
        appendTo="body"
        [options]="data?.countries"
        placeholder="Select country"
        styleClass="add-user-dropdown"
        panelStyleClass="add-user-dropdown-panel"
        formControlName="country"
        (onChange)="onCountryChange($event)"
      >
      </p-dropdown>

        <input
          type="text"
          [hidden]="selectedCountry != 'Other' && data?.type === 'app_add_key_account'"
          class="form-control"
          formControlName="otherCountry"
          placeholder="Enter country name"
          style="margin-top: 8px"
          #otherCountryInput
        />
      <p
        class="error-text"
        *ngIf="
          isFormSubmitted &&
          addKeyAccFormGroup?.controls?.country?.errors?.required
        "
      >
        Please select country
      </p>
    </div>
    <div class="input-elm">
      <label for="">Key Account</label>
      <input
        type="text"
        class="form-control"
        formControlName="name"
        placeholder="Enter KA"
      />
      <p
        class="error-text"
        *ngIf="
          isFormSubmitted &&
          addKeyAccFormGroup?.controls?.name?.errors?.required
        "
      >
        Key account name is required
      </p>
    </div>
    <div *ngIf="data?.type === 'edit_key_account'" class="input-elm">
      <label for="">Zone</label>
      <p-dropdown
        appendTo="body"
        [options]="data?.zones"
        optionLabel="name"
        optionValue="id"
        placeholder="Select zone"
        styleClass="add-user-dropdown"
        panelStyleClass="add-user-dropdown-panel"
        formControlName="zone_id"
        [readonly]="true"
      >
      </p-dropdown>
      <p
        class="error-text"
        *ngIf="
          isFormSubmitted &&
          addKeyAccFormGroup?.controls?.zone_id?.errors?.required
        "
      >
        Please select zone
      </p>
    </div>
  </div>
</div>

<div *ngIf="errMessage" class="error-block pt-1">
  <p>{{ errMessage }}</p>
</div>

<!-- common buttons for all dialogs -->
<div
  class="dialog-footer pt-3 p-dialog-footer"
  *ngIf="data?.type !== 'share_exp'"
>
  <button
    *ngIf="data?.cancelBtn"
    [ngClass]="{
      'add-user-btn': data?.type === 'add_user',
      'add-edit-key-accounts-btn': data?.type === 'add_edit_key_account'
    }"
    class="btn cancel-btn mr-2"
    (click)="closeDialog()"
  >
    {{ data?.cancelBtn }}
  </button>
  <button
    *ngIf="data?.okBtn && !isApiCalling"
    [ngClass]="{
      'add-user-btn': data?.type === 'add_user',
      'add-edit-key-accounts-btn': data?.type === 'add_edit_key_account'
    }"
    class="btn ok-btn"
    (click)="submitDialog()"
  >
    {{ data?.okBtn }}
  </button>
  <button *ngIf="data?.okBtn && isApiCalling" class="btn ok-btn">
    <mat-spinner
      style="left: 1.6em"
      strokeWidth="1"
      diameter="16"
      color="#000000"
      *ngIf="isApiCalling"
    ></mat-spinner>
  </button>
</div>
