<p-dropdown
  [options]="dropdownOptions"
  [(ngModel)]="selectedOption"
  optionLabel="label"
  optionValue="value"
  placeholder="Select"
  styleClass="{{ styleClass }}"
  panelStyleClass="select-dropdown-panel"
  [showClear]="selectedOption ? showClearFlag : false"
  appendTo="body"
  (onChange)="onDopdownChange()"
  optionDisabled="isDisabled"
  [readonly]="isReadonlyMode"
  [disabled]="isDisabled"
>
  <ng-template let-option pTemplate="item">
    <div [ngClass]="{ 'create-new-option': option.value === 'create-new' }">
      {{ option.label }}
    </div>
  </ng-template>
</p-dropdown>
