import { Component, Input, SimpleChanges } from "@angular/core";
import { CreateChartService } from "../../services/create-chart.service";
import { StateService } from "../../services/state.service";
import html2canvas from "html2canvas";

@Component({
  selector: "app-chart-highcharts",
  templateUrl: "./chart-highcharts.component.html",
  styleUrl: "./chart-highcharts.component.scss",
})
export class ChartHighchartsComponent {
  @Input() chartIds: any[] = [];
  @Input() chartData: any;
  @Input() containerId: string = "";
  containerIds: any[] = ["overall", "country", "region", "state", "channel"];
  macoTableView: boolean = false;
  revenueTableView: boolean = false;
  volumeTableView: boolean = false;
  excelHeader: any = [
    {
      label: "Category",
      value: "name",
    },
    {
      label: "Value",
      value: "y",
    },
  ];

  constructor(
    private chartService: CreateChartService,
    private stateService: StateService
  ) {}
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData) {
      this.chartIds = this.chartIds.map((id) => this.containerId.concat(id));
      this.chartData = changes.chartData.currentValue;
      setTimeout(() => {
        this.createAndAppendDiv();
      }, 200);
      setTimeout(() => {
        this.getWaterfallChartData(this.chartIds);
      }, 500);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getWaterfallChartData(this.chartIds);
    }, 0);
  }

  createAndAppendDiv() {
    const targetElement = document.getElementById(this.containerId);
    if (targetElement) {
      targetElement.innerHTML = "";
    }
    for (const id of this.chartIds) {
      const newDiv = document.createElement("div");
      newDiv.setAttribute("id", id);

      if (targetElement) {
        targetElement.appendChild(newDiv);
      }
    }
  }

  nParseFloat(val: any) {
    if (typeof val == "string") {
      return val != "" ? val : "-";
    } else if (this.isInt(val)) {
      return this.formatNumber(val);
    } else if (val && typeof val != "string" && !this.isInt(val)) {
      const floatVal: any = val.toFixed(1);
      return this.formatNumber(floatVal);
    } else {
      return val || "-";
    }
  }

  isInt(num: any) {
    return Number(num) === num && num % 1 === 0;
  }

  formatNumber(num: number) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  showTableView(event: any) {
    switch (event) {
      case "maco":
        this.macoTableView = !this.macoTableView;
        break;
      case "revenue":
        this.revenueTableView = !this.revenueTableView;
        break;
      case "volume":
        this.volumeTableView = !this.volumeTableView;
        break;

      default:
        break;
    }
  }

  chartDownload(ids: string[]) {
    ids.forEach((id) => {
      let element = document.getElementById(id);
      if (!element) {
        return;
      }

      html2canvas(element)
        .then(function (canvas: any) {
          // Convert the canvas to blob
          canvas.toBlob(function (blob: any) {
            // To download directly on browser default 'downloads' location
            let link = document.createElement("a");
            link.download = id + ".png"; // Use ID for download
            link.href = URL.createObjectURL(blob);
            link.click();
            // To save manually somewhere in file explorer
          }, "image/png");
        })
        .catch((error) => {});
    });
  }

  excelDownload(excelHeader: any, excelData: any, fileName: string) {
    this.stateService.exportToExcel(excelHeader, excelData, fileName);
  }

  getWaterfallChartData(containerNames: string[]) {
    containerNames.forEach((containerName) => {
      this.chartService.setWaterfallChartData(
        containerName,
        this.chartData?.macoData,
        this.chartData?.volumeData,
        this.chartData?.revenueData
      );
    });
  }
}