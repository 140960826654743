import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-edit-alert-popup",
  templateUrl: "./edit-alert-popup.component.html",
  styleUrls: ["./edit-alert-popup.component.scss"],
})
export class EditAlertPopupComponent implements OnInit {
  @Input() popupData: any = [];
  @Input() visibleFlag: boolean = false;
  @Output() actionButtonCall = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  clickEvent(buttonFlag: any) {
    if(buttonFlag === 'No') {
      this.popupData.visibleFlag = false
    }
    this.actionButtonCall.emit(buttonFlag);
  }
}
