<div class="upload-download">
  <p
    class="upload-download-edit"
    *ngIf="dataValue?.isEdit && accessType === 'edit'"
    (click)="downloadAndUpload()"
  >
    <span
      class="upload-download-edit-text space-cluster"
      *ngIf="isSpaceClustering"
    >
      <img src="/assets/images/space-clustering-input.svg" /> Space Clustering
      Input</span
    >
    <span class="upload-download-edit-text" *ngIf="!isSpaceClustering"
      ><img src="/assets/images/edit.svg" /> Modify</span
    >
  </p>
  <span *ngIf="!isSpaceClustering">
    <a
      href="{{ dataValue?.downloadUrl }}"
      *ngIf="dataValue?.downloadUrl && stateService.guideLayerShow !== true"
      (click)="downloadFile($event, dataValue.downloadUrl)"
    >
      <img src="/assets/images/download.svg" />
      <span class="upload-download-edit-text">Download Results</span>
    </a>
    <a *ngIf="stateService.guideLayerShow">
      <img src="/assets/images/download.svg" />
      <span class="upload-download-edit-text">Download Results</span>
    </a>
  </span>
</div>
