<ng-container *ngIf="!isMobile">
  <div class="row form-inputs mb-0" *ngIf="!options.singleCalendar">
    <div class="col">
      <div *ngIf="options.icons !== 'default'">
        <div
          [ngClass]="{
            'd-flex align-items-center input-with-icon': true,
            material: options.icons === 'material'
          }"
        >
          <i *ngIf="options.icons === 'material'" class="material-icons"
            >event</i
          >
          <i
            *ngIf="options.icons === 'font-awesome'"
            class="far fa-calendar-alt"
          ></i>
          <span *ngIf="fromDate">From:&nbsp;</span>
          <input
            class="form-control"
            [ngModel]="fromDate | formatMomentDate: options.format"
            (blur)="setDateFromInput($event, true)"
            type="text"
            name="daterangepicker_start"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select From Date"
          />
        </div>
      </div>

      <input
        *ngIf="options.icons === 'default'"
        class="form-control"
        [ngModel]="fromDate | formatMomentDate: options.format"
        (blur)="setDateFromInput($event, true)"
        type="text"
        name="daterangepicker_start"
        [ngModelOptions]="{ standalone: true }"
        placeholder="Select From Date"
      />
    </div>

    <div class="col">
      <div *ngIf="options.icons !== 'default'">
        <div
          [ngClass]="{
            'd-flex align-items-center input-with-icon': true,
            material: options.icons === 'material'
          }"
        >
          <i *ngIf="options.icons === 'material'" class="material-icons"
            >event</i
          >
          <i
            *ngIf="options.icons === 'font-awesome'"
            class="far fa-calendar-alt"
          ></i>
          <span *ngIf="toDate">To:&nbsp;</span>
          <input
            class="form-control"
            [ngModel]="toDate | formatMomentDate: options.format"
            (blur)="setDateFromInput($event)"
            type="text"
            name="daterangepicker_end"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select To Date"
          />
        </div>
      </div>

      <input
        *ngIf="options.icons === 'default'"
        class="form-control"
        [ngModel]="toDate | formatMomentDate: options.format"
        (blur)="setDateFromInput($event)"
        name="daterangepicker_end"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>
  </div>
</ng-container>
