import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { debounceTime } from 'rxjs/operators';
import { HttpService } from '../../services/http.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-share-experiment',
  templateUrl: './share-experiment.component.html',
  styleUrls: ['./share-experiment.component.scss'],
})
export class ShareExperimentComponent implements OnInit {
  @Input() selectedExperiment: any;
  @Output() closeSharePopup: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  mailIdList: any[] = [];
  suggestions: any[] = [];
  searchText: any;
  visible: boolean = true;
  userID: any;
  allowEditing: boolean = true;
  shareLoader: boolean = false;
  invalidEmail: boolean = false;
  expID: any;
  sharedUsers: any[] = [];
  sharedWithClicked: boolean = false;
  removeShareLoader: boolean = false;
  sharedUserIDTobeRemoved: any;
  optionsList = [
    { name: 'View', value: 'view' },
    { name: 'Edit', value: 'edit' },
    { name: 'Remove', value: 'remove' },
  ];

  constructor(
    private stateService: StateService,
    private httpService: HttpService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.expID = this.selectedExperiment.id;
    this.getSharedUsersList(this.selectedExperiment);
  }

  closeDialog() {
    this.mailIdList = [];
    this.closeSharePopup.emit(true);
    this.searchText = '';
    this.sharedWithClicked = false;
  }

  getUserSuggestions(event: any) {
    this.getUserDetail();
    this.getUserFromServer();
    this.searchText = event.query;
  }

  getUserDetail() {
    this.userID = null;
    if (!this.searchText.length) {
      this.mailIdList = [];
    }
  }

  getUserFromServer() {
    let key_acc_id = this.selectedExperiment.key_account_id
      ? this.selectedExperiment.key_account_id
      : sessionStorage.getItem('expKeyAccountId');
    let exclude_id =
      this.selectedExperiment.created_by || this.selectedExperiment.user;
    const url = `${this.stateService.apiList.user.searchUserV3}?search=${this.searchText}&key_account_id=${key_acc_id}`;
    this.httpService
      .getMethod(url)
      .pipe(debounceTime(500))
      .subscribe(
        (val: any) => {
          this.mailIdList = val
            .filter((each: any) => {
              return (
                !this.selectedExperiment.shared_users.includes(each.id) &&
                exclude_id != each.id
              );
            })
            .map((each: any) => {
              each.name = [each.first_name, each.last_name]
                .filter((name) => name)
                .join(' ');
              each.has_key_account_access =
                key_acc_id == 'Other' ? true : each.has_key_account_access;
              return each;
            });
          this.suggestions = this.mailIdList;
        },
        (err:any) => {
          if (err.status === 400) {
          } else if (err.status !== 401) {
            this.messageService.clear();
            this.messageService.add({
              key: 'c',
              severity: 'warn',
              summary: err.error.message,
              detail: err.error.traceback,
            });
          }
          this.stateService.removeCalledApi(url);
        },
      );
  }

  shareInvite() {
    let validEmail = false;
    let selectedItem: any;
    for (let item of this.mailIdList) {
      if (item.email === this.searchText.email) {
        this.userID = item.id;
        validEmail = true;
        selectedItem = item;
        break;
      }
    }

    if (!validEmail) {
      this.invalidEmail = true;
      return;
    }
    if (!this.shareLoader) {
      this.shareLoader = true;
      const url = `${this.stateService.apiList.experiments.getExperiments}/${this.expID}/share/${this.userID}`;
      const payload = {
        experiment_id: this.expID,
        user_id: this.userID,
        share_type: this.allowEditing ? 'edit' : 'read',
      };
      this.httpService.postMethod(url, payload).subscribe(
        (val:any) => {
          this.stateService.removeCalledApi(url);
          this.messageService.add({
            key: 's',
            severity: 'success',
            summary: 'Shared successfully',
            detail: 'Success',
          });
          this.searchText = '';
          this.selectedExperiment.shared_users.push(selectedItem.id);
          const firstname = selectedItem.name
            ? selectedItem.name.split(' ')[0]
            : selectedItem.first_name;
          const lastname = selectedItem.name
            ? selectedItem.name.split(' ')[1]
            : selectedItem.last_name;
          selectedItem.first_name = firstname;
          selectedItem.last_name = lastname;
          selectedItem.permission = this.allowEditing ? 'edit' : 'read';
          if (this.sharedUsers) {
            this.sharedUsers.push(selectedItem);
            this.selectedExperiment.shared_users_data = this.sharedUsers;
          }
          this.shareLoader = false;
          // this.closeDialog();
        },
        (err:any) => {
          this.shareLoader = false;
          if (err.status === 400) {
            this.messageService.add({
              key: 'w',
              severity: 'warn',
              summary: 'Warn Message',
              detail: err.error.message,
            });
          } else if (err.status !== 401) {
            this.messageService.clear();
            this.messageService.add({
              key: 'c',
              severity: 'warn',
              summary: err.error.message,
              detail: err.error.traceback,
            });
          }
          this.stateService.removeCalledApi(url);
        },
      );
    }
  }

  getSharedUsersList(experiment: any) {
    if (experiment.shared_users_data || !experiment.shared_users.length) {
      this.sharedUsers = experiment.shared_users.length
        ? experiment.shared_users_data
        : [];
      this.sharedWithClicked = true;
      return;
    }

    const payload = {
      users_list: experiment.shared_users.map((user: any) => user.id),
    };

    this.stateService.getBulkUserDetailsService(payload).subscribe(
      (val:any) => {
        if (val) {
          experiment.shared_users_data = val;
          experiment.shared_users_data.map((user: any) => {
            const index = experiment.shared_users.findIndex(
              (user1: any) => user1.id === user.id,
            );

            user['permission'] = experiment.shared_users[index].access;
          });
          this.sharedUsers = experiment.shared_users_data;
          this.sharedWithClicked = true;
        }
      },
      (err:any) => {
        if (err.status === 400) {
          this.messageService.add({
            key: 'w',
            severity: 'warn',
            summary: 'Warn Message',
            detail: err.error.message,
          });
        } else if (err.status !== 401) {
          this.messageService.clear();
          this.messageService.add({
            key: 'c',
            severity: 'warn',
            summary: err.error.message,
            detail: err.error.traceback,
          });
        }
      },
    );
    this.showInvite(experiment);
  }

  showInvite(experiment: any) {
    this.invalidEmail = false;
    this.userID = experiment.user;
    this.expID = experiment.id;
    this.selectedExperiment = experiment;
    this.sharedUsers = experiment.shared_users_data;
  }

  getColor(index: number) {
    let colors = ['#FFDBDB', '#E2DBFF', '#DBF5FF', '#DBE2FF'];
    return colors[index % 4];
  }

  hasValidValue(str: any) {
    return !!str && str.toLowerCase() !== 'none';
  }

  // User actions permissions like edit read or remove
  sharedUserActions(userID: string, event: any, item: any) {
    switch (event.value) {
      case 'edit':
        const url = `${this.stateService.apiList.experiments.getExperiments}/${this.expID}/share/${userID}`;
        const payload = {
          share_type: 'edit',
        };

        this.httpService.putMethod(url, payload).subscribe((val:any) => {});

        break;
      case 'view':
        const permissionUrl = `${this.stateService.apiList.experiments.getExperiments}/${this.expID}/share/${userID}`;
        const data = {
          share_type: 'read',
        };

        this.httpService.putMethod(permissionUrl, data).subscribe((val:any) => {});
        break;
      case 'remove':
        this.sharedUserIDTobeRemoved = userID;
        if (!this.removeShareLoader) {
          this.removeShareLoader = true;
          const url = `${this.stateService.apiList.experiments.getExperiments}/${this.expID}/share/${userID}`;
          this.httpService.deleteMethod(url).subscribe(
            (val:any) => {
              if (this.sharedUsers) {
                // Remove the user from the shared_users
                for (let i = 0; i <= this.sharedUsers.length; i++) {
                  if (this.sharedUsers[i].id === userID) {
                    this.sharedUsers.splice(i, 1);
                    break;
                  }
                }
              }

              let currentUsers = this.selectedExperiment.shared_users;
              for (let i = 0; i <= currentUsers.length; i++) {
                if (currentUsers[i] === userID) {
                  currentUsers.splice(i, 1);
                  break;
                }
              }

              this.selectedExperiment.shared_users = currentUsers;

              this.messageService.add({
                key: 's',
                severity: 'success',
                summary: 'Removed Share successfully',
                detail: 'Success',
              });
              this.removeShareLoader = false;
              this.sharedUserIDTobeRemoved = null;
            },
            (err:any) => {
              this.sharedUserIDTobeRemoved = null;
              this.removeShareLoader = false;
              if (err.status === 400) {
                this.messageService.add({
                  key: 'w',
                  severity: 'warn',
                  summary: 'Warn Message',
                  detail: err.error.message.message,
                });
              } else if (err.status !== 401) {
                this.messageService.clear();
                this.messageService.add({
                  key: 'c',
                  severity: 'warn',
                  summary: err.error.message,
                  detail: err.error.traceback,
                });
              }
            },
          );
        }
        break;
      default:
        break;
    }
  }
}
